import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { INumeroDaSorte } from '../../../interfaces/ISeguros';
import Relatorio from '../../../components/common/Relatorio';
import { useSeguros } from '../../../contexts/segurosProvider';

const ImportarNumeroSorte: React.FC = () => {
    const [ficha, setFicha] = useState<INumeroDaSorte[]>([]);
    const { buscarNumerosDaSorteAsync, exportarNumerosDaSorteAsync } = useSeguros();

    return (
        <>
            <Typography variant="h6">Importar Número da Sorte</Typography>
            <Relatorio
                busca={buscarNumerosDaSorteAsync}
                exporta={exportarNumerosDaSorteAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"NumerosDaSorte"}
                refactoring={true}
            />
        </>
    );
};

export default ImportarNumeroSorte;
