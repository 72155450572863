import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Card, CardContent, Typography, Box, Snackbar, Alert, Checkbox, FormControlLabel, Autocomplete, Grid, RadioGroup, Radio, FormLabel, FormControl } from '@mui/material';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import { downloadBlob } from '../../utils/helpers';
import { useCondominio } from '../../contexts/CondominioProvider';
import { useJuridico } from '../../contexts/juridicoProvider';

export interface CondominioInfo {
    nome_Condominio: string;
    numero_Bloco: string;
    logradouro: string;
    bairro_Bloco: string;
    cidade_Bloco: string;
    uF_Bloco: string;
}

interface EconomiaItem {
    Id_Economia: number;
    Descricao: string;
    Bloco: string;
}
interface AutocompleteOption {
    label: string;
    id: string;
}

export interface IEconomiaInfo {
    codcondom: number;
    titulo: string;
    observacao: string;
    economia: string;
    bloco: string;
    economiaSindico: boolean;
    somenteSindico: boolean;
}

const ProtocoloCorrespondenciaPage: React.FC = () => {
    const { buscaAcessosGestorAsync, imprimeDadosCondominosAsync } = useCondominio();
    const { economiasByCondominioAsync } = useJuridico()

    const [codcondom, setCodcondom] = useState<string>('');
    const [codpessoa, setCodPessoa] = useState<string>('0');
    const [condominioInfo, setCondominioInfo] = useState<CondominioInfo | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [titulo, setTitulo] = useState<string>('');
    const [observacao, setObservacao] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState<"success" | "error">("success");
    const [msgAlert, setMsgAlert] = useState("");
    const [showFieldsEconomia, setShowFieldsEconomia] = useState<boolean>(false);
    const [showFieldsBloco, setShowFieldsBloco] = useState<boolean>(false);
    const [showFieldsSindico, setShowFieldsSindico] = useState<boolean>(false);
    const [selectedOptionsEconomia, setselectedOptionsEconomia] = useState<string>('');
    const [selectedOptionsBloco, setselectedOptionsBloco] = useState<string>('');
    const [sindicoOption, setSindicoOption] = useState<string>('');
    const [economias, setEconomias] = useState<AutocompleteOption[]>([]);
    const [blocos, setBlocos] = useState<AutocompleteOption[]>([]);
    const [economiaSindico, setEconomiaSindico] = useState<boolean>(false);
    const [somenteSindico, setSomenteSindico] = useState<boolean>(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, type: 'economias' | 'blocos' | 'sindico') => {
        const checked = event.target.checked;

        if (type === 'economias') {
            setShowFieldsEconomia(checked);
            setShowFieldsBloco(false);
            setselectedOptionsBloco('');
            if (!checked) {
                setShowFieldsEconomia(false);
                setShowFieldsBloco(false);
                setselectedOptionsEconomia('');
            }
        } else if (type === 'blocos') {
            setShowFieldsBloco(checked);
            setShowFieldsEconomia(false);
            setselectedOptionsEconomia('');
            if (!checked) {
                setShowFieldsEconomia(false);
                setShowFieldsBloco(false);
                setselectedOptionsBloco('');
            }
        } else if (type === 'sindico') {
            setShowFieldsSindico(checked);
            if (!checked) {
                setShowFieldsSindico(false);
                setSindicoOption('');
                setEconomiaSindico(false);
                setSomenteSindico(false);
            }
        }
    };

    const getEconomias = async (codigo: string) => {
        try {
            let retorno = await economiasByCondominioAsync(codigo);
            const economias: EconomiaItem[] = retorno;
            const autocompleteOptions: AutocompleteOption[] = economias.map((economia) => ({
                label: economia.Bloco + "-" + economia.Descricao,
                id: economia.Id_Economia.toString(),
            }));
            setEconomias(autocompleteOptions);
        } catch (err: any) {
            console.log(err.response.data);
        }
    };

    const getBlocos = async (codigo: string) => {
        try {
            let retorno = await economiasByCondominioAsync(codigo);
            const economias: EconomiaItem[] = retorno;
            const blocosUnicos = Array.from(new Set(economias.map(economia => economia.Bloco)));
            const autocompleteBlocosOptions: AutocompleteOption[] = blocosUnicos.map((bloco) => ({
                label: bloco,
                id: bloco.toString(),
            }));
            setBlocos(autocompleteBlocosOptions);
        } catch (err: any) {
            console.log(err.response.data);
        }
    };

    const handleBlurCondominio = React.useCallback((e: HTMLInputElement) => {
        if (e.value.trim() !== '') {
            getEconomias(e.value);
            getBlocos(e.value);
        } else {
            setEconomias([]);
            setBlocos([]);
        }
    }, []);

    const handleSindicoOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSindicoOption(value);
        setEconomiaSindico(value === 'economias_sindico');
        setSomenteSindico(value === 'somente_sindico');
        if (value === 'somente_sindico') {
            setShowFieldsEconomia(false);
            setShowFieldsBloco(false);
            setselectedOptionsEconomia('');
            setselectedOptionsBloco('');
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCodcondom(event.target.value);
    };

    const handleTituloChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitulo(event.target.value);
    };

    const handleObservacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObservacao(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCodigoPessoaFromLocalStorage = () => {
        const userString = localStorage.getItem("user");

        const showAlert = (message: string) => {
            setSeverity("error");
            setMsgAlert(message);
            setOpen(true);
        };

        if (userString) {
            try {
                const user = JSON.parse(userString);

                if (user && user.codigo_Pessoa_Imobiliar) {
                    return user.codigo_Pessoa_Imobiliar;
                } else {
                    showAlert("Você não tem permissão suficiente.");
                    return null;
                }
            } catch (error) {
                showAlert("Erro ao parsear o objeto user do localStorage.");
                return null;
            }
        } else {
            showAlert("Item 'user' não encontrado no localStorage.");
            return null;
        }
    };

    useEffect(() => {
        const codigoPessoa = getCodigoPessoaFromLocalStorage();
        if (codigoPessoa) {
            setCodPessoa(codigoPessoa);
        }
    }, []);

    const fetchCondominioInfo = async () => {
        const codCondomInt = parseInt(codcondom, 10);
        if (isNaN(codCondomInt)) {
            setSeverity("error");
            setMsgAlert("Código do condomínio inválido");
            setOpen(true);
            return;
        }
        setIsLoading(true);
        try {
            const response = await buscaAcessosGestorAsync(codpessoa, codCondomInt);
            console.log(response);
            setCondominioInfo(response);
            setSeverity("success");
            setMsgAlert("Acesso concedido");
            setOpen(true);
        } catch (err) {
            setSeverity("error");
            setMsgAlert("Acesso negado ou código inválido");
            setOpen(true);
            setCondominioInfo(null);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadReport = async () => {
        const codCondomInt = parseInt(codcondom, 10);
        if (isNaN(codCondomInt)) {
            setSeverity("error");
            setMsgAlert("Código do condomínio inválido");
            setOpen(true);
            return;
        }

        setIsLoading(true);
        try {
            const economiaInfo: IEconomiaInfo = {
                codcondom: codCondomInt,
                titulo: titulo,
                observacao: observacao,
                economia: selectedOptionsEconomia,
                bloco: selectedOptionsBloco,
                economiaSindico: economiaSindico,
                somenteSindico: somenteSindico,
            };
            
            const response = await imprimeDadosCondominosAsync(economiaInfo);
            
            const blob = new Blob([response]);
            downloadBlob(blob, "Protocolo_Correspondencia_" + codcondom + ".pdf");
            setSeverity("success");
            setMsgAlert("Relatório gerado com sucesso");
            setOpen(true);
        } catch (error) {
            console.error('Erro ao baixar o relatório:', error);
            setSeverity("error");
            setMsgAlert("Erro ao gerar o relatório");
            setOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "left", flexDirection: "column", width: "60%", marginTop: "20px", position: "relative" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "20px" }}>
                <TextField
                    label="Código do Condomínio"
                    value={codcondom}
                    onChange={handleInputChange}
                    onBlur={(e) => handleBlurCondominio(e.target as HTMLInputElement)}
                    sx={{ marginRight: "20px" }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchCondominioInfo}
                >
                    Buscar
                </Button>
            </Box>
            <Box>
                {condominioInfo && (
                    <>
                        <Card sx={{ maxWidth: "400px" }}>
                            <CardContent>
                                <Typography variant="body1">{condominioInfo.nome_Condominio}</Typography>
                                <Typography variant="body2">
                                    {condominioInfo.logradouro}, {condominioInfo.numero_Bloco} - {condominioInfo.bairro_Bloco}, {condominioInfo.cidade_Bloco} - {condominioInfo.uF_Bloco}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Box sx={{ mt: 2 }}>
                            <FormControl>
                                <FormLabel component="legend">Filtros do Relatório</FormLabel>
                                <FormControlLabel
                                    control={<Checkbox checked={showFieldsEconomia} onChange={(e) => handleCheckboxChange(e, 'economias')} />}
                                    label="Selecionar por economias"
                                    disabled={sindicoOption === 'somente_sindico'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={showFieldsBloco} onChange={(e) => handleCheckboxChange(e, 'blocos')} />}
                                    label="Selecionar por blocos"
                                    disabled={sindicoOption === 'somente_sindico'}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={showFieldsSindico} onChange={(e) => handleCheckboxChange(e, 'sindico')} />}
                                    label="Adicionar Síndico"
                                />
                                {showFieldsSindico && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: "8px" }}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="sindico-options"
                                                name="sindico-options"
                                                value={sindicoOption}
                                                onChange={handleSindicoOptionChange}
                                            >
                                                <FormControlLabel value="somente_sindico" control={<Radio />} label="Somente Síndico" />
                                                <FormControlLabel value="economias_sindico" control={<Radio />} label="Economias + Síndico" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                )}
                            </FormControl>
                        </Box>
                        {showFieldsEconomia && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: "8px" }}>
                                <Grid item sx={{ width: '100%' }}>
                                    <Autocomplete
                                        multiple
                                        disablePortal={false}
                                        id="combo-box-economia"
                                        options={economias}
                                        value={economias.filter((option) => selectedOptionsEconomia.includes(option.id)) || []}
                                        onChange={(event, newValue) => {
                                            const selectedIds = newValue.map((option) => option.id).join(', ');
                                            setselectedOptionsEconomia(selectedIds);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Economias"
                                                variant="outlined"
                                                multiline
                                                sx={{
                                                    minHeight: 100,
                                                    height: 150,
                                                    alignItems: 'start',
                                                }}
                                            />
                                        )}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAutocomplete-inputRoot': {
                                                minHeight: 150,
                                                height: 150,
                                                alignItems: 'start',
                                            }
                                        }}
                                    />
                                </Grid>
                            </Box>
                        )}
                        {showFieldsBloco && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: "8px" }}>
                                <Grid item sx={{ width: '100%' }}>
                                    <Autocomplete
                                        multiple
                                        disablePortal={false}
                                        id="combo-box-bloco"
                                        options={blocos}
                                        value={blocos.filter((option) => selectedOptionsBloco.split(',').includes(option.id)) || []}
                                        onChange={(event, newValue) => {
                                            setselectedOptionsBloco(newValue.map((option) => option.id).join(', '));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Blocos"
                                                variant="outlined"
                                                multiline
                                                sx={{
                                                    minHeight: 100,
                                                    height: 150,
                                                    alignItems: 'start',
                                                }}
                                            />
                                        )}
                                        sx={{
                                            width: '100%',
                                            '& .MuiAutocomplete-inputRoot': {
                                                minHeight: 150,
                                                height: 150,
                                                alignItems: 'start',
                                            }
                                        }}
                                    />
                                </Grid>
                            </Box>
                        )}
                        <TextField
                            label="Titulo do Relatório"
                            value={titulo}
                            onChange={handleTituloChange}
                            sx={{ marginTop: "17px" }}
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                        />
                        <TextField
                            label="Observação"
                            value={observacao}
                            onChange={handleObservacaoChange}
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                            fullWidth
                            inputProps={{ maxLength: 250 }}
                            multiline
                            minRows={3}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={downloadReport}
                            sx={{ mt: 2 }}
                        >
                            Gerar Relatório
                        </Button>
                    </>
                )}
            </Box>

            {isLoading && (
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000
                }}>
                    <Loading />
                </Box>
            )}
        </Box>
    );
};

export default ProtocoloCorrespondenciaPage;