import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { CDL } from '../../../interfaces/ICDL';
import Relatorio from '../../../components/common/Relatorio';
import { useJuridico } from '../../../contexts/juridicoProvider';

const RelatorioCDLPage: React.FC = () => {
    const [ficha, setFicha] = useState<CDL[]>([]);
    const { exportaListaCDLAsync, buscaListaCDLAsync } = useJuridico();

    return (
        <div>
            <Typography variant="h6">Relatório CDL</Typography>
            <Relatorio
                busca={buscaListaCDLAsync}
                exporta={exportaListaCDLAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"CDL"}
                refactoring={true}
            />
        </div>
    );
};

export default RelatorioCDLPage;