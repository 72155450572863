
/**
 * Utilizado para deixar a primeira letra do objeto de retorno, maiusculo.
 * Apenas os primeiros dados do objeto sao tratados.
 * @returns exemplo: data { obj1: { subObj1 }, obj2... } > data { Obj1: { subObj1 }, Obj2... }
 */
export function normalizeKeys<T extends object>(obj: any): T {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
        const formattedKey = key.charAt(0).toUpperCase() + key.slice(1); // Converte a primeira letra para maiúscula
        acc[formattedKey] = obj[key]; // Mantém o valor original

        return acc;
    }, {} as any);
}

export function downloadBlob(blob: Blob, fileName: string) {
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
}