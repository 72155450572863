import React, { useState, useEffect } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from '../../../components/common/Loading';
import '../../../components/common/Relatorio.css';
import { IRamal } from './Interface/IRamal';
import { Modal as AntModal } from 'antd';
import { useAlugueis } from '../../../contexts/alugueisProvider';

interface TableDataProps {
  ficha: IRamal[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert }) => {

  return (
    <div style={{ zoom: 0.9 }} className="responsive-table-container">
      {loading ? <Loading /> : null}
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Departamento</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Ramal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((item: IRamal, index) => (
              <TableRow key={index}>
                <TableCell>{item.Username}</TableCell>
                <TableCell>{item.Departamento}</TableCell>
                <TableCell>{item.Email}</TableCell>
                <TableCell>{item.Ramal}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TableData;
