import React, { createContext, useContext, ReactNode } from "react";
import { IAniversariantes } from "../interfaces/IAniversariantes";
import {
    buscarAniversariantesAgenciaV1,
    buscarAniversariantesConsultaIndividual,
    buscarAniversariantesImobiliar,
    buscarBloqueadorEmail,
    buscarRelatorioEmailAniversario,
    buscarRelatorioEmailOnboarding,
    buscarTiposEmail,
    exportarBloqueadorEmail,
    exportarRelatorioEmailAniversario,
    exportarRelatorioEmailOnboarding,
    listarNomeGestoresOnboarding,
    listarTemplatesEmailsOnboarding,
    listarTiposEmailsOnboarding,
    buscarListaErrosOnboarding,
    exportarListaErrosOnboarding
} from "../services/marketing/marketingApi";
import { IPaginationFilter } from "../services/IService";
import { IRelatorioEmailAniversario } from "../pages/marketing/interfaces/IAniversarios";
import { IBloqueioEmail } from "../pages/marketing/interfaces/IBloqueioEmail";
import { IOnboardingEmailsTipo, IOnboardingRelatorio } from "../pages/marketing/interfaces/IOnboarding";
import { IErrosOnboarding } from "../pages/marketing/interfaces/IErrosOnboarding";

interface MarketingContextData {
    buscarAniversariantesAgenciaV1Async: (mesAniversario: number, tipoCliente: string) => Promise<IAniversariantes[]>;
    buscarAniversariantesImobiliarAsync: () => Promise<IAniversariantes[]>;
    buscarAniversariantesConsultaIndividualAsync: (nomePessoa: string) => Promise<IAniversariantes[]>;
    exportaRelatorioEmailAniversarioAsync: (filtros: any) => Promise<any>;
    buscaRelatorioEmailAniversarioAsync: (requestParams: IPaginationFilter) => Promise<IRelatorioEmailAniversario[]>;
    buscaBloqueioEmailAsync: (requestParams: IPaginationFilter) => Promise<IBloqueioEmail[]>;
    exportaBloqueioEmailAsync: (filtros: any) => Promise<any>;
    buscaTiposEmailAsync: () => Promise<string[]>
    buscaRelatorioEmailOnboardingAsync: (requestParams: IPaginationFilter) => Promise<IOnboardingRelatorio[]>;
    exportaRelatorioEmailOnboardingAsync: (requestParams: IPaginationFilter) => Promise<any>;
    listaTiposEmailsOnboardingAsync: () => Promise<IOnboardingEmailsTipo[]>;
    listaNomeGestoresOnboardingAsync:  () => Promise<string[]>;
    listaTemplatesEmailsOnboardingAsyc: () => Promise<string[]>;
    buscaListaErrosOnboarding: (requestParams: IPaginationFilter) => Promise<IErrosOnboarding[]>;
    exportaListaErrosOnboarding: (requestParams: IPaginationFilter) => Promise<any>;
}

const MarketingContext = createContext<MarketingContextData | undefined>(undefined);

interface MarketingProviderProps {
    children: ReactNode;
}

export const MarketingProvider: React.FC<MarketingProviderProps> = ({ children }) => {
    const buscarAniversariantesAgenciaV1Async = async (
        mesAniversario: number,
        tipoCliente: string
    ): Promise<IAniversariantes[]> => {
        return await buscarAniversariantesAgenciaV1(mesAniversario, tipoCliente);
    };

    const buscarAniversariantesImobiliarAsync = async (): Promise<IAniversariantes[]> => {
        return await buscarAniversariantesImobiliar();
    };

    const buscarAniversariantesConsultaIndividualAsync = async (nomePessoa: string): Promise<IAniversariantes[]> => {
        return await buscarAniversariantesConsultaIndividual(nomePessoa);
    };

    const exportaRelatorioEmailAniversarioAsync = async (filtros: any): Promise<any> => {
        const response = await exportarRelatorioEmailAniversario(filtros);
        return response.data;
    };

    const buscaRelatorioEmailAniversarioAsync = async (requestParams: IPaginationFilter): Promise<IRelatorioEmailAniversario[]> => {
        return await buscarRelatorioEmailAniversario(requestParams);
    };

    const buscaBloqueioEmailAsync = async (requestParams: IPaginationFilter): Promise<IBloqueioEmail[]> => {
        return await buscarBloqueadorEmail(requestParams);
    };

    const exportaBloqueioEmailAsync = async (filtros: any): Promise<any> => {
        const response = await exportarBloqueadorEmail(filtros);
        return response.data;
    };

    const buscaTiposEmailAsync = async (): Promise<string[]> => {       
        return await buscarTiposEmail();
    };

    const buscaRelatorioEmailOnboardingAsync = async (requestParams: IPaginationFilter): Promise<IOnboardingRelatorio[]> => {
        return await buscarRelatorioEmailOnboarding(requestParams);
    };

    const exportaRelatorioEmailOnboardingAsync = async (requestParams: IPaginationFilter): Promise<any> => {
        const response = await exportarRelatorioEmailOnboarding(requestParams);
        return response.data;
    };

    const listaTiposEmailsOnboardingAsync = async (): Promise<IOnboardingEmailsTipo[]> => {
        return await listarTiposEmailsOnboarding();
    };

    const listaNomeGestoresOnboardingAsync = async (): Promise<string[]> => {
        return await listarNomeGestoresOnboarding();
    };

    const listaTemplatesEmailsOnboardingAsyc = async (): Promise<string[]> => {
        return await listarTemplatesEmailsOnboarding();
    };

    const buscaListaErrosOnboarding = async (requestParams: IPaginationFilter): Promise<IErrosOnboarding[]> => {
        return await buscarListaErrosOnboarding(requestParams);
    };

    const exportaListaErrosOnboarding = async (requestParams: IPaginationFilter): Promise<any> => {
        return await exportarListaErrosOnboarding(requestParams);
    };

    return (
        <MarketingContext.Provider value={{
            buscarAniversariantesAgenciaV1Async,
            buscarAniversariantesImobiliarAsync,
            buscarAniversariantesConsultaIndividualAsync,
            exportaRelatorioEmailAniversarioAsync,
            buscaRelatorioEmailAniversarioAsync,
            buscaBloqueioEmailAsync,
            exportaBloqueioEmailAsync,
            buscaRelatorioEmailOnboardingAsync,
            exportaRelatorioEmailOnboardingAsync,
            listaTiposEmailsOnboardingAsync,
            listaTemplatesEmailsOnboardingAsyc,
            listaNomeGestoresOnboardingAsync,
            buscaTiposEmailAsync,
            buscaListaErrosOnboarding,
            exportaListaErrosOnboarding
        }}>
            {children}
        </MarketingContext.Provider>
    );
};

// Hook para acessar o contexto
export const useMarketing = (): MarketingContextData => {
    const context = useContext(MarketingContext);
    if (!context) {
        throw new Error("useMarketing must be used within a MarketingProvider");
    }
    return context;
};
