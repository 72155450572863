import axios from "axios";
import { IFichaFinanceira } from "../../interfaces/IVendas";
import { ArquivosAutomacao } from "../../pages/LoteAutomacao/ProcessarArquivoPage";
import { IPaginatedResult } from "../IService";
import { LoteAutomacao } from "../../interfaces/ILoteAutomacao";
import { normalizeKeys } from "../../utils/helpers";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function processarArquivoLoteAutomacao(tipo_arquivo: string, body: ArquivosAutomacao): Promise<any> {
    return (await api.post("/api/LoteAutomacao/ProcessarArquivo?tipo_arquivo=" + tipo_arquivo, body)).data;
}

export async function filtrarLoteAutomacao(body: any): Promise<IPaginatedResult<LoteAutomacao>> {
    const data = (await api.post("/api/LoteAutomacao/Search", body)).data;
    return normalizeKeys(data)
}
