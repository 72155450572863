import React from "react";
import { AuthProvider } from "./auth";
import { MarketingProvider } from "./marketingProvider";
import { AlugueisProvider } from "./alugueisProvider";
import { UtilidadesTIProvider } from "./utilidadesTIProvider";
import { SiteProvider } from "./siteProvider";
import { VendasProvider } from "./vendasProvider";
import { LoteAutomacaoProvider } from "./loteAutomacaoProvider";
import { SegurosProvider } from "./segurosProvider";
import { JuridicoProvider } from "./juridicoProvider";
import { RhProvider } from "./RhProvider";
import { CondominioProvider } from "./CondominioProvider";
import { DPProvider } from "./DPProvider";

type ProviderProps = {
    children: React.ReactNode;
};

type Provider = React.ComponentType<ProviderProps>;

export const composeProviders = (...providers: Provider[]): React.FC<ProviderProps> => {
    return function ComposedProviders({ children }: ProviderProps): React.ReactElement {
        return providers.reduceRight(
            (acc, Provider) => <Provider>{acc}</Provider>,
            children as React.ReactElement
        );
    };
};

const providers = [
    AuthProvider,
    MarketingProvider,
    AlugueisProvider,
    UtilidadesTIProvider,
    SiteProvider,
    VendasProvider,
    LoteAutomacaoProvider,
    SegurosProvider,
    JuridicoProvider,
    RhProvider,
    CondominioProvider,
    DPProvider
];

const AppProviders = composeProviders(...providers);

export default AppProviders;
