import React from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import FilterFormCDL from '../../pages/juridico/RelatorioCDL/FilterFormCDL';
import FilterFormIntegrador from '../../pages/site/RelatorioIntegrador/FilterFormIntegrador';
import FilterFormLeads from '../../pages/site/RelatorioLeads/FilterFormLeads';
import FilterFormAniversario from '../../pages/marketing/RelatorioAniversario/FilterFormAniversario';
import FilterFormNumeroDaSorte from '../../pages/Seguros/NumerosDaSorte/FilterFormNumeroDaSorte';
import FilterFormDesocupacao from '../../pages/alugueis/desocupacao/relatorio/FilterFormDesocupacao';
import FilterFormDesocupados from '../../pages/alugueis/desocupacao/RelatorioDesocupados/FilterFormDesocupados';
import FilterFormBloqueioEmail from '../../pages/marketing/BloqueioEmail/FilterBloqueioEmail';
import FilterFormOnboarding from '../../pages/marketing/RelatorioOnboarding/FilterFormOnboarding';
import FilterFormEsteiraRenovacao from '../../pages/alugueis/RelatorioRenovação/FilterFormEsteiraRenovacao';
import FilterFormErrosRenovacao from '../../pages/alugueis/RelatorioErrosRenovacao/FilterFormErrosRenovacao';
import FilterFormRelatorioEmailSindico from '../../pages/juridico/RelatorioEmailSindico/FilterFormRelatorioEmailSindico';
import FilterFormEmailsBetalabs from '../../pages/Seguros/ControleEmailsBetalabs/FilterFormEmailsBetalabs';
import FilterFormRamais from '../../pages/colaboradores/Ramais/FilterFormRamais';
import FilterFormErrosOnboarding from '../../pages/marketing/ErrosOnboarding/FilterFormErrosOnboarding';

interface FilterFormProps {
    handleSubmit: (event: React.FormEvent) => void;
    handleChangeItensPagina: (event: SelectChangeEvent<number>) => void;
    handleGenerateExcel: (event: React.FormEvent) => void;
    itensPagina: number;
    formData: any;
    setFormData: (data: any) => void;
    relatorio: string;
}

const FilterForm: React.FC<FilterFormProps> = ({
    handleSubmit,
    handleChangeItensPagina,
    handleGenerateExcel,
    itensPagina,
    formData,
    setFormData,
    relatorio
}) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        console.log('name :', name, "value : ", value);
        console.log('formData ', formData);

        if (value !== "") {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            delete formData[name];
            setFormData({ ...formData });
        }
    };

    const handleSelectChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const date = new Date(value);

        if (!isNaN(date.getTime())) {
            setFormData({
                ...formData,
                [name]: new Date(value),
            });
        } else {
            const defaultDate = "0001-01-01";
            const defaultObj = new Date(defaultDate)
            setFormData({
                ...formData,
                [name]: (defaultObj),
            });
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }}>
                {relatorio == "CDL" ? (
                    <FilterFormCDL
                        handleChange={handleChange}
                        formData={formData}
                    />
                ) : relatorio == "Integrador" ? (
                    <FilterFormIntegrador
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        formData={formData}
                    />
                ) : relatorio == "Leads" ? (
                    <FilterFormLeads
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "EmailAniversario" ? (
                    <FilterFormAniversario
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "NumerosDaSorte" ? (
                    <FilterFormNumeroDaSorte
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        formData={formData}
                    />
                ) : relatorio == "Desocupacao" ? (
                    <FilterFormDesocupacao
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "Desocupados" ? (
                    <FilterFormDesocupados
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "BloqueioEmail" ? (
                    <FilterFormBloqueioEmail
                        handleChange={handleChange}
                        formData={formData}
                    />
                ) : relatorio == "EmailOnboarding" ? (
                    <FilterFormOnboarding
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "EmailEsteiraRenovacao" ? (
                    <FilterFormEsteiraRenovacao
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "EmailErrosRenovacao" ? (
                    <FilterFormErrosRenovacao
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "EmailSindico" ? (
                    <FilterFormRelatorioEmailSindico
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                ) : relatorio == "EmailBetalabs" ? (
                    <FilterFormEmailsBetalabs
                        handleSelectChange={handleSelectChange}
                        handleChange={handleChange}
                        handleChangeDate={handleChangeDate}
                        formData={formData}
                    />
                )
                : relatorio == "Ramais" ? (
                    <FilterFormRamais
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        formData={formData}
                    />
                ) : relatorio == "ErrosOnboarding" ? (
                    <FilterFormErrosOnboarding
                    handleSelectChange={handleSelectChange}
                    handleChange={handleChange}
                    handleChangeDate={handleChangeDate}
                    formData={formData}
                    />
                )  : null}
            </div>
            <Grid container sx={{ mt: 2 }} >
                <Grid item xs={2}>
                    <FormControl
                        sx={{
                            width: '100%',
                            marginTop: '10px',
                            marginRight: '10px'
                        }}>

                        <InputLabel className="customLabel">Registros por página</InputLabel>
                        <Select
                            value={itensPagina}
                            onChange={handleChangeItensPagina}
                            className="customSelect"
                            variant="filled"
                            type="number"
                            inputProps={{
                                name: 'itensPagina',
                                id: 'itens-pagina',
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <Button type="submit" sx={{ margin: '10px', float: 'right', width: '20%', height: 50 }} variant="contained">
                        Buscar
                    </Button>
                    <Button color="secondary" sx={{ margin: '10px', float: 'right', width: '20%', height: 50 }} variant="contained" onClick={handleGenerateExcel}>
                        Exportar
                    </Button>

                </Grid>
            </Grid>
        </form>
    );
};

export default FilterForm;
