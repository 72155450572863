import axios from "axios";
import { ICondominio, ICondominioStatusHandler } from "../../interfaces/ICondominio";
import { Economia } from "../../pages/condominio/ImportarEconomiasPage";
import { DadosNotificacao } from "../../interfaces/IERemessa";
import { IERemessaQueryParams } from "../../pages/condominio/ERemessa";
import { IDigifileQueryParams } from "../../pages/condominio/Digifile";
import { IDigifileDTO } from "../../interfaces/IDigifile";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { normalizeKeys } from "../../utils/helpers";
import { ICNDQueryParams } from "../../pages/condominio/CND";
import { ICNDDTO } from "../../interfaces/ICND";
import { CondominioInfo, IEconomiaInfo } from "../../pages/condominio/ProtocoloCorrespondencia";
import { IAtualizaInadimplente } from "../../components/common/BuscaClientes";
import { BuscaClientes } from "../../interfaces/IBuscaClientes";
import { IDocumentacaoInativoData } from "../../pages/condominio/DocumentacaoInativos/DocumentacaoInativos";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function enviarWelcomeKit(condominios: string): Promise<any> {
    return (await api.get("/api/WelcomeKit/EnviaWelcomeKit?codigosCondom=" + condominios)).data;
}

export async function listaCondominios(): Promise<ICondominio[]> {
    return (await api.get("/api/condominio/listarbloqueios")).data;
}

export async function bloqueioInadimplentes(body: ICondominioStatusHandler): Promise<any> {
    return await api.post("/api/BloqueioInadimplentes/handlestatus", body);
}

export async function processarArquivoEconomiaImobiliar(formData: FormData): Promise<Economia[]> {
    return (await api.post("/api/EconomiaImobiliar/ProcessarArquivo", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })).data;
}

export async function importarEconomiaImobiliar(economia: Economia): Promise<any> {
    return await api.post("/api/EconomiaImobiliar/ImportarEconomia", economia);
}

export async function processarArquivoInadimplencia(formData: FormData): Promise<any> {
    return (await api.post("/api/EconomiaImobiliar/ProcessarArquivoInadimplencia", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })).data;
}

export async function importarInadimplencia(economia: Economia): Promise<any> {
    return (await api.post("/api/EconomiaImobiliar/EnviarFilaInadimplencia?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W", economia)).data;
}

export async function filtrarERemessa(body: IERemessaQueryParams): Promise<DadosNotificacao[]> {
    return (await api.post("/api/ERemessa/Search", body)).data;
}

export async function exportarERemessa(body: IERemessaQueryParams): Promise<any> {
    return (await api.post("/api/ERemessa/Exportar", body)).data;
}

export async function filtrarDigifile(body: IDigifileQueryParams): Promise<IPaginatedResult<IDigifileDTO>> {
    return normalizeKeys((await api.post("/api/Digifile/Search", body)).data);
}

export async function exportarDigifile(body: IDigifileQueryParams): Promise<any> {
    return (await api.post("/api/Digifile/Exportar", body, { responseType: 'blob' })).data;
}

export async function filtrarCND(body: ICNDQueryParams): Promise<IPaginatedResult<ICNDDTO>> {
    return normalizeKeys((await api.post("/api/CND/Search", body)).data);
}

export async function exportarCND(body: ICNDQueryParams): Promise<any> {
    return (await api.post("/api/CND/Exportar", body, { responseType: 'blob' })).data;
}

export async function buscarAcessosGestor(Codigo_Pessoa: string, ID_Condominio: number): Promise<CondominioInfo | null> {
    return (await api.post("api/ProtocoloCorrespondencia/Search", { Codigo_Pessoa, ID_Condominio })).data;
}

export const imprimirDadosCondominos = async (dados: IEconomiaInfo) => {
    const requestData = {
        ID_Condominio: dados.codcondom,
        Titulo: dados.titulo,
        Observacao: dados.observacao,
        ID_Economia: dados.economia,
        Bloco: dados.bloco,
        Economia_Sindico: dados.economiaSindico,
        Somente_Sindico: dados.somenteSindico
    };

    return (await api.post("api/ProtocoloCorrespondencia/Report", requestData, { responseType: 'blob' })).data;
};

export async function buscarClientes(params: IPaginationFilter): Promise<IPaginatedResult<BuscaClientes>> {
    return normalizeKeys((await api.get("api/BuscaClientes/Buscar", { params })).data);
};

export async function atualizarInadimplente(data: IAtualizaInadimplente): Promise<any> {
    return (await api.put("api/BuscaClientes/Atualizar", { data })).data;
};

export async function exportarListaBuscaCliente(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/BuscaClientes/Gerar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function getCondominiosGdocs(): Promise<ICondominio[]> {
    return (await api.get("/api/condominio/listar-gdocs")).data;
}

export async function postDocumentacaoInativo(params: IDocumentacaoInativoData): Promise<any> {    
    return await api.post("api/condominio/enviar/documentos-inativos", {...params});
}