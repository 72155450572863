import React, { createContext, useContext, ReactNode } from "react";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { ILeadsImoveis, IntegradorImoveis } from "../interfaces/ISite";
import { buscarListaIntegradorSite, buscarListaLeadsSite, exportarListaIntegradorSite, exportarListaLeadsSite } from "../services/Site/siteApi";

interface SiteProviderProps {
    children: ReactNode;
}

interface SiteContextData {
    buscaListaIntegradorSiteAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IntegradorImoveis>>;
    exportaListaIntegradorSiteAsync: (params: IPaginationFilter) => Promise<any>;
    buscaListaLeadsSiteAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<ILeadsImoveis>>;
    exportaListaLeadsSiteAsync: (params: IPaginationFilter) => Promise<any>;
}

const SiteContext = createContext<SiteContextData | undefined>(undefined);

export const SiteProvider: React.FC<SiteProviderProps> = ({ children }) => {
    const buscaListaIntegradorSiteAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IntegradorImoveis>> => {
        return await buscarListaIntegradorSite(params);
    };

    const exportaListaIntegradorSiteAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaIntegradorSite(params);
    };

    const buscaListaLeadsSiteAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<ILeadsImoveis>> => {
        return await buscarListaLeadsSite(params);
    };
    const exportaListaLeadsSiteAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaLeadsSite(params);
    };

    return (
        <SiteContext.Provider value={{
            buscaListaIntegradorSiteAsync,
            exportaListaIntegradorSiteAsync,
            buscaListaLeadsSiteAsync,
            exportaListaLeadsSiteAsync
        }}>
            {children}
        </SiteContext.Provider>
    );
};

export const useSite = (): SiteContextData => {
    const context = useContext(SiteContext);
    if (!context) {
        throw new Error("useSite must be used within a SiteProvider");
    }
    return context;
};
