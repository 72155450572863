import React, { useState, useContext, useEffect } from 'react';
import { Button, Typography, TextField, Box, Snackbar, Alert, Modal, Backdrop, Fade } from '@mui/material';
import { AuthContext } from '../../contexts/auth';
import Loading from '../../components/common/Loading';
import { ITranscricaoAudioVideo } from '../../interfaces/ITranscricaoAudioVideo';
import { useUtilidadesTI } from '../../contexts/utilidadesTIProvider';

const TranscricaoPage = () => {
  const { validaLinkCompartilhadoAsync, salvaTranscricaoAsync } = useUtilidadesTI();

  const [googleDriveLink, setGoogleDriveLink] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [alert, setAlert] = useState<{ severity: 'success' | 'error'; message: string } | null>(null);
  const [processingDescription, setProcessingDescription] = useState('');

  const specificEmail = 'gdocs-producao@gdocs-producao.iam.gserviceaccount.com'; // Substitua pelo email específico

  const handleProcessClick = async () => {
    if (!googleDriveLink) {
      setAlert({ severity: 'error', message: 'Por favor, insira o link do Google Drive.' });
      return;
    }

    setIsProcessing(true);

    try {
      const isShared = await validaLinkCompartilhadoAsync(googleDriveLink);

        if (!isShared.sucesso) {
            setModalOpen(true);
        } else {

          if (isShared.parametro != "video/mp4" && isShared.parametro != "audio/mp3" && isShared.parametro != "audio/mpeg" && isShared.parametro != "video/webm") {
            console.log(isShared);
            setAlert({ severity: 'error', message: 'Tip do arquivo não é suportado. Por favor, insira um link de um arquivo de áudio ou vídeo.' });
            return;
          }

        const transcricao: ITranscricaoAudioVideo = {
            Id_Usuario: 123,
            Link_Arquivo: googleDriveLink,
            Status: 0,
            Mime_Type: isShared.parametro,
            Link_Resumo: "",
            Link_Transcricao: ""
        };

        await salvaTranscricaoAsync(transcricao);
        setAlert({
          severity: 'success',
          message: 'Sua requisição está sendo processada. Em breve você receberá um email com o resultado.',
        });
        setGoogleDriveLink('');
      }
    } catch (error) {
      setAlert({ severity: 'error', message: 'Erro ao processar a requisição. Tente novamente mais tarde.' });
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box sx={{ minWidth: 300, maxWidth: 600, margin: '0 auto', paddingTop: '2rem' }}>
        <Typography variant="h5" gutterBottom>
          Transcrição de Áudio ou Vídeo
        </Typography>
        <Typography variant="body1" gutterBottom>
          Por favor, insira o link do Google Drive do seu arquivo de áudio ou vídeo. Certifique-se de compartilhar o arquivo com o email{' '}
          <b>{specificEmail}</b>.
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Para compartilhar o arquivo:
          <ol>
            <li>Acesse o Google Drive e encontre o arquivo que deseja compartilhar.</li>
            <li>Clique com o botão direito no arquivo e selecione "Compartilhar".</li>
            <li>Adicione o email <b>{specificEmail}</b>, escolha como "<b>EDITOR</b>" clique em "Enviar".</li>
          </ol>
        </Typography>
        <TextField
          fullWidth
          label="Link do Google Drive"
          variant="outlined"
          value={googleDriveLink}
          onChange={(e) => setGoogleDriveLink(e.target.value)}
          sx={{ marginBottom: '1rem' }}
        />
        <Button variant="contained" color="primary" onClick={handleProcessClick} disabled={isProcessing}>
          Processar
        </Button>
      </Box>

      {isProcessing && <Loading descricao={processingDescription || 'Verificando o link...'} />}

      {/* Modal de aviso de compartilhamento */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Compartilhamento Necessário
            </Typography>
            <Typography variant="body1" gutterBottom>
              O arquivo não está compartilhado com o email <b>{specificEmail}</b>. Por favor, verifique as configurações de compartilhamento e tente novamente.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleModalClose} sx={{ marginTop: '1rem' }}>
              Entendi
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Alertas */}
      {alert && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setAlert(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setAlert(null)} severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default TranscricaoPage;
