import React, { createContext, useContext, ReactNode } from "react";
import { alterarSenha, atualizarSenhaFerias, buscarFerias, buscarLiderTime, buscarUsuario, cadastrarUsuario, 
    enviarSMS, enviarWhats, forcarSenha, pegarPermissoes, salvarFerias, salvarTranscricao, validarLinkCompartilhado, 
    buscarRamais, buscarDepartamentos
 } from "../services/utilidadeTI/utilidadesTIApi";
import { ICadastroUsuario, ILiderUsuario } from "../interfaces/ICadastroUsuario";
import { IPermission } from "./interface/IPermission";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { IFeriasColaborador } from "../interfaces/IFeriasColaborador";
import { IFeriasFilters } from "../pages/TI/FeriasColaboradorPage";
import { IBuscaUsuarioBody, IUsuarioItem } from "../pages/TI/CadastrarFeriasColaboradorPage";
import { ITranscricaoAudioVideo } from "../interfaces/ITranscricaoAudioVideo";
import { IRamal } from "../pages/colaboradores/Ramais/Interface/IRamal";

interface UtilidadesTIProviderProps {
    children: ReactNode;
}

interface UtilidadesTIContextData {
    enviaSMSAsync: (mensagem: string, telefones: string) => Promise<any>;
    cadastraUsuarioAsync: (usuario: ICadastroUsuario) => Promise<any>;
    pegaPermissoesAsync: () => Promise<IPermission[]>;
    buscaLiderTimeAsync: () => Promise<ILiderUsuario[]>;
    buscaFeriasAsync: (body: IFeriasFilters) => Promise<IPaginatedResult<IFeriasColaborador>>;
    enviaWhatsAsync: (idFerias: number | undefined) => Promise<any>;
    forcaSenhaAsync: (idFerias: number | undefined) => Promise<any>;
    atualizaSenhaFeriasAsync: (idFerias: number) => Promise<any>;
    buscaUsuarioAsync: (body: IBuscaUsuarioBody) => Promise<IPaginatedResult<ICadastroUsuario | IUsuarioItem>>
    salvaFeriasAsync: (ferias: IFeriasColaborador) => Promise<any>;
    alteraSenhaAsync: (id: number, senhaAtual: string, novaSenha: string) => Promise<any>;
    validaLinkCompartilhadoAsync: (link: string) => Promise<any>;
    salvaTranscricaoAsync: (transcricao: ITranscricaoAudioVideo) => Promise<any>;
    buscaRamalAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IRamal[]>>;
    buscaDepartamentos: () => Promise<any>;
}

const UtilidadesTIContext = createContext<UtilidadesTIContextData | undefined>(undefined);

export const UtilidadesTIProvider: React.FC<UtilidadesTIProviderProps> = ({ children }) => {

    const enviaSMSAsync = async (mensagem: string, telefones: string): Promise<any> => await enviarSMS(mensagem, telefones);

    const cadastraUsuarioAsync = async (usuario: ICadastroUsuario): Promise<any> => await cadastrarUsuario(usuario);

    const pegaPermissoesAsync = async (): Promise<IPermission[]> => await pegarPermissoes();

    const buscaLiderTimeAsync = async (): Promise<ILiderUsuario[]> => await buscarLiderTime();

    const buscaFeriasAsync = async (body: IFeriasFilters): Promise<IPaginatedResult<IFeriasColaborador>> => {
        return await buscarFerias(body);
    };

    const enviaWhatsAsync = async (idFerias: number | undefined): Promise<any> => await enviarWhats(idFerias);

    const forcaSenhaAsync = async (idFerias: number | undefined): Promise<any> => await forcarSenha(idFerias);

    const atualizaSenhaFeriasAsync = async (idFerias: number): Promise<any> => await atualizarSenhaFerias(idFerias);

    const buscaUsuarioAsync = async (body: IBuscaUsuarioBody): Promise<IPaginatedResult<ICadastroUsuario | IUsuarioItem>> => {
        return await buscarUsuario(body);
    };

    const salvaFeriasAsync = async (body: IFeriasColaborador): Promise<any> => await salvarFerias(body);

    const alteraSenhaAsync = async (id: number, senhaAtual: string, novaSenha: string): Promise<any> => await alterarSenha(id, senhaAtual, novaSenha);

    const validaLinkCompartilhadoAsync = async (link: string): Promise<boolean> => await validarLinkCompartilhado(link);

    const salvaTranscricaoAsync = async (transcricao: ITranscricaoAudioVideo): Promise<boolean> => await salvarTranscricao(transcricao);

    const buscaRamalAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IRamal[]>> => {
            return await buscarRamais(params);
    };

    const buscaDepartamentos = async (): Promise<any> => await buscarDepartamentos();

    return (
        <UtilidadesTIContext.Provider value={{
            enviaSMSAsync,
            cadastraUsuarioAsync,
            pegaPermissoesAsync,
            buscaLiderTimeAsync,
            buscaFeriasAsync,
            enviaWhatsAsync,
            forcaSenhaAsync,
            atualizaSenhaFeriasAsync,
            buscaUsuarioAsync,
            salvaFeriasAsync,
            alteraSenhaAsync,
            validaLinkCompartilhadoAsync,
            salvaTranscricaoAsync,
            buscaRamalAsync,
            buscaDepartamentos
        }}>
            {children}
        </UtilidadesTIContext.Provider>
    );
};

export const useUtilidadesTI = (): UtilidadesTIContextData => {
    const context = useContext(UtilidadesTIContext);
    if (!context) {
        throw new Error("useUtilidadesTI must be used within a UtilidadesTIProvider");
    }
    return context;
};
