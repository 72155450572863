import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { FilterFormRamaisProps } from "./Interface/IRamal";
import { useUtilidadesTI } from "../../../contexts/utilidadesTIProvider";

const FilterFormRamais: React.FC<FilterFormRamaisProps> = ({ handleChange, handleSelectChange, formData }) => {
    const [mostrarSemRamal, setMostrarSemRamal] = useState(false);
    const { buscaDepartamentos } = useUtilidadesTI();
    const [departamentos, setDepartamentos] = useState<string[]>([]);
    useEffect(() => {
        console.log(mostrarSemRamal);
            const fetchTemplatesAndManagers = async () => {
                const departamentosList = await buscaDepartamentos();
                if (departamentosList) {
                    setDepartamentos(departamentosList);
                };
            };
    
            fetchTemplatesAndManagers();
        }, []);
    return (
        <>
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Nome"
                    name="Username"
                    value={formData.username === "0" ? "" : formData.username}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Email"
                    name="Email"
                    value={formData.email === "0" ? "" : formData.email}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <InputLabel id="manager-label">Setor</InputLabel>
                <Select
                    labelId="departamentos-label"
                    label="Departamento"
                    name="Departamento"
                    value={formData["Departamento"]}
                    onChange={handleSelectChange}
                    fullWidth
                >
                    <MenuItem value="">
                        <em>Limpar</em>
                    </MenuItem>
                    {departamentos.map((dep) => (
                        <MenuItem key={dep} value={dep}>
                            {dep}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Ramal"
                    name="Ramal"
                    value={formData.ramal === "0" ? "" : formData.ramal}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            name="NullRamal"
                            checked={mostrarSemRamal}
                            value={mostrarSemRamal ? "false" : "true"}
                            onChange={(e) => {
                                setMostrarSemRamal(e.target.checked);
                                handleSelectChange(e);
                            }}
                        />
                    }
                    label="Incluir colaboradores sem ramal"
                />
            </FormControl>
        </>
    );
};


export default FilterFormRamais;