import React, { useState, useContext, useRef  } from 'react';
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Select, MenuItem, FormControl, Box, InputLabel, Stack, Paper, Typography } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import { LoteAutomacao } from '../../interfaces/ILoteAutomacao';
import Modal from '../../components/common/Modal2';
import moment from 'moment';
import { useLoteAutomacao } from '../../contexts/loteAutomacaoProvider';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
}));
  
const ChartContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
}));

function formatarDataHora(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY HH:mm:ss';
    return moment(dataHora).format(formatoDataHora);
}
  
const PesquisarLoteAutomacao = () => {

    const { filtraLoteAutomacaoAsync } = useLoteAutomacao();
    const campoCod = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [idLote, setIDLote] = useState('');
    const [lotesAutomacao, setLotesAutomacao] = useState<LoteAutomacao[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");

    const handleSubmission = async (e: any) => {
        if (idLote.trim() === '') {
            setMsgAlert('Preencha o Código do Lote');
            setModalOpen(true);
            campoCod.current?.focus();
            return;
        }

        setLoading(true);

        const queryParams = {
            "id": idLote,
            "PageNumber": 1
        };

        let retorno = await filtraLoteAutomacaoAsync(queryParams);
        console.log(retorno.Itens);
        setLotesAutomacao(retorno.Itens);
        setLoading(false);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const renderDados = (): JSX.Element => {  
        return (
            <>
                {lotesAutomacao.map((lote, index) => (
                    <StyledPaper key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom>
                                            ID: <b>{lote.id}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom>
                                            Tipo: <b>{lote.descricao_Tipo}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography variant="body1" gutterBottom>
                                    Data de Criação: {lote.data_Criacao ? formatarDataHora(lote.data_Criacao) : 'N/A'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Data de Atualização: {lote.data_Atualizacao ? formatarDataHora(lote.data_Atualizacao) : 'N/A'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Status Concluído: {lote.status_Concluido ? 'Sim' : 'Não'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Pasta Ignorada: 
                                        <a href={lote.pasta_Ignorada} target="_blank" rel="noopener noreferrer">
                                            {lote.pasta_Ignorada ? ' Baixar Arquivo' : ''}
                                        </a>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Pasta Processada:  
                                        <a href={lote.pasta_Processada} target="_blank" rel="noopener noreferrer">
                                            {lote.pasta_Processada ? 'Baixar Arquivo' : ''}
                                        </a>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ChartContainer>
                                    <Gauge 
                                        width={150} 
                                        height={150}                                         
                                        value={lote.qtd_Fila_Processada} 
                                        valueMax={lote.qtd_Fila} 
                                        text={ ({ value, valueMax }) => `${value} / ${valueMax}`} />
                                </ChartContainer>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                ))}
            </>
        );
    }

    const contents = loading ? <Loading descricao="Pesquisando" /> : renderDados();

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Código Lote"
                        value={idLote}
                        onChange={e => setIDLote(e.target.value)}
                        fullWidth
                        inputRef={campoCod}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" onClick={handleSubmission}>
                        Pesquisar
                    </Button>
                </Grid>
            </Grid>
            <br/>
            {contents} 
        </>
    );
}

export default PesquisarLoteAutomacao;