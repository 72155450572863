import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { ICondominio } from "../../../interfaces/ICondominio";
import { Select, Button, message, Spin } from "antd";
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useCondominio } from "../../../contexts/CondominioProvider";

enum Categoria {
    ATAS = "ATAS",
    CERTIFICADOS_LAUDOS = "CERTIFICADOS/LAUDOS",
    CONTRATOS_FORNECEDORES = "CONTRATOS DE FORNECEDORES",
    CONVENCOES = "CONVENÇÕES",
    DOCUMENTOS_CONDOMINIO = "DOCUMENTOS DO CONDOMÍNIO",
    NOTIFICACOES = "NOTIFICAÇÕES"
}

export interface IDocumentacaoInativoData {
    CodCondom: number;
    Condominio: string;
    Categories: string[];
}

type CategoriesState = {
    [key in Categoria]: boolean;
};

export default function DocumentacaoInativos() {
    const { getCondominiosGdocsAsync, postDocumentacaoInativoAsync } = useCondominio();

    const [condominios, setCondominios] = useState<ICondominio[]>([]);
    const [selectedCondominio, setSelectedCondominio] = useState<ICondominio | null>(null);
    const [categories, setCategories] = useState<CategoriesState>({
        [Categoria.ATAS]: true,
        [Categoria.CERTIFICADOS_LAUDOS]: true,
        [Categoria.CONTRATOS_FORNECEDORES]: true,
        [Categoria.CONVENCOES]: true,
        [Categoria.DOCUMENTOS_CONDOMINIO]: true,
        [Categoria.NOTIFICACOES]: true,
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchCondominios = async () => {
            try {
                const condominiosData: ICondominio[] = await getCondominiosGdocsAsync();
                setCondominios(condominiosData);
            } catch (error) {
                console.error('Error fetching condominios:', error);
            }
        };
        fetchCondominios();
    }, [getCondominiosGdocsAsync]);

    const handleSelectChange = (value: string) => {
        const condominio = condominios.find(cond => cond.Id.toString() === value);
        setSelectedCondominio(condominio || null);
    };

    const handleCheckboxChange = (category: Categoria) => {
        setCategories((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };

    const handleSendEmail = async () => {
        if (selectedCondominio) {
            setLoading(true); // Inicia o estado de carregamento
            try {
                const selectedCategories = Object.keys(categories)
                    .filter((key) => categories[key as Categoria])
                    .map((key) => key);


                const dataToSend: IDocumentacaoInativoData = {
                    CodCondom: selectedCondominio.Id,
                    Condominio: selectedCondominio.Nome,
                    Categories: selectedCategories,
                };

                const data = await postDocumentacaoInativoAsync(dataToSend);

                if (data.status === 200) {
                    message.success('Seus documentos estão sendo processados e serão enviados para o seu email!');
                } else {
                    message.error('Não foi possível enviar os documentos!');
                }
            } catch (error) {
                message.error('Ocorreu um erro ao enviar os documentos.');
                console.error(error);
            } finally {
                setLoading(false); // Finaliza o estado de carregamento
            }
        } else {
            message.error('Selecione um condomínio antes de enviar.');
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', textAlign: 'left' }}>
            <Spin spinning={loading}>
                <div style={{ marginBottom: '20px' }}>
                    <h4>Selecione o Condomínio</h4>
                    <Select
                        placeholder="Selecione um condomínio"
                        style={{ width: '100%' }}
                        value={selectedCondominio?.Id?.toString() || undefined}
                        onChange={handleSelectChange}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {condominios.map((condominio) => (
                            <Select.Option
                                key={condominio.Id}
                                value={condominio.Id.toString()}
                                label={`${condominio.Id}: ${condominio.Nome}`}
                            >
                                {`${condominio.Id}: ${condominio.Nome}`}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div style={{ marginBottom: '20px', textAlign: 'left' }}>
                    <h4>Selecione as Categorias</h4>
                    <Grid container spacing={2}>
                        {Object.values(Categoria).map((category) => (
                            <Grid item xs={6} key={category}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!selectedCondominio ? false : categories[category]}
                                            onChange={() => handleCheckboxChange(category)}
                                            disabled={!selectedCondominio}
                                        />
                                    }
                                    label={category}
                                    style={{ margin: '8px 0' }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <Button type="primary" onClick={handleSendEmail} block disabled={!selectedCondominio || loading}>
                    {loading ? 'Enviando...' : 'Enviar por E-mail'}
                </Button>
            </Spin>
        </div>
    );
}
