import React, { createContext, useContext, ReactNode } from "react";
import { IFichaFinanceira } from "../interfaces/IVendas";
import { gerarBoletoFichaFinanceira, processarFichaFinanceira } from "../services/vendas/vendasApi";

interface VendasProviderProps {
    children: ReactNode;
}

interface vendasContextData {
    processaFichaFinanceiraAsync: (file: FormData) => Promise<IFichaFinanceira | null>;
    geraBoletoFichaFinanceiraAsync: (ficha: IFichaFinanceira | null) => Promise<any>;
}

const vendasContext = createContext<vendasContextData | undefined>(undefined);

export const VendasProvider: React.FC<VendasProviderProps> = ({ children }) => {
    const processaFichaFinanceiraAsync = async (file: FormData): Promise<IFichaFinanceira | null> => {
        return await processarFichaFinanceira(file);
    };

    const geraBoletoFichaFinanceiraAsync = async (ficha: IFichaFinanceira | null): Promise<any> => {
        return await gerarBoletoFichaFinanceira(ficha);
    };

    return (
        <vendasContext.Provider value={{
            processaFichaFinanceiraAsync,
            geraBoletoFichaFinanceiraAsync
        }}>
            {children}
        </vendasContext.Provider>
    );
};

export const useVendas = (): vendasContextData => {
    const context = useContext(vendasContext);
    if (!context) {
        throw new Error("useVendas must be used within a SiteProvider");
    }
    return context;
};
