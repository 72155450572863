import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, TextField, Grid, List, ListItem, ListItemText, Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import Modal from '../../components/common/Modal2';
import { ICNDDTO } from '../../interfaces/ICND';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Pagination from '../../components/common/Pagination';

import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import { downloadBlob } from '../../utils/helpers';
import { useCondominio } from '../../contexts/CondominioProvider';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function StatusIcon(d : ICNDDTO) {
    if (d == null) {
        return <Tooltip title="N&atilde;o Processado"><CheckCircleIcon color="disabled"></CheckCircleIcon></Tooltip>
    }

    if (d.status == 1) {
        return <Tooltip title={d.descricao_Status}><CheckCircleIcon color="success"></CheckCircleIcon></Tooltip>
    } else {
        return <Tooltip title={d.descricao_Status}><CheckCircleIcon color="error"></CheckCircleIcon></Tooltip>
    }
}

function formatDateCell(date: Date | string): XLSX.CellObject {
    // Realize aqui a formatação da data conforme desejado
    const formattedDate = formatarDataHora(date);
    return { v: formattedDate, t: 's' }; // 's' indica que é uma célula de string
  }

export function exportarParaExcel(dados: ICNDDTO[], nomeArquivo: string) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dados);

    // Alterar o nome das colunas
    worksheet['A1'].v = 'Condomínio';
    worksheet['B1'].v = 'CNPJ';
    worksheet['C1'].v = 'Data Emissão';
    worksheet['D1'].v = 'Data Vencimento';
    worksheet['E1'].v = 'Descricao';
    worksheet['F1'].v = 'Tipo CND';
    worksheet['G1'].v = 'Caminho_PDF';
    //worksheet['I1'].v = 'Caminho_PDF';
    console.log(worksheet);
    // Formatar as células de data conforme desejado
    dados.forEach((item, index) => {
        if (item.data_Emissao) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 3 }); // Coluna D
            const formattedCell = formatDateCell(item.data_Emissao);            
            worksheet[cellRef] = formattedCell;
        }

        if (item.data_Vencimento) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 4 }); // Coluna D
            const formattedCell = formatDateCell(item.data_Vencimento);            
            worksheet[cellRef] = formattedCell;
        }
        // Repita o processo para as demais células de data
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados_CND');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(data, nomeArquivo);
}



function formatarDataHora(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY HH:mm:ss';
    return moment(dataHora).format(formatoDataHora);
}

function formatarData(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY';
    return moment(dataHora).format(formatoDataHora);
}

function descricaoTipoCND(tipo: number) {
    var desc = "";
    switch (tipo){
        case 0 : desc = "Federal"; break;
        case 1 : desc = "FGTS"; break;
        case 2 : desc = "Municipal"; break;
    }

    return desc;
}

const optionsStatus = [
    { value: '-1', label: 'Todos Status' },    
    { value: '0', label: 'Com Pendencia' },
    { value: '1', label: 'Negativa' }
];

const optionsTipoCND = [
    { value: '-1', label: 'Todos Tipos' },
    { value: '0', label: 'Federal' },
    { value: '1', label: 'FGTS' },
    { value: '2', label: 'Municipal' }
];

const optionsCidade = [
    { value: '-1', label: 'Todas Cidades' },    
    { value: '0', label: 'CAPAO DA CANOA' },
    { value: '1', label: 'PORTO ALEGRE' },
    { value: '2', label: 'XANGRI-LA' },
    { value: '3', label: 'TORRES' }
];

export interface ICNDQueryParams {
    Tipo_CND: number;
    Status: number;
    ID_Condominio: number | null;
    Cidade: string;
    PageNumber: number;
}


const CND: React.FC = () => {
    const { filtraCNDAsync, exportaCNDAsync } = useCondominio();
    
    const [dados, setDados] = useState<ICNDDTO[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const [idCondominio, setIdCondominio] = useState('');
    const [selectedOptionTipoCND, setselectedOptionTipoCND] = useState('-1');
    const [selectedOptionsStatus, setselectedOptionsStatus] = useState('-1');
    const [selectedOptionsCidade, setselectedOptionsCidade] = useState('Todas Cidades');


    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");

    useEffect(() => {
        busca(paginaAtual);
    }, [paginaAtual, itensPagina]);

    const handleSelectChangeTipoCND = (event: any) => {
        setselectedOptionTipoCND(event.target.value);
    };

    const handleSelectChangeStatus = (event: any) => {
        setselectedOptionsStatus(event.target.value);
    };

    const handleSelectChangeCidade = (event: any) => {
        setselectedOptionsCidade(event.target.value);
    };

    const busca = async (paginaAtual: number) => {
        try {
            setLoading(true);
            const queryParams: ICNDQueryParams = {
                "Tipo_CND": parseInt(selectedOptionTipoCND, 10),
                "Status": parseInt(selectedOptionsStatus, 10),
                "ID_Condominio": idCondominio==='' ? null : parseInt(idCondominio, 10),
                "Cidade": selectedOptionsCidade,
                "PageNumber" : paginaAtual 
            };
    
            let retorno = await filtraCNDAsync(queryParams);
    
            setTotalPaginas(retorno.TotalPaginas);
            setTotalRegistros(retorno.TotalItens);
            setDados(retorno.Itens);
            
        } catch (err: any) {
            console.log(err.response.data);
            //setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        } finally {
            setLoading(false);
        }
    };

    const handleButtonClick = async () => {
        busca(1);
    };

    const handleButtonExportarClick = async () => {
        const queryParams: ICNDQueryParams = {
            "Tipo_CND": parseInt(selectedOptionTipoCND, 10),
            "Status": parseInt(selectedOptionsStatus, 10),
            "ID_Condominio": idCondominio==='' ? null : parseInt(idCondominio, 10),
            "Cidade": selectedOptionsCidade,
            "PageNumber" : 0
        };

        let retorno = await exportaCNDAsync(queryParams);

        const data = new Blob([retorno], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        downloadBlob(data, 'dados_cnd.xlsx');
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    function formatCNPJ(cnpj:any) {
        if (!cnpj) return '';
        
        cnpj = cnpj.replace(/\D/g, '');
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }

    return (
        <>  
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                            label="Código Condomínio"
                        value={idCondominio}
                        onChange={e => setIdCondominio(e.target.value)}
                        fullWidth
                        />
                </Grid>    
                <Grid item xs={12} sm={6}>
                    <Select fullWidth value={selectedOptionTipoCND} onChange={handleSelectChangeTipoCND} >
                        {optionsTipoCND.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>     
                <Grid item xs={12} sm={6}>
                    <Select fullWidth value={selectedOptionsStatus} onChange={handleSelectChangeStatus} >
                        {optionsStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>      
                <Grid item xs={12} sm={6}>
                    <Select fullWidth value={selectedOptionsCidade} onChange={handleSelectChangeCidade} >
                        {optionsCidade.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>          
        </Grid>
                
        <Grid item xs={12} sm={6}>   
            <Grid item xs={12} sm={6}>
                <Button variant="contained" color="primary" onClick={handleButtonClick}>
                    Pesquisar
                </Button> &nbsp;
                <Button variant="contained" color="primary" onClick={handleButtonExportarClick}>
                    Exportar
                </Button>                        
            </Grid>
        </Grid>
            {loading ? (
                <Loading descricao="Buscando as informa&ccedil;&otilde;es"/>
            ) : (
                
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600, "margin-top": "1%" }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Status</StyledTableCell>
                            <StyledTableCell >Condomínio</StyledTableCell>
                            <StyledTableCell >CNPJ</StyledTableCell>
                            <StyledTableCell >Cidade</StyledTableCell>
                            <StyledTableCell >Data Emissão</StyledTableCell>
                            <StyledTableCell >Data Vencimento</StyledTableCell>
                            <StyledTableCell >Tipo CND</StyledTableCell>                            
                            <StyledTableCell >Arquivo</StyledTableCell>                         
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {dados && dados.length > 0 ? (
                        dados.map((cur, index) =>
                            <StyledTableRow key={index}>
                                <StyledTableCell>{StatusIcon(cur)}</StyledTableCell>
                                <StyledTableCell>{cur.iD_Condominio}</StyledTableCell>
                                <StyledTableCell>{formatCNPJ(cur.cnpj)}</StyledTableCell>
                                <StyledTableCell>{cur.cidade}</StyledTableCell>
                                <StyledTableCell>{formatarDataHora(cur.data_Emissao)}</StyledTableCell>
                                <StyledTableCell>{formatarData(cur.data_Vencimento)}</StyledTableCell>
                                <StyledTableCell>{descricaoTipoCND(cur.tipo_CND)}</StyledTableCell>
                                <StyledTableCell>{cur.url_Arquivo ? (
                                    <a href={cur.url_Arquivo} target="_blank" rel="noopener noreferrer">
                                        Abrir PDF
                                    </a>) : ('')}</StyledTableCell>
                            </StyledTableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={7}>Nenhum dado disponível.</TableCell>
                            </TableRow>
                        )}                        
                    </TableBody>                    
                </Table>
                <Pagination
                        paginaAtual={paginaAtual}
                        totalPaginas={totalPaginas}
                        PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                        ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                        IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                        IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                        IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                    />
                <Typography>Total de Itens: {totalRegistros}</Typography>                
            </TableContainer >
            )}
        </>
    );
};

export default CND;
