import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { AuthContext } from "../../contexts/auth";
import { IIndicadorItem } from '../../interfaces/IIndicadorItem';

// Tipos
type ResultadoSimples = {
  name: string;
  value: number;
};

type ResultadoPie = {
  name: string;
  value: number;
};

type ResultadoPieItem = {
  titulo: string;
  data: ResultadoPie[];
};

type ResultadoBar = {
  name: string;
  value: number;
};

type ResultadoBarItem = {
  titulo: string;
  data: ResultadoBar[];
};



// Cores para usar nos gráficos Pie
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A'];

const Dashboard: React.FC = () => {

  const { buscaIndicadores } = useContext(AuthContext);

  const [indicadores, setIndicadores] = useState<IIndicadorItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const fetchIndicadores = async () => {
    try {
      setIsLoading(true);

      const startStr = startDate?.format('YYYY-MM-DD');
      const endStr = endDate?.format('YYYY-MM-DD');

      // MOCK local atualizado
      const mockData: IIndicadorItem[] = [
        {
          "tipo": "simples",
          "resultados": [
            {
              "name": "Digifile",
              "value": 18580
            },
            {
              "name": "Análise Atendimento",
              "value": 35185
            },
            {
              "name": "Boletos Inadimplência",
              "value": 27055
            },
            {
              "name": "Comprovantes Bancários",
              "value": 297186
            },
            {
              "name": "Eremessas",
              "value": 9178
            },
            {
              "name": "Notificações SMS",
              "value": 552096
            },
            {
              "name": "Notificações Email",
              "value": 610449
            },
            {
              "name": "Férias Colaboradores",
              "value": 185
            },
            {
              "name": "ISSQN",
              "value": 2
            },
            {
              "name": "Nota Débito",
              "value": 211752
            },
            {
              "name": "REINF",
              "value": 25584
            },
            {
              "name": "Secovi",
              "value": 6258
            },
            {
              "name": "Uniproof",
              "value": 365
            },
            {
              "name": "Vale Transporte (VT)",
              "value": 1499
            },
            {
              "name": "Transcrição Áudio/Video",
              "value": 62
            }
          ]
        },
        {
          "tipo": "pie",
          "resultados": [
            {
              "titulo": "CNDs Emitidas",
              "data": [
                {
                  "name": "FGTS",
                  "value": 1820
                },
                {
                  "name": "Federal",
                  "value": 1578
                },
                {
                  "name": "Municipal",
                  "value": 2093
                }
              ]
            }
          ]
        }
      ];

      const data = await buscaIndicadores(startStr, endStr);

      // Simula delay
      await new Promise((resolve) => setTimeout(resolve, 600));

      setIndicadores(data);
    } catch (error) {
      console.error('Erro ao buscar indicadores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchIndicadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Bloco simples
  const renderSimples = (resultados: ResultadoSimples[]) => {
    return (
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {resultados.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ backgroundColor: '#fff' }}>
              <CardContent>
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="h4" color="primary">
                  {item.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Bloco pie: array de { titulo, data } => cada 'data' é um subgráfico
  const renderPie = (resultados: { titulo: string; data: ResultadoPie[] }[]) => {
    return (
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {resultados.map((pieItem, idx) => (
          <Grid item xs={12} md={6} key={idx}>
            <Card sx={{ backgroundColor: '#fff', height: 350 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {pieItem.titulo}
                </Typography>
                <Box sx={{ width: '100%', height: 280 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={pieItem.data}
                        dataKey="value"
                        nameKey="name"
                        outerRadius={100}
                        fill="#8884d8"
                        label
                      >
                        {pieItem.data.map((entry, i) => (
                          <Cell
                            key={`cell-${i}`}
                            fill={COLORS[i % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Bloco bar: array de { titulo, data }
  const renderBar = (resultados: { titulo: string; data: ResultadoBar[] }[]) => {
    return (
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {resultados.map((barItem, idx) => (
          <Grid item xs={12} md={6} key={idx}>
            <Card sx={{ backgroundColor: '#fff', height: 350 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {barItem.titulo}
                </Typography>
                <Box sx={{ width: '100%', height: 280 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={barItem.data}
                      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Renderiza o bloco conforme o tipo
  const renderBlock = (item: IIndicadorItem, index: number) => {
    switch (item.tipo) {
      case 'simples':
        return (
          <React.Fragment key={index}>
            {renderSimples(item.resultados)}
          </React.Fragment>
        );
      case 'pie':
        return (
          <React.Fragment key={index}>
            {renderPie(item.resultados)}
          </React.Fragment>
        );
      case 'bar':
        return (
          <React.Fragment key={index}>
            {renderBar(item.resultados)}
          </React.Fragment>
        );
      default:
        return (
          <Box key={index} sx={{ my: 2 }}>
            <Typography variant="body1">
              Tipo <b>{item.tipo}</b> não suportado.
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Indicadores
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DatePicker
            label="Data Inicial"
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
          />
          <DatePicker
            label="Data Final"
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
          />
          <Button variant="contained" onClick={fetchIndicadores}>
            Filtrar
          </Button>
        </Box>
      </LocalizationProvider>

      {isLoading ? (
        <Typography>Carregando indicadores...</Typography>
      ) : (
        <>
          {indicadores.length === 0 ? (
            <Typography>Nenhum indicador disponível.</Typography>
          ) : (
            indicadores.map((item, idx) => renderBlock(item, idx))
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
