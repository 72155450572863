import axios from "axios";
import { IFichaFinanceira } from "../../interfaces/IVendas";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function processarFichaFinanceira(formData: FormData): Promise<IFichaFinanceira | null> {
    return (await api.post("/api/FichaFinanceira/ProcessarArquivo", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })).data;
}

export async function gerarBoletoFichaFinanceira(ficha: IFichaFinanceira | null): Promise<any> {
    return (await api.post("/api/FichaFinanceira/GerarBoleto", ficha)).data;
}