import React, { useState, useEffect } from 'react';
import { AlertProps, Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import { BuscaClientes } from '../../../interfaces/IBuscaClientes';
import Loading from '../../../components/common/Loading';
import '../../../components/common/Relatorio.css'
import { IAtualizaInadimplente } from '../../../components/common/BuscaClientes';

interface TableDataProps {
  ficha: BuscaClientes[];
  loading: boolean;
  modalOpen: boolean;
  handleCloseModal: () => void;
  atualiza: (data: IAtualizaInadimplente) => Promise<any>;
}

interface CheckboxState {
  [key: string]: {
    Ativo: boolean;
    Inadimplente: boolean;
  };
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, atualiza }) => {
  const [severity, setSeverity] = useState('success');
  const [msgAlert, setMsgAlert] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState<BuscaClientes | null>(null);
  const [checkboxValues, setCheckboxValues] = useState<CheckboxState>({});

  useEffect(() => {
    const initialCheckboxValues: CheckboxState = {};
    ficha.forEach(item => {
      initialCheckboxValues[item.Id] = {
        Ativo: item.Ativo,
        Inadimplente: item.Inadimplente
      };
    });
    setCheckboxValues(initialCheckboxValues);
  }, [ficha]);

  const handleCheckboxClick = (id: number, key: keyof CheckboxState[string]) => {
    setCheckboxValues(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [key]: !prevState[id][key]
      }
    }));
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};

  const handleEditButtonClick = (item: BuscaClientes) => {
    setShowModal(true);
    setModalItem(item);
  };

  const handleCloseInativoModal = () => {
    setShowModal(false);
    setModalItem(null);
  };

  const atualizar = async (Id: number, Ativo: boolean, Inadimplente: boolean, Codigo_pessoa: string) => {
    if (modalItem) {

      
      const userString = localStorage.getItem("user");

      if (userString) {
        const user = JSON.parse(userString);
        const UserId = user.id;

        try {
          handleCloseInativoModal();
          setLoadingModal(true);
          await atualiza({Id, Ativo, Inadimplente, Codigo_pessoa, UserId} as IAtualizaInadimplente);
          setLoadingModal(false);
          setMsgAlert("Usuário " + modalItem.Nome_pessoa + " alterado com sucesso!");
          setOpen(true);

        } catch (error) {
          setSeverity("error");
          setMsgAlert("Erro ao atualizar");
          setOpen(true);
        }
      } else {
        setSeverity("error");
        setMsgAlert("Nenhum usuário logado.");
        setOpen(true);
      }
    }
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {msgAlert}
        </Alert>
      </Snackbar>
      <div className="responsive-table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código Pessoa</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Condomínio</TableCell>
              <TableCell>Cpf/Cnpj</TableCell>
              <TableCell>Economia</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Inadimplente</TableCell>
            </TableRow>
          </TableHead>
          {loading ? <Loading /> : ''}
          {loadingModal ? <Loading /> : ''}
          <TableBody>
            {ficha.map((item) => (
              <TableRow key={item?.Id}>
                <TableCell>{item.Codigo_pessoa}</TableCell>
                <TableCell>{item.Nome_pessoa}</TableCell>
                <TableCell>{item.Condominio}</TableCell>
                <TableCell>{item.CpfCnpj}</TableCell>
                <TableCell>{item.Codigo_economia}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={checkboxValues[item.Id]?.Ativo || false}
                    onChange={() => handleCheckboxClick(item.Id, 'Ativo')}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={checkboxValues[item.Id]?.Inadimplente || false}
                    onChange={() => handleCheckboxClick(item.Id, 'Inadimplente')}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditButtonClick(item)}>
                    <SaveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {showModal && modalItem && (
          <Dialog open={showModal} onClose={handleCloseInativoModal}>
            <DialogTitle>
              {`Deseja atualizar o usuário ${modalItem?.Nome_pessoa} para ${checkboxValues[modalItem?.Id]?.Ativo ? 'ativo' : 'inativo'
                } e ${checkboxValues[modalItem?.Id]?.Inadimplente ? 'inadimplente' : 'adimplente'
                }?`}
            </DialogTitle>
            <DialogContent>
              {/* Conteúdo do modal */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseInativoModal} color="secondary">
                Fechar
              </Button>
              <Button onClick={() => atualizar(
                modalItem.Id,
                checkboxValues[modalItem.Id]?.Ativo || false,
                checkboxValues[modalItem.Id]?.Inadimplente || false,
                modalItem.Codigo_pessoa
              )}
                color="primary">
                Salvar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default TableData;