import axios from "axios";
import { IAniversariantes } from "../../interfaces/IAniversariantes";
import { IPaginationFilter } from "../IService";
import { IRelatorioEmailAniversario } from "../../pages/marketing/interfaces/IAniversarios";
import { IBloqueioEmail } from "../../pages/marketing/interfaces/IBloqueioEmail";
import { IOnboardingEmailsTipo, IOnboardingRelatorio } from "../../pages/marketing/interfaces/IOnboarding";
import { IErrosOnboarding } from "../../pages/marketing/interfaces/IErrosOnboarding";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function buscarAniversariantesAgenciaV1(mesAniversario: number, tipoCliente: string): Promise<IAniversariantes[]> {
    const response = await api.get<IAniversariantes[]>("api/aniversariante/agenciav1", {
        params: {
            mesAniversario,
            tipoCliente,
        },
    });

    return response.data;
};
export async function buscarAniversariantesImobiliar(): Promise<IAniversariantes[]> {
    return (await api.get("api/aniversariante/imobiliar")).data;
}

export async function buscarAniversariantesConsultaIndividual(nomePessoa: string): Promise<IAniversariantes[]> {
    var response = await api.get("api/aniversariante/consultaindividual", {
        params: {
            nomePessoa
        },
    });

    return response.data;
}

export async function exportarRelatorioEmailAniversario(Filtros: any): Promise<any> {
    return api.get("api/aniversario/relatorio/emails_exportar", {
        params: Filtros,
        responseType: 'blob'
    });
}

export async function buscarRelatorioEmailAniversario(requestParams: IPaginationFilter): Promise<IRelatorioEmailAniversario[]> {
    return (await api.get("api/aniversario/relatorio/emails", { params: requestParams })).data;
};

export const buscarBloqueadorEmail = async (requestParams: IPaginationFilter): Promise<IBloqueioEmail[]> => {
    return (await api.get("api/EmailBlocker/Relatorio/Listar", { params: requestParams })).data;
}

export const exportarBloqueadorEmail = async (filtros: any): Promise<any> => {
    return api.get("api/EmailBlocker/Relatorio/Exportar", {
        params: filtros,
        responseType: 'blob'
    });
}

export const buscarTiposEmail = async (): Promise<string[]> => {
    return (await api.get("api/EmailBlocker/TiposEmail")).data;
}

export const buscarRelatorioEmailOnboarding = async (requestParams: IPaginationFilter): Promise<IOnboardingRelatorio[]> => {
    return (await api.get("api/onboarding/relatorio/emails", { params: requestParams })).data;
}

export const exportarRelatorioEmailOnboarding = async (requestParams: IPaginationFilter): Promise<any> => {
    return api.get("api/onboarding/relatorio/Emails_exportar", {
        params: requestParams,
        responseType: 'blob'
    });
}

export const listarTiposEmailsOnboarding = async (): Promise<IOnboardingEmailsTipo[]> => {
    return (await api.get("api/onboarding/emails/tipos")).data;
}

export const listarTemplatesEmailsOnboarding = async (): Promise<string[]> => {
    return (await api.get("api/onboarding/emails/templates")).data;
}

export const listarNomeGestoresOnboarding = async (): Promise<string[]> => {
    return (await api.get("api/onboarding/gestores/nomes")).data;
}

export async function buscarListaErrosOnboarding(requestParams: IPaginationFilter): Promise<IErrosOnboarding[]> {
    return (await api.get("api/Onboarding/relatorio/listar-erros", { params: requestParams })).data;
};

export async function exportarListaErrosOnboarding(requestParams: IPaginationFilter): Promise<IErrosOnboarding[]> {
    return (await api.get("api/Onboarding/relatorio/exportar-erros?PageNumber", { params: requestParams })).data;
};