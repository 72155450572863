import axios from "axios";
import { IMatrixDiretoriaELideres, IMatrixNineFilter } from "../../pages/rh/MatrixNineBox";
import { IColaboradorNineBox } from "../../interfaces/ICadastroUsuario";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function getNineBox(params: IMatrixNineFilter): Promise<IColaboradorNineBox[]> {
    return (await api.post("api/Umentor/Search", params, {
        headers: {
          'Content-Type': 'application/json'
        }
      })).data;
}

export async function getDiretoria(): Promise<IMatrixDiretoriaELideres[]> {
    return (await api.get("api/Sispro/Diretorias?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W")).data;
}

export async function getDepartamento(): Promise<(string | null)[]> {
    return (await api.get("api/Sispro/Departamentos?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W")).data;
}

export async function getCargo(): Promise<(string | null)[]> {
    return (await api.get("api/Sispro/Cargos?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W")).data;
}

export async function getLider(): Promise<IMatrixDiretoriaELideres[]> {
    return (await api.get("api/Sispro/Lideres?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W")).data;
}