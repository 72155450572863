import { Divider, Typography } from "@mui/material";
import "../../../styles/styles.css";
import Relatorio from "../../../components/common/Relatorio";
import { IBloqueioEmail } from "../interfaces/IBloqueioEmail";
import { useState } from "react";
import { useMarketing } from "../../../contexts/marketingProvider";

function BloqueioEmail() {
    const {buscaBloqueioEmailAsync, exportaBloqueioEmailAsync } = useMarketing();
    const [ficha, setFicha] = useState<IBloqueioEmail[]>([]);
    return (
        <>
            <Typography variant="h6">Bloqueio de Emails</Typography>
            <Relatorio
                busca={buscaBloqueioEmailAsync} 
                exporta={exportaBloqueioEmailAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"BloqueioEmail"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}

export default BloqueioEmail;
