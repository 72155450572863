import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, CssBaseline, Typography } from '@mui/material';
import AdminDashboard from './AdminDashboard';
import UserHome from './UserHome';

// Exemplo: Obter o perfil (role) do usuário (pode vir de contexto, Redux, ou props)
import { AuthContext } from '../../contexts/auth';

type Props = {};

const HomePage: React.FC<Props> = () => {
  const { user } = useContext(AuthContext); 
  // supondo que user.role === 'ADMIN' ou 'User'
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '50vh',
      }}
    >
      <CssBaseline />

      {user.role === 'ADMIN' ? (
        <AdminDashboard />
      ) : (
        <UserHome />
      )}
    </Box>
  );
};

export default HomePage;
