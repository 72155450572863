import classNames from 'classnames';
import React, { useState, useContext, useEffect } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropZone } from '../../components/common/Dropzone';
import { FileList } from '../../components/common/Filelist';
import { Button } from '@mui/material';
import Loading from '../../components/common/Loading';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { RetornoWS } from './ImportarEconomiasPage';
import { useJuridico } from '../../contexts/juridicoProvider';
import { useCondominio } from '../../contexts/CondominioProvider';


interface Inadimplencia {
    Cod_Condominio: string;
    Condominio: string;
    Economia: string;
    Cod_Economia: string;
    Nome_Condomino: string;
    Competencia: string;
    Data_Vencimento: string;
    Valor: string;
    Retorno_WS: RetornoWS;
}

interface EconomiaItem {
    Id_Economia: number;
    Descricao: string;
    Bloco: string;
}

interface AutocompleteOption {
    label: string;
    id: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function StatusIcon(ret: RetornoWS) {
    if (ret == null) {
        return <Tooltip title="N&atilde;o Processado"><CheckCircleIcon color="disabled"></CheckCircleIcon></Tooltip>
    }

    if (ret.status == 200) {
        return <Tooltip title={ret.descricao}><CheckCircleIcon color="success"></CheckCircleIcon></Tooltip>
    } else {
        return <Tooltip title={ret.descricao}><CheckCircleIcon color="error"></CheckCircleIcon></Tooltip>
    }
}

const ImportarInadimplenciaPage = () => {
    const { processaArquivoInadimplenciaAsync, importaInadimplenciaAsync } = useCondominio();
    const { economiasByCondominioAsync } = useJuridico()

    const [isDropActive, setIsDropActive] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [inadimplencias, setInadimplencia] = useState<Inadimplencia[]>([])
    const [inadimplenciasDistinct, setInadimplenciaDistinct] = useState<Inadimplencia[]>([])
    const [open, setOpen] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50; // Número de itens por página

    const handleSubmission = async (e: any) => {
        setOpen(true);
        const formData = new FormData();

        formData.append('File', files[0]);
        try {
            let retorno = await processaArquivoInadimplenciaAsync(formData);
            setInadimplencia(retorno.lista);
            setInadimplenciaDistinct(retorno.lista2);
        } catch (err: any) {
            setOpen(false);
            console.log(err.response.data);
            //setSeverity("error");
            //setMsgAlert(err.response.data['mensagem']);
        }

        setOpen(false);
    };

    const getEconomias = async (codigo: string) => {
        try {
            let economias = await economiasByCondominioAsync(codigo);

            const autocompleteOptions: AutocompleteOption[] = economias.map((economia) => ({
                label: economia.Bloco + "-" + economia.Descricao,
                id: economia.Id_Economia.toString(),
            }));

            setOptions(autocompleteOptions);
        } catch (err: any) {
            console.log(err.response.data);
            //setSeverity("error");
            //setMsgAlert(err.response.data['mensagem']);
        }
    };

    const handleImportarInadimplencia = async (e: any) => {
        setOpen(true);
        let ec = JSON.parse(JSON.stringify(inadimplencias));
        setDescricao('');
        let i = -1;
        let total = ec.length;
        for (const economia of ec) {
            i++;
            setDescricao(' Importando ' + (i + 1) + ' de ' + total);
            try {
                console.log(economia);
                const json = await importaInadimplenciaAsync(economia);

                const ret = {} as RetornoWS;

                ret.status = json.sucesso ? 200 : 0;
                ret.descricao = json.mensagem;
                ec[i].Retorno_WS = ret;

                setInadimplencia(ec);
            } catch (err: any) {
                setOpen(false);
                console.log(err);
                console.log(err.response.data);
                //setSeverity("error");
                //setMsgAlert(err.response.data['mensagem']);
            }
        };

        setOpen(false);
    };

    const onChangeText = React.useCallback((e: HTMLInputElement, index: number) => {
        let ec = JSON.parse(JSON.stringify(inadimplencias));
        let ecD = JSON.parse(JSON.stringify(inadimplenciasDistinct));
        let i = -1;
        switch (e.name) {
            case 'Cod_Condominio':
                for (const inadimplencia of ec) {
                    i++;
                    ec[i].Cod_Condominio = e.value;
                }
                i = -1;
                for (const inadimplenciasDistinct of ecD) {
                    i++;
                    ecD[i].Cod_Condominio = e.value;
                }

                setInadimplencia(ec);
                setInadimplenciaDistinct(ecD);
                getEconomias(e.value);
                break;
        }
    }, [inadimplencias, inadimplenciasDistinct]);

    const handleOptionChange = (index: number, selectedValue: string | null) => {
        let ec = JSON.parse(JSON.stringify(inadimplencias));
        let ecD = JSON.parse(JSON.stringify(inadimplenciasDistinct));
        let economia = ecD[index];
        let i = -1;
        for (const inadimplencia of ec) {
            i++;
            if (ec[i].Economia == economia.Economia) {
                ec[i].Cod_Economia = selectedValue;
                //console.log(ec[i]);                
            }
        }

        i = -1;
        for (const inadimplenciasDistinct of ecD) {
            i++;
            if (ecD[i].Economia == economia.Economia) {
                ecD[i].Cod_Economia = selectedValue;
            }
        }

        setInadimplencia(ec);
        setInadimplenciaDistinct(ecD);

        console.log(inadimplencias);
    };

    const renderEconomiasTable = (): JSX.Element => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600, "margin-top": "1%" }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >#</StyledTableCell>
                            <StyledTableCell >In</StyledTableCell>
                            <StyledTableCell >Condomínio</StyledTableCell>
                            <StyledTableCell >C&oacute;d. Cond.</StyledTableCell>
                            <StyledTableCell >Economia</StyledTableCell>
                            <StyledTableCell >Economia Imobiliar</StyledTableCell>
                            <StyledTableCell >Nome Pessoa</StyledTableCell>
                            <StyledTableCell >Competencia</StyledTableCell>
                            <StyledTableCell >Data Vencimento</StyledTableCell>
                            <StyledTableCell >Valor</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inadimplenciasDistinct.map((cur, index) =>
                            <StyledTableRow key={index}>
                                <StyledTableCell>{StatusIcon(cur.Retorno_WS)}</StyledTableCell>
                                <StyledTableCell>{index}</StyledTableCell>
                                <StyledTableCell>{cur.Condominio}</StyledTableCell>
                                <StyledTableCell><TextField
                                    fullWidth
                                    hiddenLabel
                                    id="Cod_Condominio"
                                    name="Cod_Condominio"
                                    defaultValue=""
                                    size="small"
                                    variant="standard"
                                    value={cur.Cod_Condominio}
                                    sx={{
                                        width: '120px',
                                        fontSize: 'small'
                                    }}
                                    onChange={(e) => onChangeText((e.target as HTMLInputElement), index)}
                                /></StyledTableCell>
                                <StyledTableCell>{cur.Economia}</StyledTableCell>
                                <StyledTableCell><Autocomplete
                                    disablePortal
                                    id="combo-box-economia"
                                    options={options}
                                    value={options.find((option) => option.id === cur.Cod_Economia) || null}
                                    onChange={(_, newValue) => handleOptionChange(index, newValue?.id || null)}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />
                                </StyledTableCell>
                                <StyledTableCell>{cur.Nome_Condomino}</StyledTableCell>
                                <StyledTableCell>{cur.Competencia}</StyledTableCell>
                                <StyledTableCell>{cur.Data_Vencimento}</StyledTableCell>
                                <StyledTableCell>{cur.Valor}</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>

            </TableContainer >
        );
    }

    /*
    <div>
    {Array.from({ length: Math.ceil(inadimplencias.length / itemsPerPage) }).map((_, index) => (
        <button key={index} onClick={() => setCurrentPage(index + 1)}>
            {index + 1}
        </button>
    ))}
</div>
    */

    /*const renderEconomiasTable = () => {
        return (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <Table
                width={width}
                height={height}
                headerHeight={20}
                rowHeight={30}
                rowCount={inadimplencias.length}
                rowGetter={({ index }: { index: number }) => inadimplencias[index]}
                rowClassName={({ index }: { index: number }) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
              >
                <Column label="#" dataKey="Retorno_WS" cellRenderer={StatusIcon} width={30} />
                <Column label="In" dataKey="index" width={30} />
                <Column label="Condomínio" dataKey="Condominio" width={100} />
                <Column label="Cód. Cond." dataKey="Cod_Condominio" width={100} />
                <Column label="Economia" dataKey="Economia" width={80} />
                <Column
                  label="Economia Imobiliar"
                  dataKey="Cod_Economia"
                  width={100}
                  cellRenderer={({ rowData, dataKey, cellData }: { rowData: Inadimplencia, dataKey: string, cellData: any }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-economia"
                      options={options}
                      value={options.find((option) => option.id === cellData) || null}
                      onChange={(_, newValue) => handleOptionChange(index, newValue?.id || null)}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                    />
                  )}
                />
                <Column label="Nome Pessoa" dataKey="Nome_Condomino" width={100} />
                <Column label="Competencia" dataKey="Competencia" width={100} />
                <Column label="Data Vencimento" dataKey="Data_Vencimento" width={100} />
                <Column label="Valor" dataKey="Valor" width={80} />
              </Table>
            )}
          </AutoSizer>
        );
      };*/
    const contents = open ? <Loading descricao={descricao} /> : renderEconomiasTable();

    const onDragStateChange = React.useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive);
    }, [])

    const onFilesDrop = React.useCallback((files: File[]) => {
        setFiles(files);
    }, [])

    const removeAllFiles = () => {
        setFiles([]);
    }

    return (
        <>
            <div className={classNames('dropZoneWrapper', {
                'dropZoneActive': isDropActive,
            })}>
                <DropZone
                    onDragStateChange={onDragStateChange}
                    onFilesDrop={onFilesDrop}
                >
                    <h2>Arraste seu arquivo .XLSX<br /><CloudUploadIcon /></h2>

                    {files.length === 0 ? (
                        <h3>Sem arquivos</h3>
                    ) : (
                        <h3>Arquivos para Upload: {files.length}</h3>
                    )}

                    <FileList files={files} />
                </DropZone>

            </div>
            <Button sx={{ "margin-top": "1%" }} variant="contained" endIcon={<UploadFileIcon />} onClick={handleSubmission}>
                Processar Arquivo
            </Button>
            <Button sx={{ "margin-top": "1%", "margin-left": "1%" }} variant="contained" endIcon={<IntegrationInstructionsIcon />} onClick={handleImportarInadimplencia}>
                Importar Inadimplencia
            </Button>
            <Button sx={{ "margin-top": "1%", "margin-left": "1%", "backgroundColor": "red" }} variant="contained" endIcon={<DeleteIcon />} onClick={removeAllFiles}>
                Remover arquivos
            </Button>

            {contents}
        </>
    );

}

export default ImportarInadimplenciaPage;