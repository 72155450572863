import React from 'react';
import { Box, Container, CssBaseline, Typography } from '@mui/material';

type Props = {};

const UserHome: React.FC<Props> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '50vh',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          GDocs Guarida
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {'Bem vindo ao novo sistema interno do Grupo Guarida.'}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {
            'Aqui você irá encontrar algumas automações ou funcionalidades que antes se encontravam no SGI.'
          }
        </Typography>
        <Typography variant="body1">
          Em caso de dúvidas entre em contato com a TI.
        </Typography>
      </Container>
    </Box>
  );
};

export default UserHome;
