import React, { createContext, useContext, ReactNode } from "react";
import { filtrarLoteAutomacao, processarArquivoLoteAutomacao } from "../services/loteAutomacao/loteAutomacaoApi";
import { ArquivosAutomacao } from "../pages/LoteAutomacao/ProcessarArquivoPage";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { LoteAutomacao } from "../interfaces/ILoteAutomacao";
import { IBetalabsRelatorio, INumeroDaSorte } from "../interfaces/ISeguros";
import { buscarIntegracaoBetalabs, buscarNumeroSorteSeguros, buscarRelatorioEmailsBetalabs, cadastrarBetalabs, enviarEmailBetalabs, exportarNumerosSorteSeguros, exportarRelatorioEmailBetalabs, importarNumeroSorteSeguros, listarTemplatesEmailsBetalabs, verificarCodigoImobiliar } from "../services/seguros/segurosApi";
import { BuscaIntegracao } from "../interfaces/IBuscaIntegracoesBetalabs";

interface SegurosProviderProps {
    children: ReactNode;
}

interface SegurosContextData {
    buscarNumerosDaSorteAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<INumeroDaSorte>>;
    exportarNumerosDaSorteAsync: (params: IPaginationFilter) => Promise<any>;
    buscaRelatoriosEmailsBetalabsAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IBetalabsRelatorio>>;
    exportaRelatorioEmailBetalabsAsync: (params: IPaginationFilter) => Promise<any>;
    buscaIntegracaoBetalabsAsync: (params: IPaginationFilter, TipoBusca: number) => Promise<IPaginatedResult<BuscaIntegracao>>;
    listaTemplatesEmailsBetalabsAsync: () => Promise<string[]>;
    importarNumerosDaSorteAsync: (numerosDaSorte: INumeroDaSorte[]) => Promise<any>;
    verificaCodigoImobiliarAsync: (Codigo: string) => Promise<any>;
    cadastraBetalabsAsync: (Codigo: string, UserId: number) => Promise<any>;
    enviaEmailBetalabsAsync: (Codigo: string) => Promise<any>;
}

const segurosContext = createContext<SegurosContextData | undefined>(undefined);

export const SegurosProvider: React.FC<SegurosProviderProps> = ({ children }) => {
    const buscarNumerosDaSorteAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<INumeroDaSorte>> => {
        return await buscarNumeroSorteSeguros(params);
    };

    const exportarNumerosDaSorteAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarNumerosSorteSeguros(params);
    };

    const buscaRelatoriosEmailsBetalabsAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IBetalabsRelatorio>> => {
        return await buscarRelatorioEmailsBetalabs(params);
    };

    const exportaRelatorioEmailBetalabsAsync = async (params: IPaginationFilter) => {
        return await exportarRelatorioEmailBetalabs(params);
    };

    const buscaIntegracaoBetalabsAsync = async (params: IPaginationFilter, TipoBusca: number): Promise<IPaginatedResult<BuscaIntegracao>> => {
        return await buscarIntegracaoBetalabs(params, TipoBusca);
    }

    const listaTemplatesEmailsBetalabsAsync = async (): Promise<string[]> => {
        return await listarTemplatesEmailsBetalabs();
    }

    const importarNumerosDaSorteAsync = async (numerosDaSorte: INumeroDaSorte[]): Promise<any> => {
        return await importarNumeroSorteSeguros(numerosDaSorte);
    };


    const verificaCodigoImobiliarAsync = async (Codigo: string): Promise<any> => {
        return await verificarCodigoImobiliar(Codigo);
    };

    const cadastraBetalabsAsync = async (Codigo: string, UserId: number) => {
        const response = await cadastrarBetalabs(Codigo, UserId);
        return response;
    };

    const enviaEmailBetalabsAsync = async (Codigo: string) => {
        const response = await enviarEmailBetalabs(Codigo);
        return response;
    };
    return (
        <segurosContext.Provider value={{
            buscarNumerosDaSorteAsync,
            exportarNumerosDaSorteAsync,
            buscaRelatoriosEmailsBetalabsAsync,
            exportaRelatorioEmailBetalabsAsync,
            buscaIntegracaoBetalabsAsync,
            listaTemplatesEmailsBetalabsAsync,
            importarNumerosDaSorteAsync,
            verificaCodigoImobiliarAsync,
            cadastraBetalabsAsync,
            enviaEmailBetalabsAsync
        }}>
            {children}
        </segurosContext.Provider>
    );
};

export const useSeguros = (): SegurosContextData => {
    const context = useContext(segurosContext);
    if (!context) {
        throw new Error("useSeguros must be used within a SegurosProvider");
    }
    return context;
};
