import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { IRelatorioEmailAniversario } from "../interfaces/IAniversarios";
import "../../../styles/styles.css";
import { useMarketing } from "../../../contexts/marketingProvider";

function RelatorioEmailsAniversario() {
const {buscaRelatorioEmailAniversarioAsync, exportaRelatorioEmailAniversarioAsync } = useMarketing();
const [ficha, setFicha] = useState<IRelatorioEmailAniversario[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails de Aniversário</Typography>
            <Relatorio
                busca={buscaRelatorioEmailAniversarioAsync}
                exporta={exportaRelatorioEmailAniversarioAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailAniversario"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}

export default RelatorioEmailsAniversario;
