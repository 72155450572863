import axios from "axios";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { IBetalabsRelatorio, INumeroDaSorte } from "../../interfaces/ISeguros";
import { normalizeKeys } from "../../utils/helpers";
import { BuscaIntegracao } from "../../interfaces/IBuscaIntegracoesBetalabs";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function buscarNumeroSorteSeguros(params: IPaginationFilter): Promise<IPaginatedResult<INumeroDaSorte>> {
    return normalizeKeys((await api.get("api/numerosorte/buscar", { params })).data);
};

export async function exportarNumerosSorteSeguros(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/numerosorte/exportar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarRelatorioEmailsBetalabs(params: IPaginationFilter): Promise<IPaginatedResult<IBetalabsRelatorio>> {
    return normalizeKeys((await api.get("api/IntegracaoBetalabs/relatorio/emails", { params })).data);
}

export async function exportarRelatorioEmailBetalabs(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/IntegracaoBetalabs/relatorio/Emails_exportar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarIntegracaoBetalabs(params: IPaginationFilter, TipoBusca: number): Promise<IPaginatedResult<BuscaIntegracao>> {
    return normalizeKeys((await api.get("api/IntegracaoBetalabs/Buscar", { params: { ...params,TipoBusca}  })).data);
}

export async function listarTemplatesEmailsBetalabs(): Promise<string[]> {
    return (await api.get("api/IntegracaoBetalabs/emails/templates")).data;
}

export async function importarNumeroSorteSeguros(listaNumeros: INumeroDaSorte[]): Promise<any> {
    return await api.post("api/numerosorte/importar", JSON.stringify(listaNumeros), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
}

export async function verificarCodigoImobiliar(codigo: string): Promise<any>{
    return (await api.post("/api/IntegracaoBetalabs/VerificaCodigo", {codigo})).data;
};

export async function cadastrarBetalabs(codigo: string, userId: number): Promise<any>{
    return (await api.post("/api/IntegracaoBetalabs/Cadastrar", {codigo, userId})).data;
};

export async function enviarEmailBetalabs(codigo: string): Promise<any>{
    return (await api.post("/api/IntegracaoBetalabs/EnviarEmail", {codigo})).data;
};
