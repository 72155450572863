import React, { createContext, useContext, ReactNode } from "react";
import { atualizarInadimplente, bloqueioInadimplentes, buscarAcessosGestor, buscarClientes, enviarWelcomeKit, exportarCND, exportarDigifile, exportarERemessa, exportarListaBuscaCliente, filtrarCND, filtrarDigifile, filtrarERemessa, getCondominiosGdocs, importarEconomiaImobiliar, importarInadimplencia, imprimirDadosCondominos, listaCondominios, postDocumentacaoInativo, processarArquivoEconomiaImobiliar, processarArquivoInadimplencia } from "../services/condominio/condominioApi";
import { ICondominio, ICondominioStatusHandler } from "../interfaces/ICondominio";
import { Economia } from "../pages/condominio/ImportarEconomiasPage";
import { DadosNotificacao } from "../interfaces/IERemessa";
import { IERemessaQueryParams } from "../pages/condominio/ERemessa";
import { IDigifileDTO } from "../interfaces/IDigifile";
import { IDigifileQueryParams } from "../pages/condominio/Digifile";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { ICNDDTO } from "../interfaces/ICND";
import { ICNDQueryParams } from "../pages/condominio/CND";
import { CondominioInfo, IEconomiaInfo } from "../pages/condominio/ProtocoloCorrespondencia";
import { IAtualizaInadimplente } from "../components/common/BuscaClientes";
import { BuscaClientes } from "../interfaces/IBuscaClientes";
import { IDocumentacaoInativoData } from "../pages/condominio/DocumentacaoInativos/DocumentacaoInativos";

interface CondominioProviderProps {
    children: ReactNode;
}

interface CondominioContextData {
    envioWelcomeKitAsync: (condominios: string) => Promise<any>;
    pegaCondominiosAsync: () => Promise<ICondominio[]>;
    postBloqueioInadimplentesAsync: (body: ICondominioStatusHandler) => Promise<any>;
    processaArquivoEconomiaImobiliarAsync: (file: FormData) => Promise<Economia[]>;
    importaEconomiaImobiliarAsync: (economia: Economia) => Promise<any>;
    processaArquivoInadimplenciaAsync: (economia: FormData) => Promise<any>;
    importaInadimplenciaAsync: (economia: Economia) => Promise<any>;
    filtraERemessaAsync: (body: IERemessaQueryParams) => Promise<DadosNotificacao[]>;
    exportaERemessaAsync: (body: IERemessaQueryParams) => Promise<any>;
    filtraDigifileAsync: (body: IDigifileQueryParams) => Promise<IPaginatedResult<IDigifileDTO>>;
    exportaDigifileAsync: (body: IDigifileQueryParams) => Promise<any>;
    filtraCNDAsync: (body: ICNDQueryParams) => Promise<IPaginatedResult<ICNDDTO>>;
    exportaCNDAsync: (body: ICNDQueryParams) => Promise<any>;
    buscaAcessosGestorAsync: (codpessoa: string, codcondom: number) => Promise<CondominioInfo | null>;
    imprimeDadosCondominosAsync: (body: IEconomiaInfo) => Promise<any>;
    buscaClientesAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<BuscaClientes>>;
    atualizaInadimplenteAsync: (data: IAtualizaInadimplente) => Promise<any>;
    exportaListaBuscaClientesAsync: (params: IPaginationFilter) => Promise<any>;
    getCondominiosGdocsAsync: () => Promise<ICondominio[]>;
    postDocumentacaoInativoAsync: (params: any) => Promise<any>;

}

const condominioContext = createContext<CondominioContextData | undefined>(undefined);

export const CondominioProvider: React.FC<CondominioProviderProps> = ({ children }) => {

    const envioWelcomeKitAsync = async (condominios: string): Promise<any> => {
        return await enviarWelcomeKit(condominios);
    };

    const pegaCondominiosAsync = async (): Promise<ICondominio[]> => await listaCondominios();

    const postBloqueioInadimplentesAsync = async (body: ICondominioStatusHandler): Promise<any> => {
        return await bloqueioInadimplentes(body);
    }

    const processaArquivoEconomiaImobiliarAsync = async (file: FormData): Promise<Economia[]> => {
        return await processarArquivoEconomiaImobiliar(file);
    };

    const importaEconomiaImobiliarAsync = async (economia: Economia): Promise<any> => {
        return await importarEconomiaImobiliar(economia);
    };

    const processaArquivoInadimplenciaAsync = async (economia: FormData): Promise<any> => {
        return await processarArquivoInadimplencia(economia);
    };

    const importaInadimplenciaAsync = async (economia: Economia): Promise<any> => {
        return await importarInadimplencia(economia);
    };

    const filtraERemessaAsync = async (body: IERemessaQueryParams): Promise<DadosNotificacao[]> => {
        return await filtrarERemessa(body);
    };

    const exportaERemessaAsync = async (body: IERemessaQueryParams): Promise<any> => {
        return await exportarERemessa(body);
    };

    const filtraDigifileAsync = async (body: IDigifileQueryParams): Promise<IPaginatedResult<IDigifileDTO>> => {
        return await filtrarDigifile(body);
    };

    const exportaDigifileAsync = async (body: IDigifileQueryParams): Promise<any> => {
        return await exportarDigifile(body);
    };

    const filtraCNDAsync = async (body: ICNDQueryParams): Promise<IPaginatedResult<ICNDDTO>> => {
        return await filtrarCND(body);
    };

    const exportaCNDAsync = async (body: ICNDQueryParams): Promise<any> => {
        return await exportarCND(body);
    };

    const buscaAcessosGestorAsync = async (codpessoa: string, codcondom: number): Promise<CondominioInfo | null> => {
        return await buscarAcessosGestor(codpessoa, codcondom);
    }

    const imprimeDadosCondominosAsync = async (body: IEconomiaInfo): Promise<any> => {
        return await imprimirDadosCondominos(body);
    }

    const buscaClientesAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<BuscaClientes>> => {
        return await buscarClientes(params);
    }

    const atualizaInadimplenteAsync = async (data: IAtualizaInadimplente): Promise<any> => {
        return await atualizarInadimplente(data);
    }

    const exportaListaBuscaClientesAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaBuscaCliente(params);
    };

    const getCondominiosGdocsAsync = async (): Promise<ICondominio[]> => {
        return await getCondominiosGdocs();
    }

    const postDocumentacaoInativoAsync = async (params: IDocumentacaoInativoData): Promise<any> => {
        return await postDocumentacaoInativo(params);
    };

    return (
        <condominioContext.Provider value={{
            envioWelcomeKitAsync,
            pegaCondominiosAsync,
            postBloqueioInadimplentesAsync,
            processaArquivoEconomiaImobiliarAsync,
            importaEconomiaImobiliarAsync,
            processaArquivoInadimplenciaAsync,
            importaInadimplenciaAsync,
            filtraERemessaAsync,
            exportaERemessaAsync,
            filtraDigifileAsync,
            exportaDigifileAsync,
            filtraCNDAsync,
            exportaCNDAsync,
            buscaAcessosGestorAsync,
            imprimeDadosCondominosAsync,
            buscaClientesAsync,
            atualizaInadimplenteAsync,
            exportaListaBuscaClientesAsync,
            getCondominiosGdocsAsync,
            postDocumentacaoInativoAsync
        }}>
            {children}
        </condominioContext.Provider>
    );
};

export const useCondominio = (): CondominioContextData => {
    const context = useContext(condominioContext);
    if (!context) {
        throw new Error("useCondominio must be used within a CondominioProvider");
    }
    return context;
};
