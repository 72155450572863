import axios from "axios";
import { IVPNLiberacao, IVPNLiberacaoFilter } from '../../interfaces/IVPNLiberacao';
import { IPaginatedResult, IPaginationFilter } from "../IService";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function getLiberacoesVPN(filtro: IVPNLiberacaoFilter): Promise<IPaginatedResult<IVPNLiberacao>> {
    return (await api.get("api/VPNLiberacao/BuscarLiberacoesVPN", { params: filtro })).data;
}

export async function bloqueioLiberacaoVPN(id: number): Promise<boolean> {
     (await api.post("api/VPNLiberacao/ForcarBloqueioLiberacaoVPN/" + id));
     return true;
}

export async function salvarLiberacaoVPN(body: IVPNLiberacao): Promise<boolean> {
    return await api.post("api/VPNLiberacao/SalvarLiberacaoVPN", body);
}
