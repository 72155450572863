import React, { useState, useContext, useRef, useEffect } from 'react';
import { Paper, Button, TextField, Grid, List, ListItem, ListItemText, Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import MaskedTextField from '../../components/common/MaskedTextField';
import Modal from '../../components/common/Modal2';
import { IDigifileDTO, IDigifileFiltro } from '../../interfaces/IDigifile';
import Pagination from '../../components/common/Pagination';

import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import { useCondominio } from '../../contexts/CondominioProvider';
import { downloadBlob } from '../../utils/helpers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function StatusIcon(d : IDigifileDTO) {
    if (d == null) {
        return <Tooltip title="N&atilde;o Processado"><CheckCircleIcon color="disabled"></CheckCircleIcon></Tooltip>
    }

    if (d.status_Integracao == 1) {
        return <Tooltip title={d.descricao_Integracao}><CheckCircleIcon color="success"></CheckCircleIcon></Tooltip>
    } else {
        return <Tooltip title={d.descricao_Integracao}><CheckCircleIcon color="error"></CheckCircleIcon></Tooltip>
    }
}

function formatarDataHora(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY HH:mm:ss';
    return moment(dataHora).format(formatoDataHora);
}

const options = [
    { value: '0', label: 'TIPO DE DOCUMENTO' },    
    { value: '1', label: 'Integrado com Sucesso' },
    { value: '2', label: 'Erro na Integração' }
];

export interface IDigifileQueryParams {
    Competencia: string;
    Status_Integracao: number;
    ID_Condominio: number | null;
    PageNumber: number;
}

const Digifile: React.FC = () => {
    const { filtraDigifileAsync, exportaDigifileAsync } = useCondominio();

    const campoCod = useRef<HTMLInputElement>(null);
    
    const [dados, setDados] = useState<IDigifileDTO[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const [competencia, setCompetencia] = useState('');
    const [idCondominio, setIdCondominio] = useState('');
    const [selectedOption, setSelectedOption] = useState('0');


    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");

    useEffect(() => {
        busca(paginaAtual);
    }, [paginaAtual, itensPagina]);

    const handleSelectChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const busca = async (paginaAtual: number) => {
        try {
            setLoading(true);
            const queryParams: IDigifileQueryParams = {
                "Competencia": competencia,
                "Status_Integracao": parseInt(selectedOption, 10),
                "ID_Condominio": idCondominio==='' ? null : parseInt(idCondominio, 10),
                "PageNumber" : paginaAtual
            };

            let retorno = await filtraDigifileAsync(queryParams);
    
            setTotalPaginas(retorno.TotalPaginas);
            setTotalRegistros(retorno.TotalItens);
            setDados(retorno.Itens);
            
        } catch (err: any) {
            console.log(err.response.data);
            //setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        } finally {
            setLoading(false);
        }
    };

    const handleButtonClick = async () => {
        if (competencia.trim() === '') {
            setMsgAlert('Preencha a Competência');
            setModalOpen(true);
            campoCod.current?.focus();
            return;
        }

        busca(1);
    };

    const handleButtonExportarClick = async () => {
        const queryParams: IDigifileQueryParams = {
            "Competencia": competencia,
            "Status_Integracao": parseInt(selectedOption, 10),
            "ID_Condominio": idCondominio==='' ? null : parseInt(idCondominio, 10),
            "PageNumber" : 0
        };

        let retorno = await exportaDigifileAsync(queryParams);

        const data = new Blob([retorno], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        downloadBlob(data, 'dados_digifile.xlsx');
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handlePrimeiraPagina = () => {
        setPaginaAtual(1);
    }

    const handleUltimaPagina = () => {
        setPaginaAtual(totalPaginas);
    }

    return (
        <>  
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
        <TextField
                        label="Competência"
          value={competencia}
          onChange={e => setCompetencia(e.target.value)}
          fullWidth
          inputRef={campoCod}
          /*InputProps={{
            inputComponent: MaskedTextField,
          }}*/
        />
                    
        </Grid>
                <Grid item xs={12} sm={6}>
                    <Select fullWidth value={selectedOption} onChange={handleSelectChange} >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
      <Grid item xs={12} sm={6}>
      <TextField
                        label="Código Condomínio"
          value={idCondominio}
          onChange={e => setIdCondominio(e.target.value)}
          fullWidth
        />
      </Grid>

    <Grid item xs={12} sm={6}>
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Pesquisar
                    </Button> &nbsp;
                    <Button variant="contained" color="primary" onClick={handleButtonExportarClick}>
                        Exportar
                    </Button>
                    
                </Grid>
    </Grid>
            {loading ? (
                <Loading descricao="Buscando as informa&ccedil;&otilde;es"/>
            ) : (
                
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600, "margin-top": "1%" }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Status</StyledTableCell>
                            <StyledTableCell >Condomínio</StyledTableCell>
                            <StyledTableCell >Competencia</StyledTableCell>
                            <StyledTableCell >Data Integracao</StyledTableCell>
                            <StyledTableCell >Numero Lançamento</StyledTableCell>
                            <StyledTableCell >Valor Lançamento</StyledTableCell>
                            <StyledTableCell >Arquivo</StyledTableCell>                         
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {dados && dados.length > 0 ? (
                        dados.map((cur, index) =>
                            <StyledTableRow key={index}>
                                <StyledTableCell>{StatusIcon(cur)}</StyledTableCell>
                                <StyledTableCell>{cur.iD_Condominio}</StyledTableCell>
                                <StyledTableCell>{cur.competencia}</StyledTableCell>
                                <StyledTableCell>{formatarDataHora(cur.data_Integracao)}</StyledTableCell>
                                <StyledTableCell>{cur.numero_Lancamento}</StyledTableCell>
                                <StyledTableCell>{cur.valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</StyledTableCell>
                                <StyledTableCell><a href={cur.caminho_PDF} target="_blank" rel="noopener noreferrer">
                                    Abrir PDF
                                </a></StyledTableCell>
                            </StyledTableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={7}>Nenhum dado disponível.</TableCell>
                            </TableRow>
                        )}                        
                    </TableBody>                    
                </Table>
                <Pagination
                        paginaAtual={paginaAtual}
                        totalPaginas={totalPaginas}
                        PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                        ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                        IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                        IrParaPrimeiraPagina={handlePrimeiraPagina}
                        IrParaUltimaPagina={handleUltimaPagina}
                    />
                <Typography>Total de Itens: {totalRegistros}</Typography>                
            </TableContainer >
            )}
        </>
    );
};

export default Digifile;
