import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useParams, useNavigate } from 'react-router-dom';
import {
  Button, TextField, Grid, AppBar, Toolbar, Typography, Tooltip, Autocomplete, IconButton, AutocompleteChangeReason,
  AutocompleteChangeDetails, Avatar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IFeriasColaborador } from '../../interfaces/IFeriasColaborador';
import 'dayjs/locale/pt-br';
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AuthContext } from "../../contexts/auth";
import { ICadastroUsuario } from '../../interfaces/ICadastroUsuario';
import dayjs from 'dayjs';
import { useUtilidadesTI } from '../../contexts/utilidadesTIProvider';

export interface AutocompleteOption {
  label: string;
  id: string;
}

export interface IUsuarioItem {
  id: number;
  username: string;
}

export interface IBuscaUsuarioBody {
  id?: number;
  username?: string;
  email?: string;
  PageNumber: number;
}

const CadastrarFeriasColaboradorPage: React.FC = () => {
  const location = useLocation();
  const currentFerias = location.state?.currentFerias;
  const navigate = useNavigate();
  const [ferias, setFerias] = useState<IFeriasColaborador>({ usuario: null, senha_Alterada: '', enviado_Whats: false });
  const [usuarios, setUsuarios] = useState<AutocompleteOption[]>([]);
  const [selectedOptionsUsuario, setSelectedOptionsUsuario] = useState<string | null>(null);
  const { buscaUsuarioAsync, salvaFeriasAsync } = useUtilidadesTI();

  useEffect(() => {
    getUsuarios();
    console.log(JSON.stringify(currentFerias, null, 2));
    if (currentFerias) {
      setFerias({
        ...currentFerias,
        data_Inicio_Ferias: dayjs(currentFerias.data_Inicio_Ferias.$d || currentFerias.data_Inicio_Ferias), // Verifica se já é um objeto dayjs
        data_Fim_Ferias: dayjs(currentFerias.data_Fim_Ferias.$d || currentFerias.data_Fim_Ferias),
        data_Retorno: dayjs(currentFerias.data_Retorno.$d || currentFerias.data_Retorno),
        data_Hora_Envio_Whats: currentFerias.data_Hora_Envio_Whats ? dayjs(currentFerias.data_Hora_Envio_Whats.$d || currentFerias.data_Hora_Envio_Whats) : null,
      });

      if (currentFerias.usuario) {
        setSelectedOptionsUsuario(currentFerias.usuario.id.toString());
      }
    }

  }, []);

  const handleChange = (prop: keyof IFeriasColaborador, value: any) => {
    setFerias({ ...ferias, [prop]: value });
  };

  const getUsuarios = async () => {
    try {
      var body: IBuscaUsuarioBody = {
        id: undefined,
        username: undefined,
        email: undefined,
        PageNumber: 0
      };

      var data = await buscaUsuarioAsync(body);

      const _usuarios = data.Itens as IUsuarioItem[];
    
      const autocompleteOptions: AutocompleteOption[] = _usuarios.map((usuario: IUsuarioItem) => ({
        label: usuario.username,
        id: usuario.id.toString(),
      }));

      setUsuarios(autocompleteOptions);
    } catch (error) {
      console.error('Erro ao buscar dados da API Usuarios:', error);
    }
  };

  async function getUsuarioByID(id: number): Promise<ICadastroUsuario | undefined> {
    try {
      const body = {
        id: id, // Agora usando um tipo mais específico para `id`
        username: undefined,
        email: undefined,
        PageNumber: 0
      };

      const data = await buscaUsuarioAsync(body); // Supondo que `buscaUsuario` seja uma função definida em algum lugar

      const _usuario = data.Itens;

      // Acessando o primeiro usuário
      const primeiroUsuario = _usuario[0]; // Ou use qualquer método acima que prefira

      return primeiroUsuario as ICadastroUsuario | undefined;
    } catch (error) {
      console.error('Erro ao buscar dados da API Usuarios:', error);
      return undefined; // Retorna undefined em caso de erro
    }
  }

  const handleSave = async () => {
    await salvaFeriasAsync(ferias);
    navigate('/ti/ferias');
  };

  const handleSelectChangeUsuario = async (
    _event: SyntheticEvent<Element, Event>,
    newValue: AutocompleteOption | null,
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<AutocompleteOption> | undefined
  ) => {
    if (newValue) {
      setSelectedOptionsUsuario(newValue.id);

      // Como getUsuarioByID é uma função assíncrona, precisamos usar await para obter o resultado
      const u = await getUsuarioByID(Number(newValue.id));

      // Checa se 'u' não é undefined antes de atualizar o estado
      if (u !== undefined) {
        setFerias({ ...ferias, usuario: u });
        console.log('chegou aqui');
        console.log(ferias);
      } else {
        // Se nenhum usuário foi encontrado, pode querer limpar o usuário anteriormente selecionado
        setFerias({ ...ferias, usuario: null });
      }

    } else {
      setSelectedOptionsUsuario(null);
      setFerias({ ...ferias, usuario: null });
    }
  };

  const handleCopyPassword = (password: string) => {
    navigator.clipboard.writeText(password).then(() => {
      console.log('Senha copiada para a área de transferência');
    }).catch(err => {
      console.error('Erro ao copiar senha: ', err);
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">{currentFerias !== undefined ? 'Editar Férias' : 'Nova Férias'}</Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} style={{ padding: 20 }}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar
              src={ferias.usuario?.urL_Foto ? ferias.usuario.urL_Foto.toString() : ""}
              alt="Foto do Usuário"
              sx={{ width: 70, height: 70 }}
            />
          </Grid>
          <Grid item xs>
            <Autocomplete
              disablePortal
              id="combo-box-usuario"
              options={usuarios}
              value={usuarios.find((option) => option.id === selectedOptionsUsuario) || null}
              onChange={handleSelectChangeUsuario}
              renderInput={(params) => <TextField {...params} label="Usuario" variant="outlined" fullWidth />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Data de Início das Férias"
            value={ferias.data_Inicio_Ferias}
            onChange={(newDate) => setFerias({ ...ferias, data_Inicio_Ferias: newDate })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Data de Fim das Férias"
            value={ferias.data_Fim_Ferias}
            onChange={(newDate) => setFerias({ ...ferias, data_Fim_Ferias: newDate })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label="Data de Retorno"
            value={dayjs(ferias.data_Retorno)}
            onChange={(newDate) => setFerias({ ...ferias, data_Retorno: newDate })}
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <TextField
            disabled
            label="Senha"
            value={ferias.senha_Alterada}
            type="password"
          />
          <Tooltip title="Visualizar Senha">
            <IconButton
              onClick={() => handleCopyPassword(ferias.senha_Alterada)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvar
          </Button>
          <Button variant="outlined" color="secondary" style={{ marginLeft: 10 }} component={Link} to="/ti/ferias">
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default CadastrarFeriasColaboradorPage;