import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Autocomplete,
  Avatar,
  Container,
  Paper,
  createFilterOptions
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { ICadastroUsuario } from '../../interfaces/ICadastroUsuario';
import { IVPNLiberacao, ICadastroUsuarioVPN } from '../../interfaces/IVPNLiberacao';
import { useUtilidadesTI } from '../../contexts/utilidadesTIProvider';
import { useDP } from '../../contexts/DPProvider';

interface AutocompleteOption {
  label: string;
  id: string;
}

export interface IUsuarioItem {
  id: number;
  username: string;
}

export interface IBuscaUsuarioBody {
  id?: number;
  username?: string;
  email?: string;
  PageNumber: number;
}

const CadastrarVPNLiberacaoPage: React.FC = () => {
  const location = useLocation();
  const currentLiberacao = location.state?.currentLiberacao as any | undefined;
  const navigate = useNavigate();
  const [liberacao, setLiberacao] = useState<IVPNLiberacao>({
    Usuario: null,
    data_inicio_liberacao: null,
    data_fim_liberacao: null
  });
  const [usuarios, setUsuarios] = useState<AutocompleteOption[]>([]);
  const [selectedOptionsUsuario, setSelectedOptionsUsuario] = useState<string | null>(null);
  const { buscaUsuarioAsync } = useUtilidadesTI();
  const { salvarLiberacaoVPNAsync } = useDP();

  useEffect(() => {
    getUsuarios();
    if (currentLiberacao) {
      
      setLiberacao({
        ...currentLiberacao,
        data_inicio_liberacao: dayjs(currentLiberacao.Data_Inicio_Liberacao),
        data_fim_liberacao: dayjs(currentLiberacao.Data_Fim_Liberacao)
      });

      if (currentLiberacao.Usuario?.Id) {
        setSelectedOptionsUsuario(currentLiberacao.Usuario.Id.toString());
      }
    }
  }, []);

  const handleChange = (prop: keyof IVPNLiberacao, value: any) => {
    setLiberacao({ ...liberacao, [prop]: value });
  };

  const getUsuarios = async () => {
    try {
      const body: IBuscaUsuarioBody = {
        id: undefined,
        username: undefined,
        email: undefined,
        PageNumber: 0
      };

      const data = await buscaUsuarioAsync(body);
      const _usuarios = data.Itens as IUsuarioItem[];
      const autocompleteOptions: AutocompleteOption[] = _usuarios.map((usuario: IUsuarioItem) => ({
        label: usuario.username,
        id: usuario.id.toString()
      }));

      setUsuarios(autocompleteOptions);
    } catch (error) {
      console.error('Erro ao buscar dados da API Usuarios:', error);
    }
  };

  async function getUsuarioByID(id: number): Promise<ICadastroUsuario | undefined> {
    try {
      const body = {
        id: id,
        username: undefined,
        email: undefined,
        PageNumber: 0
      };

      const data = await buscaUsuarioAsync(body);
      const _usuario = data.Itens;
      const primeiroUsuario = _usuario[0];

      return primeiroUsuario as ICadastroUsuario | undefined;
    } catch (error) {
      console.error('Erro ao buscar dados da API Usuarios:', error);
      return undefined;
    }
  }

  const handleSave = async () => {
    if (!liberacao.Usuario || !liberacao.data_inicio_liberacao || !liberacao.data_fim_liberacao) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    console.log(liberacao);
    await salvarLiberacaoVPNAsync(liberacao);
    navigate('/dp/vpnliberacao');
  };

  const handleSelectChangeUsuario = async (
    _event: SyntheticEvent<Element, Event>,
    newValue: AutocompleteOption | null
  ) => {
    if (newValue) {
      setSelectedOptionsUsuario(newValue.id);

      const u = await getUsuarioByID(Number(newValue.id));
      const uVPN = {
        Id: u?.id,
        Username: u?.username,
        Email: u?.email,
        UrL_Foto: u?.urL_Foto
      } as ICadastroUsuarioVPN;

      if (u !== undefined) {
        setLiberacao({ ...liberacao, Usuario: uVPN });
      } else {
        setLiberacao({ ...liberacao, Usuario: null });
      }
    } else {
      setSelectedOptionsUsuario(null);
      setLiberacao({ ...liberacao, Usuario: null });
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: AutocompleteOption) => option.label
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            {currentLiberacao ? 'Editar Liberação' : 'Nova Liberação'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  src={liberacao.Usuario?.UrL_Foto ? liberacao.Usuario.UrL_Foto : ''}
                  alt="Foto do Usuário"
                  sx={{ width: 70, height: 70 }}
                />
              </Grid>
              <Grid item xs>
                <Autocomplete
                  disablePortal
                  id="combo-box-usuario"
                  options={usuarios}
                  value={usuarios.find((option) => option.id === selectedOptionsUsuario) || null}
                  onChange={handleSelectChangeUsuario}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField {...params} label="Usuário" variant="outlined" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
            <DatePicker
              label="Data de Início da Liberação"
              value={liberacao.data_inicio_liberacao}
              onChange={(newDate) => setLiberacao({ ...liberacao, data_inicio_liberacao: newDate })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            </Grid>
            <Grid item xs={12} md={6}>
            <DatePicker
              label="Data de Fim da Liberação"
              value={liberacao.data_fim_liberacao}
              onChange={(newDate) => setLiberacao({ ...liberacao, data_fim_liberacao: newDate })}
              slotProps={{ textField: { fullWidth: true } }}
            />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Salvar
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="secondary" component={Link} to="/dp/vpnliberacao">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default CadastrarVPNLiberacaoPage;
