import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { IOnboardingRelatorio } from "../interfaces/IOnboarding";
import "../../../styles/styles.css";
import { useMarketing } from "../../../contexts/marketingProvider";

function RelatorioEmailsOnboarding() {
const {buscaRelatorioEmailOnboardingAsync, exportaRelatorioEmailOnboardingAsync } = useMarketing();
const [ficha, setFicha] = useState<IOnboardingRelatorio[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails de Onboarding</Typography>
            <Relatorio
                busca={buscaRelatorioEmailOnboardingAsync}
                exporta={exportaRelatorioEmailOnboardingAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailOnboarding"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}

export default RelatorioEmailsOnboarding;
