import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IDesocupacaoImoveis } from '../../../../interfaces/ISite';
import Relatorio from '../../../../components/common/Relatorio';
import { useAlugueis } from '../../../../contexts/alugueisProvider';

const RelatorioDesocupadosPage: React.FC = () => {
    const [ficha, setFicha] = useState<IDesocupacaoImoveis[]>([]);
    const { buscaListaDesocupadosAsync, exportaListaDesocupadosAsync } = useAlugueis();

    return (
        <div>
            <Typography variant="h6">Relatório Desocupados</Typography>
            <Relatorio
                busca={buscaListaDesocupadosAsync}
                exporta={exportaListaDesocupadosAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Desocupados"}
                refactoring={true}
            />
        </div>
    );
};

export default RelatorioDesocupadosPage;