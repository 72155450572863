import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { ILeadsImoveis } from '../../../interfaces/ISite';
import Relatorio from '../../../components/common/Relatorio';
import { useSite } from '../../../contexts/siteProvider';

const RelatorioLeadsPage: React.FC = () => {
    const { exportaListaLeadsSiteAsync, buscaListaLeadsSiteAsync } = useSite();
    const [ficha, setFicha] = useState<ILeadsImoveis[]>([]);

    return (
        <div>
            <Typography variant="h6">Relatório Leads</Typography>
            <Relatorio
                busca={buscaListaLeadsSiteAsync}
                exporta={exportaListaLeadsSiteAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Leads"}
                refactoring={true}
            />
        </div>
    );
};

export default RelatorioLeadsPage;