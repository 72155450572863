import { Outlet, useLocation  } from "react-router-dom";
import { Box } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import Topbar from "../common/Topbar";
import { useContext, ReactNode} from "react";
import usePageTitle from "../../routes/usePageTitle";
import { AuthContext } from "../../contexts/auth";

interface MainLayoutProps {
  children?: ReactNode;
} 
const MainLayout : React.FC<MainLayoutProps> = ({ children }) => {
  const {sideBarOpen, setSideBarOpen} = useContext(AuthContext);
  const pageTitle = usePageTitle();

  const toggleDrawer = () => {
    setSideBarOpen(false);
  };

  return (
    <>
    <Topbar open={false} toggleDrawer={toggleDrawer} pageTitle={pageTitle}/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "64px",
          width: "90%",
          maxWidth: "1600",
          backgroundColor: colorConfigs.mainBg,
          mx: "auto",
        }}
      >
        {children}
      </Box>
    </>
  );
}  

export default MainLayout;