import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ILeadsImoveis } from '../../../interfaces/ISite';
import Loading from '../../../components/common/Loading';
import { formatarData } from '../../../utils/formatUtils';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css'


interface TableDataProps {
  ficha: ILeadsImoveis[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  const padraoCodigo = /Codigo: (\d+)/g;
  return (
    <div className="responsive-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Código Imóvel</TableCell>
            <TableCell>Negocio</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Resposta CRM</TableCell>
            <TableCell>Data Criação</TableCell>
            <TableCell>Data/Hora Visita</TableCell>
          </TableRow>
        </TableHead>
        {loading ? <Loading /> : ''}
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <TableBody>
          {ficha.map((item) => (
            <TableRow key={item?.Id}>
              <TableCell>{item.Id}</TableCell>
              <TableCell>{item.Codigo_imovel}</TableCell>
              <TableCell>{item.Negocio === 0 ? 'Alugar' : 'Vendas'}</TableCell>
              <TableCell>{item.Tipo}</TableCell>
              <TableCell>{item.Nome}</TableCell>
              <TableCell>{item.Email}</TableCell>
              <TableCell>{item.Telefone}</TableCell>
              <TableCell>
              {item.Negocio === 0 && item.Resposta_crm !== null ?
                  (item.Resposta_crm.includes("incluído com sucesso!") ? "Lead Incluido" : "Lead Não Incluido") :
                  item.Negocio === 1 && item.Resposta_crm !== null ?
                  (item.Resposta_crm.includes("Lead Enviado com sucesso") ? item.Resposta_crm.match(padraoCodigo) : "Lead Não Incluido") :
                  ""
                }
              </TableCell>
              <TableCell>{formatarData(item.Criado_em)}</TableCell>
              <TableCell>{formatarData(item.Data_hora_visita)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableData;
