import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { IntegradorImoveis } from '../../../interfaces/ISite';
import Relatorio from '../../../components/common/Relatorio';
import { useSite } from '../../../contexts/siteProvider';

const RelatorioIntegradorPage: React.FC = () => {
    const [ficha, setFicha] = useState<IntegradorImoveis[]>([]);
    const { buscaListaIntegradorSiteAsync, exportaListaIntegradorSiteAsync } = useSite();

    return (
        <div>
            <Typography variant="h6">Relatório Integrador</Typography>
            <Relatorio
                busca={buscaListaIntegradorSiteAsync}
                exporta={exportaListaIntegradorSiteAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Integrador"}
                refactoring={true}
            />
        </div>
    );
};

export default RelatorioIntegradorPage;