import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import '../../../components/common/modal.css';
import { Autocomplete, Button, CircularProgress, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { AuthContext } from "../../../contexts/auth";
import { IBloqueioEmail } from '../../marketing/interfaces/IBloqueioEmail';
import { useAlugueis } from '../../../contexts/alugueisProvider';

interface ModalProps {
    email: string | null;
    codimovel: number | null;
    emailType: string;
    codPessoa: number;
    isOpen: boolean;
    onClose: () => void;
    msgAlert: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSeverity: Dispatch<SetStateAction<'success' | 'error'>>;
    setMsg: Dispatch<SetStateAction<string>>;
    ficha: IBloqueioEmail[];
    setFicha(ficha: IBloqueioEmail[]): void;
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Modal: React.FC<ModalProps> = ({ email, codimovel, emailType, codPessoa, isOpen, onClose, msgAlert, setOpen, setSeverity, setMsg, ficha, setFicha }) => {
    const [tipo, setTipo] = useState(emailType);
    const [tipoOptions] = useState<string[]>(['Proprietario', 'Locatario']);
    const [loading, setLoading] = useState(false);
    const [blockType, setBlockType] = useState<'imovel' | 'permanente'>('imovel');
    const {criaBloqueadorEmailAsync, setCodPessoaBlockRenovacao } = useAlugueis();
    const [editableEmail, setEditableEmail] = useState<string | null>(email);
    const [editableCodimovel, setEditableCodimovel] = useState<number | null>(codimovel);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTipo(emailType.includes('Proprietario') ? 'Proprietario' : 'Locatario');
    }, [emailType]);

    const handleAdd = async () => {
        setIsLoading(true);
        try {
            const emailParaBloquear = blockType === 'imovel' ? null : editableEmail;
            const codimovelParaBloquear = blockType === 'permanente' ? null : editableCodimovel;
            const tipoBloqueio = tipo.charAt(0).toUpperCase() + tipo.slice(1).toLowerCase() === 'Proprietario' ? 'renovacaoproprietario' : 'renovacaolocatario';
            
            const novoRegistro: IBloqueioEmail = {
                ClientEmail: emailParaBloquear,
                Codimovel: codimovelParaBloquear,
                EmailType: tipoBloqueio,
            };

            const response = await criaBloqueadorEmailAsync(novoRegistro);
            if (response === 200) {
                setCodPessoaBlockRenovacao(codPessoa);
                
                setFicha([...ficha, novoRegistro]);
                setMsg("Email bloqueado com sucesso");
                setSeverity("success");
                setOpen(true);
            }
        } catch (error) {
            setMsg("Erro ao bloquear registro");
            setSeverity("error");
            setOpen(true);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const handleClose = () => {
        onClose();
        setEditableEmail(null);
        setEditableCodimovel(null);
    };

    const isFormValid = () => {
        const isEmailValid = emailRegex.test(editableEmail || '');
        const isCodimovelValid = editableCodimovel !== null && editableCodimovel >= 0;
        return isEmailValid && isCodimovelValid;
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    <TextField
                        label="Email"
                        value={editableEmail || ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setEditableEmail(e.target.value)}
                        required
                        error={!emailRegex.test(editableEmail || '')}
                        helperText={!emailRegex.test(editableEmail || '') ? "Endereço de email inválido" : ""}
                    />
                    <TextField
                        label="Codimovel"
                        value={editableCodimovel !== null ? String(editableCodimovel) : ''}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setEditableCodimovel(Number(e.target.value) || null)}
                        required
                        error={editableCodimovel !== null && editableCodimovel < 0}
                        helperText={editableCodimovel !== null && editableCodimovel < 0 ? "Código inválido" : ""}
                    />
                    <Autocomplete
                        value={tipo}
                        onChange={(event, newValue) => setTipo(newValue || '')}
                        options={tipoOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={24} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <RadioGroup
                        value={blockType}
                        onChange={(e) => setBlockType(e.target.value as 'imovel' | 'permanente')}
                    >
                        <FormControlLabel value="imovel" control={<Radio />} label="Bloquear imóvel" />
                        <FormControlLabel value="permanente" control={<Radio />} label="Bloquear cliente permanentemente" />
                    </RadioGroup>
                </div>
                <div className="modal-footer">
                    <Button 
                        onClick={handleClose} 
                        sx={{ mr: 1}}>Cancelar</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}
                        disabled={isLoading || !isFormValid()}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Bloquear'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
