import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Relatorio from "../../../components/common/Relatorio";
import "../../../styles/styles.css";
import { IClientErro } from "../interfaces/IRenovacaoErros";
import { useAlugueis } from "../../../contexts/alugueisProvider";

export default function RelatorioEsteiraRenovacao() {
const {buscaErrosRelatorioEsteiraRenovacaoAsync, exportaRelatorioErrosEsteiraRenovacaoAsync } = useAlugueis();
const [ficha, setFicha] = useState<IClientErro[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Erros - Emails Esteira de Renovação</Typography>
            <Relatorio
                busca={buscaErrosRelatorioEsteiraRenovacaoAsync}
                exporta={exportaRelatorioErrosEsteiraRenovacaoAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailErrosRenovacao"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}