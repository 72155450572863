import axios from "axios";
import { ICadastroUsuario, ILiderUsuario } from "../../interfaces/ICadastroUsuario";
import { IPermission } from "../../contexts/interface/IPermission";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { IFeriasColaborador } from "../../interfaces/IFeriasColaborador";
import { IFeriasFilters } from "../../pages/TI/FeriasColaboradorPage";
import { normalizeKeys } from "../../utils/helpers";
import { IBuscaUsuarioBody, IUsuarioItem } from "../../pages/TI/CadastrarFeriasColaboradorPage";
import { ITranscricaoAudioVideo } from "../../interfaces/ITranscricaoAudioVideo";
import { IRamal } from "../../pages/colaboradores/Ramais/Interface/IRamal";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";

console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function enviarSMS(mensagem: string, telefones: string): Promise<any> {
    return await api.post("/api/Common/EnviaSMS", { mensagem, telefones });
};

export async function cadastrarUsuario(usuario: ICadastroUsuario): Promise<any> {
    return await api.post("/api/UsuarioCadastro", usuario);
};

export async function pegarPermissoes(): Promise<IPermission[]> {
    return (await api.get("/api/Permissoes/pegarPermissoes")).data;
};

export async function buscarLiderTime(): Promise<ILiderUsuario[]> {
    return (await api.get("/api/UsuarioCadastro/ListarLiderTime")).data;
};

export async function buscarFerias(body: IFeriasFilters): Promise<IPaginatedResult<IFeriasColaborador>> {
    const data = (await api.post("/api/FeriasColaborador/Search", body)).data;
    return normalizeKeys(data);
};

export async function enviarWhats(idferias: number | undefined): Promise<any> {
    return api.post("/api/FeriasColaborador/EnviarWhats/" + idferias + "?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W");
};

export async function forcarSenha(idferias: number | undefined): Promise<any> {
    return api.post("/api/FeriasColaborador/AlterarAcessos/" + idferias + "?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W");
};

export async function atualizarSenhaFerias(idferias: number): Promise<any> {
    return api.post("/api/FeriasColaborador/AtualizarSenhaFerias/" + idferias);
};

export async function buscarUsuario(body: IBuscaUsuarioBody): Promise<IPaginatedResult<ICadastroUsuario>> {
    const data = (await api.post("/api/UsuarioCadastro/Listar", body)).data;
    return normalizeKeys(data);
};

export async function salvarFerias (ferias: IFeriasColaborador): Promise<any> {
    return await api.post("/api/FeriasColaborador/Salvar", ferias);
};

export const alterarSenha = async (id: number, senhaAtual: string, novaSenha: string): Promise<any> => {
    return await api.put("/api/UsuarioCadastro/alterarsenha", { id, senhaAtual, novaSenha });
};

export const validarLinkCompartilhado = async (Link: string): Promise<any> => {
    return (await api.get("api/IA/ValidarAcessoLink?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W&link=" + Link)).data;
};

export const salvarTranscricao = async (trancricao: ITranscricaoAudioVideo): Promise<any> => {
    return (await api.post("api/IA/SalvarTranscricao", trancricao)).data;
};

export async function buscarRamais(params: IPaginationFilter): Promise<IPaginatedResult<IRamal[]>> {
    return (await api.get("api/UsuarioCadastro/Ramais", { params })).data;
};
export async function buscarDepartamentos(): Promise<any> {
    return (await api.get("api/Sispro/Departamentos?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W")).data;
};