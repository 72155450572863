import React, { useContext, useEffect, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { CSSTransition } from "react-transition-group";
import { Button, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import Loading from "../../components/common/Loading";
import { IAniversariantes } from "../../interfaces/IAniversariantes";
import Divider from "@mui/material/Divider";
import Modal from "../../components/common/Modal";
import './ListaAniversariantes.css'
import PrintIcon from '@mui/icons-material/Print'
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import Footer from "../../components/common/Footer";
import Pagination from "../../components/common/Pagination";
import { useMarketing } from "../../contexts/marketingProvider";

function ListaAniversariantes() {
    const { 
        buscarAniversariantesAgenciaV1Async,
        buscarAniversariantesImobiliarAsync,
        buscarAniversariantesConsultaIndividualAsync } = useMarketing();

    const [clientes, setClientes] = useState<IAniversariantes[]>([]);
    const [tipoCliente, setTipoCliente] = useState('');
    const [mesAniversario, setMesAniversario] = useState('');
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [consultaIndividual, setConsultaIndividual] = useState(false);
    const [nomeAniversariante, setNomeAniversariante] = useState('');
    const itensPorPagina = 20;

    const [pesquisarClientes, setPesquisarClientes] = useState(false);
    const [mostrarBody, setMostrarBody] = useState(false);

    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const fetchDataAgenciaV1 = async (mesAniversario: number, tipoCliente: string) => {
        try {
            setLoading(true);
            const data = await buscarAniversariantesAgenciaV1Async(mesAniversario, tipoCliente);
            setClientes(data);
        } catch (error) {
            console.log('Erro ao carregar dados:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataImobiliar = async () => {
        try {
            setLoading(true);
            const data = await buscarAniversariantesImobiliarAsync();
            setClientes(data);
        } catch (error) {
            console.log('Erro ao carregar dados:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchDataConsultaIndividual = async (nomePessoa: string) => {
        try {
            setLoading(true);
            const data = await buscarAniversariantesConsultaIndividualAsync(nomePessoa);
            setClientes(data);
        } catch (error) {
            console.log('Erro ao carregar dados:', error);
        } finally {
            setLoading(false);
        }
    }


    const calcularTotalPaginas = () => {
        return Math.ceil(totalClientes / itensPorPagina);
    };

    const calcularTotalClientes = () => {
        if (consultaIndividual) {
            return clientes.length;
        }
        const aniversariantesDoMes = clientes.filter(cliente => {
            const [dia, mes, ano] = cliente.aniversario.split('/');
            const dataAniversario = new Date(Number(ano), Number(mes) - 1, Number(dia));
            const mesAniversarioCliente = dataAniversario.getMonth() + 1;
            return mesAniversarioCliente.toString() === mesAniversario;
        });
        return aniversariantesDoMes.length;
    };

    const totalClientes = calcularTotalClientes();

    const totalPaginas = calcularTotalPaginas();

    const renderizarItensDaPagina = () => {
        const indiceInicial = (paginaAtual - 1) * itensPorPagina;
        const indiceFinal = Math.min(indiceInicial + itensPorPagina, clientes.length);

        if (consultaIndividual) {
            return clientes
                .slice(indiceInicial, indiceFinal)
                .map(cliente => (
                    <tr key={cliente.codigo_pessoa}>
                        <td>{cliente.nome_pessoa}</td>
                        <td>{cliente.aniversario}</td>
                        <td>{cliente.email}</td>
                    </tr>
                ));
        }

        const aniversariantesDoMes = clientes.filter(cliente => {
            const [dia, mes, ano] = cliente.aniversario.split('/');
            const dataAniversario = new Date(Number(ano), Number(mes) - 1, Number(dia));
            const mesAniversarioCliente = dataAniversario.getMonth() + 1;
            return mesAniversarioCliente.toString() === mesAniversario;
        });

        const nomesUnicos = new Set<string>();
        const itensDaPagina = aniversariantesDoMes
            .filter(cliente => {
                if (!nomesUnicos.has(cliente.nome_pessoa)) {
                    nomesUnicos.add(cliente.nome_pessoa);
                    return true;
                }
                return false;
            })
            .slice(indiceInicial, indiceFinal)
            .map(cliente => (
                <tr key={cliente.codigo_pessoa}>
                    <td>{cliente.nome_pessoa}</td>
                    <td>{cliente.aniversario}</td>
                    <td>{cliente.email}</td>
                </tr>
            ));

        return itensDaPagina;
    };

    const refazerPesquisa = () => {
        setTipoCliente('');
        setMesAniversario('');
        setClientes([]);
        setPaginaAtual(1);
        setNomeAniversariante('');
        setMostrarBody(false);
    }

    const handlePaginaAnterior = () => {
        if (paginaAtual > 1) {
            setPaginaAtual(paginaAtual - 1);
        }
    };

    const handleProximaPagina = () => {
        if (paginaAtual < totalPaginas) {
            setPaginaAtual(paginaAtual + 1);
        }
    };

    const handleIrParaPagina = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPaginas) {
            setPaginaAtual(pageNumber);
        }
    };

    const handlePrimeiraPagina = () => {
        setPaginaAtual(1);
    }

    const handleUltimaPagina = () => {
        setPaginaAtual(totalPaginas);
    }

    const handleTipoClienteChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        if (!mostrarBody) {
            setTipoCliente(event?.target.value);
        }
    }

    const handleMesAniversarioChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        if (!mostrarBody) {
            setMesAniversario(event.target.value);
        }
    }

    const handleConsultaIndividualChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setTipoCliente('');
        setMesAniversario('');
        setNomeAniversariante('');
        setMostrarBody(false);
        setConsultaIndividual(event.target.checked);
    }

    const handleNomeAniversarianteChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setNomeAniversariante(event.target.value);
    }

    const handleKeyDown = (event: { key: string; }) => {
        const inputField = document.getElementById("consultaIndividual");

        if (event.key === 'Enter' && inputField && inputField === document.activeElement) {
            handlePesquisar();
        }
    }

    const conteudoHTML = `
    <html>
        <head>
            <title>Resultado da Pesquisa de Aniversariantes</title>
            <style>
            body {
                font-family: Arial, sans-serif;
            }
            h1 {
                color: #333;
                text-align: center;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;
            }
            th {
                background-color: #f2f2f2;
            }
            tr:nth-child(even) {
                background-color: #f9f9f9;
            }
            </style>
        </head>
        <body>
            <h1>Resultado da Pesquisa de Aniversariantes</h1>
            <table>
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </body>
    </html>
`;
    const imprimirResultados = () => {
        if (!mostrarBody) {
            setMsgAlert('Não há resultados para imprimir.');
            setModalOpen(true);
            return;
        }
        const janelaImpressao = window.open('', '_blank');

        if (janelaImpressao) {
            janelaImpressao.document.write('<html><head><title>Lista de Aniversariantes</title></head><body>');
            janelaImpressao.document.write('<h1>Lista de Aniversariantes</h1>');
            janelaImpressao.document.write(conteudoHTML);

            janelaImpressao.document.write('<table>');
            janelaImpressao.document.write('<thead>');
            janelaImpressao.document.write('<tr>');
            janelaImpressao.document.write('<th>Nome</th>');
            janelaImpressao.document.write('<th>Data de Nascimento</th>');
            janelaImpressao.document.write('<th>E-mail</th>');
            janelaImpressao.document.write('</tr>');
            janelaImpressao.document.write('</thead>');
            janelaImpressao.document.write('<tbody>');

            renderizarItensDaPagina().forEach(item => {
                if (item) {
                    const itemHTML = ReactDOMServer.renderToString(item);
                    janelaImpressao.document.write(itemHTML);
                }
            });

            janelaImpressao.document.write('</tbody>');
            janelaImpressao.document.write('</table>');

            janelaImpressao.document.write('</body></html>');
            janelaImpressao.document.close();
            janelaImpressao.print();
        } else {
            setMsgAlert('Erro ao abrir a janela de impressão.');
            setModalOpen(true);
        }
    };
    const handlePesquisar = () => {
        if (!tipoCliente && !mostrarBody && !consultaIndividual) {
            setMsgAlert("Selecione o tipo de cliente");
            setModalOpen(true);
            return [];
        }
        if (!mesAniversario && !mostrarBody && !consultaIndividual) {
            setMsgAlert("Selecione o mês de aniversário");
            setModalOpen(true);
            return [];
        }
        if (consultaIndividual && nomeAniversariante.trim() === '') {
            setMsgAlert('Por favor, insira um nome para pesquisar');
            setModalOpen(true);
            return [];
        }
        if (consultaIndividual) {
            fetchDataConsultaIndividual(nomeAniversariante);
        }
        else if (tipoCliente === 'Síndicos') {
            fetchDataImobiliar()
        } else {
            fetchDataAgenciaV1(parseInt(mesAniversario), tipoCliente);
        }
        setPesquisarClientes(true);
        setClientes([]);
        setPaginaAtual(1);
        setMostrarBody(true);
    }


    const gerarCSV = () => {
        let csvContent = "Nome,Data de Nascimento,E-mail\n";
        clientes.forEach(cliente => {
            csvContent += `${cliente.nome_pessoa},${cliente.aniversario},${cliente.email}\n`;
        });
        return csvContent;
    };

    const downloadResultados = () => {
        if (!mostrarBody) {
            setMsgAlert('Não há dados para baixar.');
            setModalOpen(true);
            return;
        }

        const conteudoCSV = gerarCSV();
        const blob = new Blob([conteudoCSV], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'dados_aniversariantes.csv');
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    return (
        <>
            <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
            <h1 id="tableLabel" style={{ fontSize: "1rem", fontWeight: "500" }}>
                Lista de Aniversariantes
            </h1>
            <Divider variant="middle" />
            <div className="panel-heading">
                <p>Filtrar Aniversariantes</p>
            </div>
            <div className="panel-body">
                <div className="label-cliente-container">
                    <label className="tipo-cliente">
                        {!consultaIndividual && (<>
                            Tipo de Cliente:
                            <select value={tipoCliente} onChange={handleTipoClienteChange}>
                                <option value="">Selecione</option>
                                <option value="Síndicos">Síndicos - Imobiliar</option>
                                <option value="utc.tipprop">Proprietário </option>
                                <option value="utc.tiplocat">Inquilino</option>
                                <option value="utc.tipcond">Condômino</option>
                                <option value="utc.tipsind">Síndicos - Agência Virtual</option>
                            </select>
                        </>
                        )}
                        <CSSTransition in={consultaIndividual} classNames={"fade"} timeout={300}>
                            <div className="nome-aniversariante-container">
                                {consultaIndividual && (
                                    <>
                                        Nome do Aniversariante
                                        <input id="consultaIndividual" type="text" value={nomeAniversariante} onChange={handleNomeAniversarianteChange} onKeyDown={handleKeyDown} />
                                    </>
                                )}
                            </div>
                        </CSSTransition>
                        <div className="consulta-individual">
                            <input type="checkbox" checked={consultaIndividual} onChange={handleConsultaIndividualChange} /> Consulta Individual
                        </div>
                    </label>
                    {!consultaIndividual && (
                        <div className="label-mes-container">
                            <label className="mes-aniversario">
                                Mês do Aniversário:
                                <select value={mesAniversario} onChange={handleMesAniversarioChange}>
                                    <option value="">Selecione</option>
                                    <option value="1">Janeiro</option>
                                    <option value="2">Fevereiro</option>
                                    <option value="3">Março</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Maio</option>
                                    <option value="6">Junho</option>
                                    <option value="7">Julho</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Setembro</option>
                                    <option value="10">Outubro</option>
                                    <option value="11">Novembro</option>
                                    <option value="12">Dezembro</option>
                                </select>
                            </label>
                        </div>
                    )}
                    <div className="botoes">
                        <>
                            <Button sx={{ "fontSize": "12px", "textTransform": "capitalize", "backgroundColor": "#FF0000", "color": "#fff", ":hover": { "backgroundColor": "#AD0000" } }} variant="contained" endIcon={<DeleteIcon />} onClick={refazerPesquisa}>
                                Refazer Pesquisa
                            </Button>
                            <Button sx={{ "fontSize": "12px", "textTransform": "capitalize", "marginLeft": "3px", "backgroundColor": "#00A651", "color": "#fff", ":hover": { "backgroundColor": "#007D3D" } }} onClick={imprimirResultados} endIcon={<PrintIcon />}>
                                Imprimir
                            </Button>
                            <Button sx={{ "fontSize": "12px", "marginLeft": "3px", "textTransform": "capitalize", "backgroundColor": "#00A651", "color": "#fff", ":hover": { "backgroundColor": "#007D3D" } }} onClick={downloadResultados} endIcon={<DownloadIcon />}>
                                Baixar dados
                            </Button>
                            <Button sx={{ "fontSize": "12px", "marginLeft": "3px", "textTransform": "capitalize", "backgroundColor": "#3B5998", "color": "#fff", ":hover": { "backgroundColor": "#30487B" } }} onClick={handlePesquisar} endIcon={<SearchIcon />}>
                                Pesquisar
                            </Button>
                        </>
                    </div>
                </div>
            </div>
            {mostrarBody && (<>
                {loading ? (
                    <Loading descricao="Buscando as informações" />
                ) : (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Data de Nascimento</th>
                                    <th>E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderizarItensDaPagina()}
                            </tbody>
                        </table>
                        <Pagination
                            paginaAtual={paginaAtual}
                            totalPaginas={totalPaginas}
                            PaginaAnterior={handlePaginaAnterior}
                            ProximaPagina={handleProximaPagina}
                            IrParaPagina={handleIrParaPagina}
                            IrParaPrimeiraPagina={handlePrimeiraPagina}
                            IrParaUltimaPagina={handleUltimaPagina}
                        />
                        <Typography>Total de Itens: {totalClientes}</Typography>
                    </div>)}
                <Divider variant="middle" />
            </>
            )}
            <Footer />
        </>
    );
}


export default ListaAniversariantes;