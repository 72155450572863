import React, { createContext, useContext, ReactNode } from "react";
import { IVPNLiberacao, IVPNLiberacaoFilter } from '../interfaces/IVPNLiberacao';
import { getLiberacoesVPN, bloqueioLiberacaoVPN, salvarLiberacaoVPN } from "../services/DP/DPApi";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";

interface DPProviderProps {
    children: ReactNode;
}

interface DPContextData {
    getLiberacoesVPNFiltroAsync: (params: IVPNLiberacaoFilter) => Promise<IPaginatedResult<IVPNLiberacao>>;
    bloqueioLiberacaoVPNAsync: (id: number) => Promise<boolean>;
    salvarLiberacaoVPNAsync: (body: IVPNLiberacao) => Promise<boolean>;
}

const dpContext = createContext<DPContextData | undefined>(undefined);

export const DPProvider: React.FC<DPProviderProps> = ({ children }) => {
    const getLiberacoesVPNFiltroAsync = async (params: IVPNLiberacaoFilter): Promise<IPaginatedResult<IVPNLiberacao>> => {
        return await getLiberacoesVPN(params);
    };

    const bloqueioLiberacaoVPNAsync = async (id: number): Promise<boolean> => {
        return await bloqueioLiberacaoVPN(id);
    };

    const salvarLiberacaoVPNAsync = async (body: IVPNLiberacao): Promise<boolean> => {
        return await salvarLiberacaoVPN(body);
    };

    return (
        <dpContext.Provider value={{
            getLiberacoesVPNFiltroAsync,
            bloqueioLiberacaoVPNAsync,
            salvarLiberacaoVPNAsync
        }}>
            {children}
        </dpContext.Provider>
    );
};

export const useDP = (): DPContextData => {
    const context = useContext(dpContext);
    if (!context) {
        throw new Error("useDP must be used within a DPProvider");
    }
    return context;
};
