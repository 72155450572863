import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import { ICadastroUsuario } from '../../interfaces/ICadastroUsuario';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../contexts/auth";
import Pagination from '../../components/common/Pagination';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import { useUtilidadesTI } from '../../contexts/utilidadesTIProvider';

const UsuarioPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [users, setUsers] = useState<ICadastroUsuario[]>([]);
    const navigate = useNavigate(); 
    const { buscaUsuarioAsync } = useUtilidadesTI();
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);

    useEffect(() => {
        busca(paginaAtual);
    }, [paginaAtual, itensPagina]);

    const busca = async (paginaAtual: number) => {
        var body = {
            id: id ? Number(id) : undefined,
            username: username || undefined,
            email: email || undefined,
            PageNumber : paginaAtual
        };

        var data = await buscaUsuarioAsync(body);

        setTotalPaginas(data.TotalPaginas);
        setTotalRegistros(data.TotalItens);
        setUsers(data.Itens as ICadastroUsuario[]);
    };

    const handleSearch = async () => {
        busca(1);
    };

    const handleNewUser = () => {
        navigate('/ti/usuarios/cadastrar');
    };

    const handleEditUser = (user: ICadastroUsuario) => {
        navigate('/ti/usuarios/cadastrar', { state: { currentUser: user } });
    };

    function StatusIcon(st: boolean) {
        if (st) {
            return <Tooltip title={'Ativo'}><CheckCircleIcon color="success"></CheckCircleIcon></Tooltip>
        } else {
            return <Tooltip title={'Inativo'}><CheckCircleIcon color="error"></CheckCircleIcon></Tooltip>
        }
    }

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Pesquisa de Usuários
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="ID"
                        type="number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={id}
                        onChange={e => setId(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                
            </Grid>
            <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginTop: 2 }}>
                Pesquisar
            </Button>
            <Button variant="contained" color="secondary" onClick={handleNewUser} sx={{ marginTop: 2, marginLeft: 2 }}>
                Novo Usuário
            </Button>
            {users.length > 0 && (
                <TableContainer component={Paper} sx={{ marginTop: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Ação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{StatusIcon(user.ativo)}</TableCell>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleEditUser(user)}>
                                            Editar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        paginaAtual={paginaAtual}
                        totalPaginas={totalPaginas}
                        PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                        ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                        IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                        IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                        IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                    />
                    <Typography>Total de Itens: {totalRegistros}</Typography>  
                </TableContainer>
            )}
        </Box>
    );

};

export default UsuarioPage;