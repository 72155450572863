import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { IBetalabsRelatorio } from "../../../interfaces/ISeguros";
import "../../../styles/styles.css";
import { useSeguros } from "../../../contexts/segurosProvider";

function RelatorioEmailsBetalabs() {
    const { buscaRelatoriosEmailsBetalabsAsync, exportaRelatorioEmailBetalabsAsync } = useSeguros();
    const [ficha, setFicha] = useState<IBetalabsRelatorio[]>([]);
    
    return (
        <>
            <Typography variant="h6">Relatório de Emails Betalabs</Typography>
            <Relatorio
                busca={buscaRelatoriosEmailsBetalabsAsync}
                exporta={exportaRelatorioEmailBetalabsAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailBetalabs"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}
export default RelatorioEmailsBetalabs;