import React, { useState, useContext} from "react";
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Loading from '../../components/common/Loading';
import { useUtilidadesTI } from "../../contexts/utilidadesTIProvider";




function AlterarSenhaPage() {

    const [senhaAtual, setSenhaAtual] = useState ("");
    const [novaSenha, setNovaSenha] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");
    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const { alteraSenhaAsync } = useUtilidadesTI();
 


    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        
       
        try{
            if (novaSenha === confirmaSenha){
                if (novaSenha.length >= 8){
                    const id = getIdFromLocalStorage();
                    let retorno = await alteraSenhaAsync(id, senhaAtual, novaSenha);
                    console.log("retorno", { retorno });
                    setMsgAlert(" Senha alterada com sucesso!");
                } else{
                    setSeverity("error");
                    setMsgAlert("A senha deve ter no mínimo de 8 caracteres.");
                    setLoading(false);
                    setOpen(true);
                    return;
                }
            } else{
                setSeverity("error");
                setMsgAlert("A senha de confirmação está incorreta.");
                setLoading(false);
                setOpen(true);
                return;
            }

            setSenhaAtual("");
            setNovaSenha("");
            setConfirmaSenha("");

            setLoading(false);
            setOpen(true);
            
            
        } catch (error){
            console.error("Ocorreu um erro ao alterar a senha: ", error);
            setSeverity("error");
            setMsgAlert("Ocorreu um erro ao alterar a senha.");
            setLoading(false);
            setOpen(true);
        }
    };

    const getIdFromLocalStorage = () => {
        const user = localStorage.getItem('user');
        if (user) {
          const userData = JSON.parse(user);
          return userData.id;
        } else {
          return null;
        }
      };
     
      const contents = loading
        ? <Loading /> : "";

    return (  
            <div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                        {msgAlert}
                    </Alert>
                </Snackbar>

                <h1 id="tableLabel">Alterar senha da sua conta:</h1>

                <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
                >
                    <TextField
                        size='small'
                        required
                        id="outlined-required"
                        label="Senha Atual"
                        type="password"
                        value={senhaAtual}
                        onChange={(e) => setSenhaAtual(e.target.value)}
                    />

                    <div>
                        <TextField
                        size='small'
                        required
                        id="outlined-required"
                        label="Nova Senha"
                        type="password"
                        autoComplete="new-password"
                        value={novaSenha}
                        onChange={(e) => setNovaSenha(e.target.value)}
                        />
                    </div>

                    <div>
                        <TextField
                        size='small'
                        required
                        id="outlined-required"
                        label="Confirmar nova Senha"
                        type="password"
                        value={confirmaSenha}
                        onChange={(e) => setConfirmaSenha(e.target.value)}
                        />
                    </div>
                    
                
                    <Button
                        sx={{ "marginLeft": "1%" }} variant="contained" endIcon={<SendIcon />} onClick={handleSubmit}
                    >
                        Alterar Senha
                    </Button>
                </Box>

                {contents}

            </div>
        );
    }

export default AlterarSenhaPage;