import { useEffect, useState } from "react";
import { ICondominio } from "../../../interfaces/ICondominio";
import { Button, Checkbox, Typography, Container, Paper, Stack, Box } from "@mui/material";
import { Select } from "antd";
import Loading from "../../../components/common/Loading";
import { useCondominio } from "../../../contexts/CondominioProvider";

const { Option } = Select;

function BloqueioInadimplentes() {
  const { pegaCondominiosAsync, postBloqueioInadimplentesAsync } = useCondominio();
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] = useState<ICondominio | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCondominios = async () => {
      try {
        const condominiosData = await pegaCondominiosAsync();
        setCondominios(condominiosData);
        if (condominiosData.length > 0) {
          setSelectedCondominio(condominiosData[0]);
        }
      } catch (error) {
        console.error("Error fetching condominios:", error);
      }
    };

    setEditMode(false);
    fetchCondominios();
  }, [pegaCondominiosAsync]);

  const handleDropdownChange = (value: string) => {
    const cond = condominios.find((x) => String(x.Id) === value);
    setSelectedCondominio(cond || null);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    if (!selectedCondominio) {
      setEditMode(false);
      setLoading(false);
      return;
    }

    const result = await postBloqueioInadimplentesAsync({
      Id_Condominio: selectedCondominio.Id,
      Bloqueado: selectedCondominio.Bloqueado,
    });

    if (result.status === 200 && selectedCondominio) {
      setCondominios((prevCondominios) =>
        prevCondominios.map((condominio) =>
          condominio.Id === selectedCondominio.Id
            ? { ...condominio, Bloqueado: selectedCondominio.Bloqueado }
            : condominio
        )
      );
    }

    setEditMode(false);
    setLoading(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedCondominio) {
      setSelectedCondominio({ ...selectedCondominio, Bloqueado: event.target.checked });
    }
  };

  const handleCnpj = (cnpj: string) =>
    cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: "center", mt: 4, mb: 3 }}>
        <Typography variant="h4" component="h1">Condomínios</Typography>
      </Box>

      {/* ✅ Select melhor posicionado e dimensionado */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <Select
          showSearch
          style={{ width: 300 }}
          placeholder="Selecione um condomínio"
          value={selectedCondominio ? String(selectedCondominio.Id) : undefined}
          onChange={handleDropdownChange}
          filterOption={(input, option) =>
            String(option?.children || "").toLowerCase().includes(input.toLowerCase())
          }
          optionLabelProp="label"
        >
          {condominios.map((condominio) => (
            <Option key={condominio.Id} value={String(condominio.Id)} label={condominio.Nome}>
              {condominio.Nome} ({condominio.Codcondom})
            </Option>
          ))}
        </Select>
      </Box>

      {selectedCondominio && (
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: "#f9f9f9" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <b>Condomínio:</b> {selectedCondominio.Nome}
          </Typography>
          <Typography variant="body1"><b>CNPJ:</b> {selectedCondominio.Cnpj && handleCnpj(selectedCondominio.Cnpj)}</Typography>
          <Typography variant="body1"><b>Código:</b> {selectedCondominio.Codcondom}</Typography>

          {/* Bloqueado com checkbox alinhado */}
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
            <Typography variant="body1"><b>Bloqueado:</b></Typography>
            <Checkbox
              checked={selectedCondominio.Bloqueado}
              onChange={handleCheckboxChange}
              disabled={!editMode}
            />
          </Stack>

          {/* ✅ Botões melhor alinhados */}
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
            {editMode ? (
              <>
                <Button variant="contained" size="small" onClick={handleSaveClick}>
                  Salvar
                </Button>
                <Button variant="contained" size="small" onClick={() => setEditMode(false)}>
                  Cancelar
                </Button>
              </>
            ) : (
              <Button variant="contained" size="small" onClick={() => setEditMode(true)}>
                Editar
              </Button>
            )}
          </Stack>

          {loading && (
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Loading />
            </Box>
          )}
        </Paper>
      )}
    </Container>
  );
}

export default BloqueioInadimplentes;
