import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, FormControl, FormHelperText, MenuItem, Select, Snackbar, Step, Stepper, StepContent, StepLabel, TextField } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Loading from "../../../components/common/Loading";
import { IBairro, IImovel, ITipo } from "./ICalculadoraAlugueis";
import { useAlugueis } from "../../../contexts/alugueisProvider";

const defaultImovelValue: IImovel = {
    bairroData: null,
    tipoData: null,
    bairro: "",
    tipo: "",
    andar: 0,
    areaTotal: "0",
    qtdBanheiros: 0,
    qtdElevadores: 0,
    qtdQuartos: 0,
    qtdSalas: 0,
    qtdSuites: 0,
    qtdVagas: 0,
    valorCondominio: "R$ 0,00",
    valorIptu: "R$ 0,00",
    churrasqueira_imo: false,
    sacada: false,
    aquecimentoGas: false,
    arCondicionado: false,
    areaServico: false,
    armarioBanheiro: false,
    armarioCozinha: false,
    armarioQuarto: false,
    boxBanheiro: false,
    closet: false,
    despensa: false,
    hidromassagem: false,
    lavabo: false,
    mobiliado: false,
    solManha: false,
    aguaIndividual: false,
    gasCanalizado: false,
    portaria: false,
    academia: false,
    churrasqueiraCond: false,
    piscina: false,
    playground: false,
    salaoFestas: false,
    salaoJogos: false,
};

function CalculadoraAlugueisPage() {
    const { pegaBairrosAsync, pegaTiposAsync, calculaAluguelAsync } = useAlugueis();

    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [tipoError, setTipoError] = useState(true);
    const [bairroError, setBairroError] = useState(true)
    const [resultadoCalculo, setResultadoCalculo] = useState<string | null>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [bairros, setBairros] = useState<IBairro[]>([]);
    const [tipos, setTipos] = useState<ITipo[]>([]);
    const [imovel, setImovel] = useState<IImovel>(defaultImovelValue);

    const updateImovel = <K extends keyof IImovel>(field: K, value: IImovel[K]) => {
        setImovel((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    useEffect(() => {
        const buscaBairros = async () => {
            try {
                const response = await pegaBairrosAsync();
                setBairros(response);
            } catch (error) {
                console.error("Erro ao buscar bairros", error);
            }
        };

        buscaBairros();
    }, [pegaBairrosAsync]);

    useEffect(() => {
        const buscaTipos = async () => {
            try {
                const response = await pegaTiposAsync();
                setTipos(response);
            } catch (error) {
                console.error("Erro ao buscar os tipos", error);
            }
        };

        buscaTipos();
    }, [pegaTiposAsync]);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Cálculo realizado com sucesso!");

        if (imovel.tipo === null || imovel.bairro === null) {
            setSeverity("error");
            setMsgAlert("Os campos Tipo e Bairro são obrigatórios.");
            setLoading(false);
            setOpen(true);
            return;
        }
        else {
            try {
                const auxValorCondoFormatado = imovel.valorCondominio.split("R$");
                const valorCondominioAux = auxValorCondoFormatado[1].trim().replace(",", ".");
                const valorCondominioConvertido = parseFloat(valorCondominioAux);

                const auxValorIptuFormatado = imovel.valorIptu.split("R$");
                const valorIptuAux = auxValorIptuFormatado[1].trim().replace(",", ".");
                const valorIptuConvertido = parseFloat(valorIptuAux);

                imovel.valorCondominio = valorCondominioConvertido.toString();
                imovel.valorIptu = valorIptuConvertido.toString();
                imovel.bairro = imovel.bairroData?.bairroNomes;
                imovel.tipo = imovel.tipoData?.tipoDescricao;

                let retorno = await calculaAluguelAsync(imovel);
                
                let valorAjustado: number;
                const valorCalculado = retorno.aluguel;

                const dezena = valorCalculado % 100;

                if (dezena >= 0 && dezena <= 50) {
                    valorAjustado = Math.floor(valorCalculado / 100) * 100 - 10;
                } else {
                    valorAjustado = Math.floor(valorCalculado / 100) * 100 + 90;
                }

                const valorFormatado = valorAjustado.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });

                setResultadoCalculo(valorFormatado);

                setMsgAlert("Cálculo bem-sucedido!");
                setLoading(false);
                setOpen(true);

            } catch (error: any) {
                if (error.response && error.response.status === 400) {
                    setSeverity("error");
                    setMsgAlert((error.response.data as any).mensagem);
                    setLoading(false);
                    setOpen(true);
                    return;
                }
                console.error("Ocorreu um erro ao calcular", error);
                setSeverity("error");
                setMsgAlert("Ocorreu um erro ao calcular.");
                setLoading(false);
                setOpen(true);
            }
        }
    };

    const resultadoCalculoSectionRef = useRef<HTMLDivElement>(null);

    const scrollIntoView = () => {
        if (resultadoCalculoSectionRef.current) {
            resultadoCalculoSectionRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        scrollIntoView();
    }, [resultadoCalculo]);

    const formatarInput = (valor: string) => {
        const valorNumerico = parseFloat(valor.replace(/[^\d]/g, ''));
        if (isNaN(valorNumerico)) {
            return '';
        }

        const reais = Math.floor(valorNumerico / 100);
        const centavos = valorNumerico % 100;

        const valorFormatado = `${reais.toLocaleString('pt-BR')},${centavos
            .toString()
            .padStart(2, '0')}`;

        return `R$ ${valorFormatado}`;
    };

    const handleChange = (value: any, kayValue: keyof IImovel) => {
        const novoValorLimpo = value.replace(/[^\d]/g, '');
        if (novoValorLimpo.length >= 1) {
            const valorFormatado = formatarInput(novoValorLimpo);

            updateImovel(kayValue, valorFormatado);
        } else {
            updateImovel(kayValue, "");
        }
    };

    const contents = loading ? <Loading /> : "";

    const getStepLabel = (index: number) => {
        switch (index) {
            case 0:
                return "Características do Imóvel - Informações";
            case 1:
                return "Características do Imóvel - Opções";
            case 2:
                return "Características do Condomínio";
            default:
                return "";
        }
    };


    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        {/* Seção: Tipo e Bairro */}
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                            {/* Tipo */}
                            <FormControl
                                sx={{ width: "100%", flexDirection: "row", justifyContent: "space-between" }}
                                required
                                error={tipoError}
                            >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h4>Tipo:</h4>
                                    {tipoError && <FormHelperText style={{ color: "red", margin: 0 }}>*</FormHelperText>}
                                </div>
                                <Select
                                    variant="filled"
                                    name="tipo"
                                    value={imovel.tipoData ? imovel.tipoData.tipoId.toString() : ""}
                                    onChange={(e) => {
                                        const selectedTipoId = Number(e.target.value);
                                        const selectedTipo = tipos.find((t) => t.tipoId === selectedTipoId);
                                        updateImovel("tipoData", selectedTipo || null);
                                        setTipoError(selectedTipo === null);
                                    }}
                                >
                                    {tipos.map((tipo) => (
                                        <MenuItem key={tipo.tipoId} value={tipo.tipoId}>
                                            {tipo.tipoDescricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Bairro */}
                            <FormControl
                                sx={{ width: "100%", flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px" }}
                                required
                                error={bairroError}
                            >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h4>Bairro:</h4>
                                    {bairroError && <FormHelperText style={{ color: "red", margin: 0 }}>*</FormHelperText>}
                                </div>
                                <Select
                                    variant="filled"
                                    name="bairro"
                                    value={imovel.bairroData ? imovel.bairroData.bairroId.toString() : ""}
                                    onChange={(e) => {
                                        const selectedBairroId = Number(e.target.value);
                                        const selectedBairro = bairros.find((bairro) => bairro.bairroId === selectedBairroId);
                                        updateImovel("bairroData", selectedBairro || null);
                                        setBairroError(selectedBairro === null);
                                    }}
                                >
                                    {bairros.map((bairro) => (
                                        <MenuItem key={bairro.bairroId} value={bairro.bairroId}>
                                            {bairro.bairroNomes}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Seção: Andar e Área */}
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                            {/* Andar */}
                            <FormControl sx={{ width: "100%", marginTop: "8px", flexDirection: "row", justifyContent: "space-between" }}>
                                <h4>Andar:</h4>
                                <Select
                                    variant="filled"
                                    type="number"
                                    value={imovel.andar}
                                    onChange={(e) => updateImovel("andar", Number(e.target.value))}
                                >
                                    {Array.from({ length: 21 }, (_, index) => (
                                        <MenuItem key={index} value={index}>{index}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Área */}
                            <FormControl sx={{ width: "100%", marginTop: "8px", flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px" }}>
                                <h4>Área interna em m²:</h4>
                                <TextField
                                    variant="filled"
                                    type="number"
                                    value={imovel.areaTotal}
                                    onChange={(e) => updateImovel("areaTotal", e.target.value)}
                                />
                            </FormControl>
                        </div>

                        {[
                            { label: "Número de banheiros", field: "qtdBanheiros" },
                            { label: "Número de elevadores", field: "qtdElevadores" },
                            { label: "Número de quartos", field: "qtdQuartos" },
                            { label: "Número de salas", field: "qtdSalas" },
                            { label: "Número de suítes", field: "qtdSuites" },
                            { label: "Número de vagas", field: "qtdVagas" }
                        ].map(({ label, field }, index) => (
                            <div key={field} style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                                <FormControl sx={{ width: "100%", marginTop: "8px", flexDirection: "row", justifyContent: "space-between" }}>
                                    <h4>{label}:</h4>
                                    <Select
                                        variant="filled"
                                        type="number"
                                        value={imovel[field as keyof IImovel]}
                                        onChange={(e) => updateImovel(field as keyof IImovel, Number(e.target.value))}
                                    >
                                        {Array.from({ length: 11 }, (_, index) => (
                                            <MenuItem key={index} value={index}>{index}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        ))}

                        {/* Seção: Valores */}
                        {[
                            { label: "Valor do IPTU (mensal)", field: "valorIptu" },
                            { label: "Valor do condomínio", field: "valorCondominio" }
                        ].map(({ label, field }, index) => (
                            <div key={field} style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                                <FormControl sx={{ width: "100%", marginTop: "8px", flexDirection: "row", justifyContent: "space-between" }}>
                                    <h4>{label}:</h4>
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        value={imovel[field as keyof IImovel]}
                                        onChange={(e) => handleChange(e.target.value, field as keyof IImovel)}
                                    />
                                </FormControl>
                            </div>
                        ))}
                    </Box>
                );

            case 1:
            case 2:
                const caracteristicas = step === 1
                    ? [
                        { label: "Churrasqueira no imóvel", field: "churrasqueira_imo" },
                        { label: "Sacada", field: "sacada" },
                        { label: "Aquecedor a gás", field: "aquecimentoGas" },
                        { label: "Ar condicionado", field: "arCondicionado" },
                        { label: "Área de serviço fechada", field: "areaServico" },
                        { label: "Armário no banheiro", field: "armarioBanheiro" },
                        { label: "Armário na cozinha", field: "armarioCozinha" },
                        { label: "Armário no quarto", field: "armarioQuarto" },
                        { label: "Box no banheiro", field: "boxBanheiro" },
                        { label: "Closet", field: "closet" },
                        { label: "Despensa", field: "despensa" },
                        { label: "Hidromassagem", field: "hidromassagem" },
                        { label: "Lavabo", field: "lavabo" },
                        { label: "Mobiliado", field: "mobiliado" },
                        { label: "Sol da manhã", field: "solManha" }
                    ]
                    : [
                        { label: "Água individual", field: "aguaIndividual" },
                        { label: "Gás central", field: "gasCanalizado" },
                        { label: "Portaria 24h", field: "portaria" },
                        { label: "Academia", field: "academia" },
                        { label: "Churrasqueira no condomínio", field: "churrasqueiraCond" },
                        { label: "Piscina", field: "piscina" },
                        { label: "Playground", field: "playground" },
                        { label: "Salão de festas", field: "salaoFestas" },
                        { label: "Salão de jogos", field: "salaoJogos" }
                    ];

                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        {caracteristicas.map(({ label, field }) => (
                            <div key={field} style={{ display: "flex", flexDirection: "row", width: "85%", justifyContent: "space-between" }}>
                                <h4>{label}:</h4>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={imovel[field as keyof IImovel] as boolean} onChange={() => updateImovel(field as keyof IImovel, true)} />}
                                        label="Sim"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={!imovel[field as keyof IImovel] as boolean} onChange={() => updateImovel(field as keyof IImovel, false)} />}
                                        label="Não"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                );

            default:
                return null;
        }
    };


    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: "100%" }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            <h1 id="tableLabel" style={{ fontSize: "1rem", fontWeight: "500" }}>
                Calculadora Aluguéis
            </h1>

            <Divider variant="middle" />

            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root, & .MuiInputBase-root": { my: 1, width: "20ch", height: "6ch" },
                    "& h4": {
                        fontSize: "14px",
                        fontWeight: "bold",
                    },
                    maxWidth: "50%",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
                noValidate
                autoComplete="off"
            >
                <Stepper activeStep={activeStep} orientation="vertical">
                    {[0, 1, 2].map((index) => (
                        <Step key={index}>
                            <StepLabel>{getStepLabel(index)}</StepLabel>
                            <StepContent>
                                {getStepContent(index)}
                                <div style={{ marginTop: "20px" }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        onClick={activeStep === 2 ? handleSubmit : handleNext}
                                        variant="contained"
                                    >
                                        {activeStep === 2 ? "Calcular" : "Próximo"}
                                    </Button>
                                    {activeStep === 2 && (
                                        <Button
                                            sx={{
                                                marginLeft: "50px",
                                            }}
                                            onClick={() => setImovel(defaultImovelValue)}
                                            variant="contained"
                                        >
                                            Limpar
                                        </Button>
                                    )}

                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Box ref={resultadoCalculoSectionRef}>
                    {resultadoCalculo !== null && (
                        <div style={{ textAlign: "left", paddingTop: "80px", fontSize: "24px", fontWeight: "bolder" }}>
                            O valor estimado do aluguel é: {resultadoCalculo}
                        </div>
                    )}
                </Box>
            </Box>

            {contents}
        </div>
    );
}

export default CalculadoraAlugueisPage;