import axios from "axios";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { ILeadsImoveis, IntegradorImoveis } from "../../interfaces/ISite";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function buscarListaIntegradorSite(params: IPaginationFilter): Promise<IPaginatedResult<IntegradorImoveis>> {
    return (await api.get("api/Site/Integrador/Listar", { params })).data;
};

export async function exportarListaIntegradorSite(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/Site/Integrador/Gerar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarListaLeadsSite(params: IPaginationFilter): Promise<IPaginatedResult<ILeadsImoveis>> {    
    return (await api.get("api/Site/Leads/Listar", { params })).data;
};

export async function exportarListaLeadsSite(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/Site/Leads/Gerar", {
        params,
        responseType: 'blob'
    })).data;
}