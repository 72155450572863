import React from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css';
import { IErrosOnboarding } from '../interfaces/IErrosOnboarding';
interface TableDataProps {
  ficha: IErrosOnboarding[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}
const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {

  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };
  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";


  return (
    <div className="responsive-table-container">
      {loading ? <Loading /> : null}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CodPessoa</TableCell>
              <TableCell>CodImovel</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Dias para Envio</TableCell>
              <TableCell>Motivo</TableCell>
              <TableCell>Nome do Gestor</TableCell>
              <TableCell>Email do Gestor</TableCell>
              <TableCell>Data de Envio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ficha.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.CodPessoa}</TableCell>
                <TableCell>{item.CodImovel}</TableCell>
                <TableCell>{item.ClientName}</TableCell>
                <TableCell>{item.ClientEmail}</TableCell>
                <TableCell>{item.DiasParaEnvio}</TableCell>
                <TableCell>{item.Motivo}</TableCell>
                <TableCell>{item.ManagerName}</TableCell>
                <TableCell>{item.ManagerEmail}</TableCell>
                <TableCell>{handleDateTime(item.CreatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TableData;