import React, { useEffect, useState } from 'react';
import { AlertProps, Divider, SelectChangeEvent, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import BuscarClientesTable from '../../pages/condominio/BuscarClientes/BuscarClientesTable'
import Pagination from './Pagination';
import BuscaClientesForm from './BuscaClientesForm';
import { downloadBlob } from '../../utils/helpers';
import { IPaginatedResult, IPaginationFilter } from '../../services/IService';
import { BuscaClientes } from '../../interfaces/IBuscaClientes';

interface BuscaClientesProps {
    busca: (params: IPaginationFilter) => Promise<IPaginatedResult<BuscaClientes>>;
    exporta: (params: IPaginationFilter) => Promise<any>;
    atualiza: (data: IAtualizaInadimplente) => Promise<any>;
    ficha: any;
    setFicha(ficha: any): void;
}

export interface IAtualizaInadimplente {
    Id: number;
    Ativo: boolean;
    Inadimplente: boolean;
    Codigo_pessoa: any; // Substitua 'any' pelo tipo correto se conhecido
    UserId: any; // Substitua 'any' pelo tipo correto se conhecido
}


const BuscarClientes: React.FC<BuscaClientesProps> = ({
    busca,
    exporta,
    atualiza,
    ficha,
    setFicha,
}) => {
    const [severity, setSeverity] = useState('success');
    const [msgAlert, setMsgAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);

    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (ficha.length !== 0) {
            buscar(formData, paginaAtual, itensPagina);
        }
    }, [paginaAtual, itensPagina]);

    const buscar = async (filtros: any, paginaAtual: number, itensPagina: number, msg = false) => {
        try {
            setLoading(true);

            const parameters: IPaginationFilter = {
                PageNumber: paginaAtual,
                PageSize: itensPagina,
                ...filtros,
            }

            const retorno = await busca(parameters) as IPaginatedResult<any>;
            console.log('retorno ', retorno);

            setFicha(retorno.Itens);
            setTotalPaginas(retorno.TotalPaginas);
            setTotalRegistros(retorno.TotalItens);

            if (msg === true) {
                setSeverity("success");
                setMsgAlert(retorno['TotalItens'] + " usuários encontrados.")
                setOpen(true);
            }
        } catch (err: any) {
            console.log(err.response.data);
            setSeverity("error");
            setMsgAlert(err.response.data);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateExcel = async (event: React.FormEvent) => {
        try {
            setLoading(true);
            const parameters: IPaginationFilter = {
                PageNumber: paginaAtual,
                PageSize: itensPagina,
                ...formData,
            }
            const response = await exporta(parameters);
            
            const blob = new Blob([response]);

            downloadBlob(blob, "Relatorio_Clientes.xlsx");
        } catch (error: any) {
            console.log(error.response.data);
            setSeverity("error");
            setMsgAlert("0 registros encontrados.");
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setPaginaAtual(1);
        buscar(formData, 1, itensPagina, true);
    };

    return (
        <>
            <Divider sx={{ my: 2 }} />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <BuscaClientesForm
                handleSubmit={handleSubmit}
                handleChangeItensPagina={(event: SelectChangeEvent<number>) => setItensPagina(Number(event.target.value))}
                handleGenerateExcel={handleGenerateExcel}
                itensPagina={itensPagina}
                formData={formData}
                setFormData={setFormData}
            />
            <div>
                <Divider sx={{ my: 2 }} />
                <div>
                    <BuscarClientesTable ficha={ficha} loading={loading} modalOpen={modalOpen} handleCloseModal={handleCloseModal} atualiza={atualiza} />
                </div>
                <Pagination
                    paginaAtual={paginaAtual}
                    totalPaginas={totalPaginas}
                    PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                    ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                    IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                    IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                    IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                />
                <Typography>Usuários: {totalRegistros}</Typography>
            </div>
        </>
    );
};

export default BuscarClientes;