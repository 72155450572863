import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import { AuthContext } from "./contexts/auth";
import { useContext } from "react";
import LoginPage from "./pages/Login";
import NotFound from "./pages/NotFound";
import RecuperarSenhaPage from "./pages/Login/RecuperarSenhaPage";
import CriadorTemplates from "./pages/TemplatesEmail/CriadorTemplates";
import ListaEmails from "./pages/TemplatesEmail/ListaEmails";
import AppProviders from "./contexts/AppProvider";
import { useLocation } from "react-router-dom";
import Colaboradores from "./pages/colaboradores/Ramais/Ramais"
import DefaultLayout from "./components/layout/DefaultLayout";



function App() {
  const Private = ({ children }: any) => {
    const { authenticated, loading } = useContext(AuthContext);
    const location = useLocation();

    if (loading) {
      return <div className="loading">Carregando...</div>
    }
    if (!authenticated && location.pathname !== "/colaboradores/busca-colaboradores") {
      return <Navigate to="/login" />
    }

    return children;
  }
  return (
    <BrowserRouter>
      <AppProviders>
        <Routes>
          <Route path="*" element={<Private><MainLayout /></Private>}>
            <Route path="*" element={<NotFound />}/>
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/recuperar-senha" element={<RecuperarSenhaPage />} />
          <Route path="/colaboradores/busca-colaboradores" element={<DefaultLayout><Colaboradores /> </DefaultLayout>}/>
          <Route path="/" element={<Private><MainLayout /></Private>}>
            {routes}
          </Route>
        </Routes>
      </AppProviders>
    </BrowserRouter>
  );
}

export default App;
