import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IOnboardingEmailsTipo } from "../interfaces/IOnboarding";
import { useMarketing } from "../../../contexts/marketingProvider";

interface IFilterFormOnboardingProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    formData: any;
}

const FilterFormOnboarding: React.FC<IFilterFormOnboardingProps> = ({ handleChange, handleChangeDate, handleSelectChange, formData }) => {
    const { listaTiposEmailsOnboardingAsync, listaTemplatesEmailsOnboardingAsyc, listaNomeGestoresOnboardingAsync  } = useMarketing();

    const [templatesType, setTemplatesType] = useState<IOnboardingEmailsTipo[]>([]);
    const [managersName, setManagersName] = useState<string[]>([]);
    const [templatesName, setTemplatesName] = useState<string[]>([]);
    const [isEmailOpened, setIsEmailOpened] = useState<boolean>(false);

    useEffect(() => {
        const fetchTemplatesAndManagers = async () => {
            const templates = await listaTemplatesEmailsOnboardingAsyc();
            const managers = await listaNomeGestoresOnboardingAsync();
            const templatesType: IOnboardingEmailsTipo[] = await listaTiposEmailsOnboardingAsync();

            if (templates) {
                setTemplatesName(templates);
            }
            if (managers) {
                setManagersName(managers);
            }

            if (templatesType) {
                setTemplatesType(templatesType);
            }
        };

        fetchTemplatesAndManagers();
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        
        setIsEmailOpened(checked);

        handleChange({
            target: {
                name,
                value: checked.toString(),
            },
        } as React.ChangeEvent<HTMLInputElement>);
    };
    
    const formatTemplateName = (str: string) => {        
        return str
            .replace(/([A-Z])/g, ' $1') // Adiciona espaço antes de cada letra maiúscula
            .trim() // Remove espaços desnecessários
            .replace(/\b\w/g, (char) => char.toUpperCase()) // Coloca a primeira letra em maiúscula
            .replace(/\bProprietario\b/i, 'Proprietário') // Corrige "Proprietarios"
            .replace(/\bLocatario\b/i, 'Locatário'); // Corrige "Locatarios"
    };

    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = (formData.dataInicial && formData.dataFinal);

    const defaultEmail = formData.ClientEmail || "";
    const defaultCodigoImovel = formData.CodImovel || "";
    const defaultCodPessoa = formData.CodPessoa || "";
    const defaultTemplate = formData.TemplateName || "";

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Email"
                                type="text"
                                name="ClientEmail"
                                value={defaultEmail}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Código imóvel"
                                type="text"
                                name="CodImovel"
                                value={defaultCodigoImovel}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="CodPessoa"
                                type="text"
                                name="CodPessoa"
                                value={defaultCodPessoa}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="manager-label">Gestor</InputLabel>
                            <Select
                                labelId="manager-label"
                                label="Gestor"
                                name="ManagerName"
                                value={formData["ManagerName"]}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {managersName.map((manager) => (
                                    <MenuItem key={manager} value={manager}>
                                        {manager}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="template-type-label">Template</InputLabel>
                            <Select
                                labelId="template-type-label"
                                id="template-type-select"
                                label="Tipo"
                                name="TemplateType"
                                value={formData["TemplateType"]}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {templatesType.map((template) => (
                                    <MenuItem key={template.Days} value={Number(template.Days)}>
                                        {template.Type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="template-name-label">Tipo</InputLabel>
                            <Select
                                labelId="template-name-label"
                                label="Template"
                                name="TemplateName"
                                value={defaultTemplate}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {templatesName.map((template) => (
                                    <MenuItem key={template} value={template}>
                                        {formatTemplateName(template)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="De:"
                                type="date"
                                name="dataInicial"
                                value={defaultDataDe}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Até:"
                                type="date"
                                name="dataFinal"
                                value={defaultDataAte}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEmailOpened} // se estiver indefinido, será falso
                            name="OpenedEmail"
                            onChange={handleCheckboxChange} // Reutiliza o handleChange
                        />
                    }
                    label="Teve Abertura?"
                />
            </Grid>
        </Grid>
    );
};

export default FilterFormOnboarding;
