import { FormControl, Grid, SelectChangeEvent, TextField } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";

interface IFilterFormErrosOnboarding {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void;
    formData: any;
}

const FilterFormErrosOnboarding: React.FC<IFilterFormErrosOnboarding> = ({ handleChangeDate, handleSelectChange, formData }) => {
    const { } = useContext(AuthContext);

    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = Boolean(formData.dataInicial && formData.dataFinal);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="De:"
                                type="date"
                                name="dataInicial"
                                value={defaultDataDe}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Até:"
                                type="date"
                                name="dataFinal"
                                value={defaultDataAte}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default FilterFormErrosOnboarding;
