import React, { createContext, useContext, ReactNode, useState } from "react";
import {
    buscaBairrosCalculadora,
    buscarEmailBloqueadoPorTipo,
    buscarErrosRelatorioEsteiraRenovacao,
    buscarListaDesocupacaoSite,
    buscarListaDesocupados,
    buscarRelatorioEsteiraRenovacao,
    buscaTiposCalculadora,
    calcularAluguel,
    criarBloqueadorEmail,
    deletarBloqueadorEmail,
    exportarListaDesocupacaoSite,
    exportarListaDesocupados,
    exportarRelatorioErrosEsteiraRenovacao,
    exportarRelatorioEsteiraRenovacao,
    listarNomeGestoresEsteiraRenovacao,
    verificarEmailBloqueado
} from "../services/alugueis/alugueisApi";
import { IBairro, ICalculadoraResult, IImovel, ITipo } from "../pages/alugueis/Calculadora/ICalculadoraAlugueis";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { IDesocupacaoImoveis } from "../interfaces/ISite";
import { IClientContrato } from "../pages/alugueis/interfaces/IRenovacaoContratos";
import { IClientErro } from "../pages/alugueis/interfaces/IRenovacaoErros";
import { IBloqueioEmail } from "../pages/marketing/interfaces/IBloqueioEmail";
import { IEmailBlocker } from "../pages/alugueis/RelatorioRenovação/ListBlockedClientsModal";

interface AlugueisContextData {
}

const AlugueisContext = createContext<AlugueisContextData | undefined>(undefined);

interface AlugueisProviderProps {
    children: ReactNode;
}

interface AlugueisContextData {
    pegaBairrosAsync: () => Promise<IBairro[]>;
    pegaTiposAsync: () => Promise<ITipo[]>;
    calculaAluguelAsync: (imovel: IImovel) => Promise<ICalculadoraResult>;
    buscaListaDesocupacaoSiteAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IDesocupacaoImoveis[]>>;
    exportaListaDesocupacaoSiteAsync: (params: IPaginationFilter) => Promise<any>;
    buscaListaDesocupadosAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IDesocupacaoImoveis[]>>;
    exportaListaDesocupadosAsync: (params: IPaginationFilter) => Promise<any>;
    buscaRelatorioEsteiraRenovacaoAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IClientContrato[]>>;
    exportaRelatorioEsteiraRenovacaoAsync: (params: IPaginationFilter) => Promise<any>;
    buscaErrosRelatorioEsteiraRenovacaoAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IClientErro[]>>;
    exportaRelatorioErrosEsteiraRenovacaoAsync: (params: IPaginationFilter) => Promise<any>;
    criaBloqueadorEmailAsync: (data: IBloqueioEmail) => Promise<number>;
    buscaEmailBloqueadoPorTipoAsync: (emailType: string) => Promise<IEmailBlocker[]>;
    setCodPessoaBlockRenovacao: React.Dispatch<React.SetStateAction<number>>;
    codPessoaBlockRenovacao: number;
    verificaEmailBloqueadoAsync: (data: IClientContrato) => Promise<any>;
    deletaBloqueioEmailAsync: (data: IClientContrato) => Promise<number>;
    listaNomeGestoresEsteiraRenovacaoAsync: () => Promise<string[]>;
}

export const AlugueisProvider: React.FC<AlugueisProviderProps> = ({ children }) => {
    const [codPessoaBlockRenovacao, setCodPessoaBlockRenovacao] = useState(0);

    const pegaBairrosAsync = async (): Promise<IBairro[]> => {
        return await buscaBairrosCalculadora();
    }

    const pegaTiposAsync = async (): Promise<ITipo[]> => {
        return await buscaTiposCalculadora();
    }

    const calculaAluguelAsync = async (imovel: IImovel): Promise<ICalculadoraResult> => {
        return await calcularAluguel(imovel);
    }

    const buscaListaDesocupacaoSiteAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IDesocupacaoImoveis[]>> => {
        return await buscarListaDesocupacaoSite(params);
    };

    const exportaListaDesocupacaoSiteAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaDesocupacaoSite(params);
    };

    const buscaListaDesocupadosAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IDesocupacaoImoveis[]>> => {
        return await buscarListaDesocupados(params);
    };

    const exportaListaDesocupadosAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaDesocupados(params);
    };

    const buscaRelatorioEsteiraRenovacaoAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IClientContrato[]>> => {
        return await buscarRelatorioEsteiraRenovacao(params);
    };

    const exportaRelatorioEsteiraRenovacaoAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarRelatorioEsteiraRenovacao(params);
    };

    const buscaErrosRelatorioEsteiraRenovacaoAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IClientErro[]>> => {
        return await buscarErrosRelatorioEsteiraRenovacao(params);
    };

    const exportaRelatorioErrosEsteiraRenovacaoAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarRelatorioErrosEsteiraRenovacao(params);
    };

    const criaBloqueadorEmailAsync = async (data: IBloqueioEmail): Promise<number> => {
        return await criarBloqueadorEmail(data);
    };


    const verificaEmailBloqueadoAsync = async (data: IClientContrato): Promise<any> => {
        return await verificarEmailBloqueado(data);
    };

    const buscaEmailBloqueadoPorTipoAsync = async (emailType: string): Promise<IEmailBlocker[]> => {
        return await buscarEmailBloqueadoPorTipo(emailType);
    };

    const deletaBloqueioEmailAsync = async (data: IClientContrato): Promise<number> => {
        return await deletarBloqueadorEmail(data);
    };

    const listaNomeGestoresEsteiraRenovacaoAsync = async (): Promise<string[]> => await listarNomeGestoresEsteiraRenovacao();


    return (
        <AlugueisContext.Provider value={{
            pegaBairrosAsync,
            pegaTiposAsync,
            calculaAluguelAsync,
            buscaListaDesocupacaoSiteAsync,
            exportaListaDesocupacaoSiteAsync,
            buscaListaDesocupadosAsync,
            exportaListaDesocupadosAsync,
            buscaRelatorioEsteiraRenovacaoAsync,
            exportaRelatorioEsteiraRenovacaoAsync,
            buscaErrosRelatorioEsteiraRenovacaoAsync,
            exportaRelatorioErrosEsteiraRenovacaoAsync,
            criaBloqueadorEmailAsync,
            setCodPessoaBlockRenovacao,
            codPessoaBlockRenovacao,
            verificaEmailBloqueadoAsync,
            buscaEmailBloqueadoPorTipoAsync,
            deletaBloqueioEmailAsync,
            listaNomeGestoresEsteiraRenovacaoAsync
        }}>
            {children}
        </AlugueisContext.Provider>
    );
};

export const useAlugueis = (): AlugueisContextData => {
    const context = useContext(AlugueisContext);
    if (!context) {
        throw new Error("useAlugueis must be used within a AlugueisProvider");
    }
    return context;
};
