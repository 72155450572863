import axios from "axios";
import { IBairro, ICalculadoraResult, IImovel, ITipo } from "../../pages/alugueis/Calculadora/ICalculadoraAlugueis";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { IDesocupacaoImoveis } from "../../interfaces/ISite";
import { normalizeKeys } from "../../utils/helpers";
import { IClientContrato } from "../../pages/alugueis/interfaces/IRenovacaoContratos";
import { IClientErro } from "../../pages/alugueis/interfaces/IRenovacaoErros";
import { IBloqueioEmail } from "../../pages/marketing/interfaces/IBloqueioEmail";
import { IEmailBlocker } from "../../pages/alugueis/RelatorioRenovação/ListBlockedClientsModal";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function buscaBairrosCalculadora(): Promise<IBairro[]> {
    return (await api.get("/api/BI/getBairros")).data;
}

export async function buscaTiposCalculadora(): Promise<ITipo[]> {
    return (await api.get("/api/BI/getTipos")).data;
}

export async function calcularAluguel(imovel: IImovel): Promise<ICalculadoraResult> {
    return (await api.post("/api/BI/calcular", { ...imovel })).data;
}

export async function buscarListaDesocupacaoSite(params: IPaginationFilter): Promise<IPaginatedResult<IDesocupacaoImoveis[]>> {
    return (await api.get("api/ControleImoveisDesocupacao/Listar", { params })).data;
};

export async function exportarListaDesocupacaoSite(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/ControleImoveisDesocupacao/Gerar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarListaDesocupados(params: IPaginationFilter): Promise<IPaginatedResult<IDesocupacaoImoveis[]>> {
    const response = await api.get("api/ControleImoveisDesocupacao/BuscarDesocupadosPaginado", { params });
    return normalizeKeys<IPaginatedResult<IDesocupacaoImoveis[]>>(response.data);
};

export async function exportarListaDesocupados(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/ControleImoveisDesocupacao/GerarDesocupados", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarRelatorioEsteiraRenovacao(params: IPaginationFilter): Promise<IPaginatedResult<IClientContrato[]>> {
    return (await api.get("api/renovacao/contratos/Relatorio/Listar", { params })).data;
}

export async function exportarRelatorioEsteiraRenovacao(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/renovacao/contratos/relatorio/Exportar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarErrosRelatorioEsteiraRenovacao(params: IPaginationFilter): Promise<IPaginatedResult<IClientErro[]>> {
    return (await api.get("api/renovacao/contratos/Relatorio/Listar-erros", { params })).data;
}


export async function exportarRelatorioErrosEsteiraRenovacao(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/renovacao/contratos/relatorio/exportar-erros", {
        params,
        responseType: 'blob'
    })).data;
}

export async function criarBloqueadorEmail(data: IBloqueioEmail): Promise<number> {
    return (await api.post("api/EmailBlocker", { ...data })).status;
}

export async function verificarEmailBloqueado(data: IClientContrato): Promise<any> {
    return (await api.get("api/EmailBlocker/verificar-bloqueado", {
        params: {
            clientEmail: data.ClientEmail,
            emailType: data.ContractType,
            codimovel: data.CodImovel
        }
    })).data;
}

export async function buscarEmailBloqueadoPorTipo(emailType: string): Promise<IEmailBlocker[]> {
    return (await api.get("api/EmailBlocker/busca-por-tipo?EmailType=" + emailType)).data;
};

export async function deletarBloqueadorEmail(data: IClientContrato): Promise<number> {
    return (await api.delete("api/EmailBlocker", {
        params: {
            clientEmail: data.ClientEmail,
            emailType: data.ContractType,
            codimovel: data.CodImovel
        }
    })).status;
}

export async function listarNomeGestoresEsteiraRenovacao(): Promise<string[]> {
    return (await api.get("api/renovacao/contratos/Managers")).data;
}
