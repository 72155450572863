import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { BuscaClientes } from '../../../interfaces/IBuscaClientes';
import BuscarClientes from '../../../components/common/BuscaClientes';
import { useCondominio } from '../../../contexts/CondominioProvider';

const BuscarClientesPage: React.FC = () => {
    const [ficha, setFicha] = useState<BuscaClientes[]>([]);
    const { buscaClientesAsync, exportaListaBuscaClientesAsync, atualizaInadimplenteAsync} = useCondominio();
    return (
        <div>
            <Typography variant="h6">Buscar Clientes</Typography>
            <BuscarClientes
                busca={buscaClientesAsync}
                exporta={exportaListaBuscaClientesAsync}
                atualiza={atualizaInadimplenteAsync}
                ficha={ficha}
                setFicha={setFicha}
            />
        </div>
    );
};

export default BuscarClientesPage;