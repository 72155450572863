import React, { createContext, useContext, ReactNode } from "react";
import { IFichaFinanceira } from "../interfaces/IVendas";
import { gerarBoletoFichaFinanceira, processarFichaFinanceira } from "../services/vendas/vendasApi";
import { IMatrixDiretoriaELideres, IMatrixNineFilter } from "../pages/rh/MatrixNineBox";
import { IColaboradorNineBox } from "../interfaces/ICadastroUsuario";
import { getCargo, getDepartamento, getDiretoria, getLider, getNineBox } from "../services/RH/rhApi";

interface RhProviderProps {
    children: ReactNode;
}

interface RhContextData {
    getNineBoxFiltroAsync: (params: IMatrixNineFilter) => Promise<IColaboradorNineBox[]>;
    getDiretoriasAsync: () => Promise<IMatrixDiretoriaELideres[]>;
    getDepartamentosAsync: () => Promise<(string | null)[]>;
    getCargosAsync: () => Promise<(string | null)[]>;
    getLideresAsync: () => Promise<IMatrixDiretoriaELideres[]>;
}

const rhContext = createContext<RhContextData | undefined>(undefined);

export const RhProvider: React.FC<RhProviderProps> = ({ children }) => {
    const getNineBoxFiltroAsync = async (params: IMatrixNineFilter): Promise<IColaboradorNineBox[]> => {
        return await getNineBox(params);
    };

    const getDiretoriasAsync = async (): Promise<IMatrixDiretoriaELideres[]> => {
        return await getDiretoria();
    };

    const getDepartamentosAsync = async (): Promise<(string | null)[]> => {
        return await getDepartamento();
    };

    const getCargosAsync = async (): Promise<(string | null)[]> => {
        return await getCargo();
    };

    const getLideresAsync = async (): Promise<IMatrixDiretoriaELideres[]> => {
        return await getLider();
    };

    return (
        <rhContext.Provider value={{
            getNineBoxFiltroAsync,
            getDiretoriasAsync,
            getDepartamentosAsync,
            getCargosAsync,
            getLideresAsync
        }}>
            {children}
        </rhContext.Provider>
    );
};

export const useRH = (): RhContextData => {
    const context = useContext(rhContext);
    if (!context) {
        throw new Error("useRh must be used within a RhProvider");
    }
    return context;
};
