import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, AppBar, Toolbar, Typography, Grid, IconButton, Tooltip, Container, Box
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import Pagination from '../../components/common/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IVPNLiberacao, IVPNLiberacaoFilter } from '../../interfaces/IVPNLiberacao';
import { useDP } from '../../contexts/DPProvider';

const VPNLiberacaoPage: React.FC = () => {
  const [liberacoes, setLiberacoes] = useState<IVPNLiberacao[]>([]);
  const navigate = useNavigate();
  const { getLiberacoesVPNFiltroAsync, bloqueioLiberacaoVPNAsync } = useDP();
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPagina] = useState(10);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [filters, setFilters] = useState<IVPNLiberacaoFilter>({
    nome: '',
    email: '',
    data_inicio_liberacao: null,
    data_fim_liberacao: null,
  });

  const handleSearch = async () => {
    const params: IVPNLiberacaoFilter = {
      nome: filters.nome,
      email: filters.email,
      data_inicio_liberacao: filters.data_inicio_liberacao ? filters.data_inicio_liberacao : null,
      data_fim_liberacao: filters.data_fim_liberacao ? filters.data_fim_liberacao : null,
      paginaAtual: filters.paginaAtual,
      itensPagina: filters.itensPagina,
    };

    const data = await getLiberacoesVPNFiltroAsync(params);

    setTotalPaginas(data.TotalPaginas);
    setTotalRegistros(data.TotalItens);

    const liberacoesData = data.Itens ? data.Itens.map((item: any) => ({
      ...item,
      data_inicio_liberacao: dayjs(item.Data_Inicio_Liberacao),
      data_fim_liberacao: dayjs(item.Data_Fim_Liberacao),
    })): [];

    setLiberacoes(liberacoesData);
  };

  const handleFilterChange = (prop: keyof typeof filters, value: any) => {
    setFilters({ ...filters, [prop]: value });
  };

  const handleNew = () => {
    navigate('/dp/vpnliberacao/cadastrar');
  };

  const handleEdit = (liberacao: IVPNLiberacao) => {
    navigate('/dp/vpnliberacao/cadastrar', { state: { currentLiberacao: liberacao } });
  };

  const handleForcarBloqueioLiberacao = async (liberacaoId: number | undefined) => {
    if (liberacaoId!== undefined){
      await bloqueioLiberacaoVPNAsync(liberacaoId);
    }
    
    await handleSearch();
  };

  useEffect(() => {
    handleSearch();
  }, [paginaAtual]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Pesquisar Liberações de VPN</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        {/* Card dos filtros */}
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Nome"
                value={filters.nome}
                onChange={(e) => handleFilterChange('nome', e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Email"
                value={filters.email}
                onChange={(e) => handleFilterChange('email', e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DatePicker
                label="Data de Início"
                value={filters.data_inicio_liberacao}
                onChange={(newDate) =>
                  handleFilterChange('data_inicio_liberacao', newDate)
                }
                slotProps={{
                  textField: { fullWidth: true, margin: 'normal' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DatePicker
                label="Data de Fim"
                value={filters.data_fim_liberacao}
                onChange={(newDate) =>
                  handleFilterChange('data_fim_liberacao', newDate)
                }
                slotProps={{
                  textField: { fullWidth: true, margin: 'normal' }
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={1} display="flex" alignItems="center">
              <Button
                onClick={handleSearch}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Pesquisar
              </Button>
            </Grid>
            <Grid item xs={6} sm={3} md={1} display="flex" alignItems="center">
              <Button
                onClick={handleNew}
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Nova Liberação
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {/* Tabela com os resultados */}
        <Paper elevation={3} sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Início da Liberação</TableCell>
                  <TableCell>Fim da Liberação</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liberacoes.map((liberacao) => (
                  <TableRow key={liberacao.Id}>
                    <TableCell>{liberacao.Id}</TableCell>
                    <TableCell>{liberacao.Usuario?.Username}</TableCell>
                    <TableCell>{liberacao.Usuario?.Email}</TableCell>
                    <TableCell>
                      {liberacao.data_inicio_liberacao?.format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {liberacao.data_fim_liberacao?.format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<EditIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEdit(liberacao)}
                        sx={{ mr: 1 }}
                      >
                        Editar
                      </Button>
                      <Tooltip title="Forçar Bloqueio/Liberação">
                      {liberacao.Ativo ? (
                      <Button
                        startIcon={<SystemUpdateAltIcon />}
                        variant="outlined"
                        color="error"
                        onClick={() => handleForcarBloqueioLiberacao(liberacao.Id)} 
                      >
                        Bloquear Acesso
                      </Button>):(
                      <Button
                        startIcon={<SystemUpdateAltIcon />}
                        variant="outlined"
                        color="success"
                        onClick={() => handleForcarBloqueioLiberacao(liberacao.Id)} 
                      >
                        Liberar Acesso
                      </Button>)}
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Pagination
              paginaAtual={paginaAtual}
              totalPaginas={totalPaginas}
              PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
              ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
              IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
              IrParaPrimeiraPagina={() => setPaginaAtual(1)}
              IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
            />
            <Typography variant="body2">
              Total de Itens: {totalRegistros}
            </Typography>
          </Box>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default VPNLiberacaoPage;
