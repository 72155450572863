import { Divider, Typography } from "@mui/material";
import "../../../styles/styles.css";
import Relatorio from "../../../components/common/Relatorio";
import { IErrosOnboarding } from "../interfaces/IErrosOnboarding";
import { useState } from "react";
import { useMarketing } from "../../../contexts/marketingProvider";

function BloqueioEmail() {
    const {buscaListaErrosOnboarding, exportaListaErrosOnboarding } = useMarketing();
    const [ficha, setFicha] = useState<IErrosOnboarding[]>([]);
    return (
        <>
            <Typography variant="h6">Relatório de Erros Onboarding</Typography>
            <Relatorio
                busca={buscaListaErrosOnboarding} 
                exporta={exportaListaErrosOnboarding}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"ErrosOnboarding"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}

export default BloqueioEmail;
