import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { IIndicadorItem } from "../interfaces/IIndicadorItem";
import {
    api,
    createSession,
    permissaoUsuarioAPI,
    validarAmbiente,
    pegarPermissoesUsuarios,
    atualizarPermissoesUsuarios,
    enviarRecuperacaoSenha,
    redefinirSenha,
    buscarIndicador
} from "../services/api";

import { INumeroDaSorte } from "../interfaces/ISeguros";

export const AuthContext = createContext<any>({});

export const AuthProvider = ({ children }: any) => {
    const navigate = useNavigate();

    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [user, setUser] = useState({ id: 0, email: "" });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const recoveredUser = localStorage.getItem("user");

        if (recoveredUser) {
            setUser(JSON.parse(recoveredUser));
        }

        setLoading(false);
    }, []);

    const login = async (email: any, password: any) => {
        const response = await createSession(email, password);
        console.log("login auth", response);

        //Ir na API e criar Sessao
        const loggedUser = response.data.user;
        const token = response.data.token;

        localStorage.setItem("user", JSON.stringify(loggedUser));
        localStorage.setItem("token", token);
        permissaoUsuario();

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUser(loggedUser);

        await new Promise(f => setTimeout(f, 4000));

        navigate("/");
        //if (localStorage.getItem("permission") == null) { 
        window.location.reload();
        //}
    };

    const permissaoUsuario = async () => {
        const response = await permissaoUsuarioAPI();
        console.log("permissao", response.data);

        localStorage.setItem("permission", JSON.stringify(response.data));
    };

    const envioRecuperacaoSenha = async (email: string) => {
        const response = await enviarRecuperacaoSenha(email);
        console.log("Recuperacao de senha: ", response.data);
    };

    const redefinicaoSenha = async (password: string, confirmPassword: string) => {
        const response = await redefinirSenha(password, confirmPassword);
        console.log("Redefinir senha: ", response.data);
    }


    const validaAmbiente = async () => {
        const response = await validarAmbiente();
        console.log("Ambiente: ", response.data);
        return response.data;
    };

    const logout = () => {
        console.log("logout");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        api.defaults.headers.Authorization = null;
        setUser({ id: 0, email: "" });
        navigate("/login");
    };


    const buscaIndicadores = async (startStr: string | undefined, endStr: string | undefined): Promise<IIndicadorItem[]> => {
        const response = await buscarIndicador(startStr, endStr);

        console.log("busca indicador:", response.data);
        return response.data;
    };

    const pegaPermissoesUsuarios = async (email: string) => {
        const response = await pegarPermissoesUsuarios(email);
        return response.data;
    };
    const atualizaPermissoesUsuarios = async (email: string, selectedPermissions: []) => {
        const response = await atualizarPermissoesUsuarios(email, selectedPermissions);
        return response.data;
    };

    return (
        <AuthContext.Provider value={{
            authenticated: user.id !== 0,
            user,
            loading,
            login,
            logout,
            sideBarOpen,
            setSideBarOpen,
            validaAmbiente,
            pegaPermissoesUsuarios,
            atualizaPermissoesUsuarios,
            envioRecuperacaoSenha,
            redefinicaoSenha,
            buscaIndicadores
        }}>
            {children}
        </AuthContext.Provider>
    );
}