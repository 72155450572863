import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";

export const api = axios.create({
    baseURL: baseURL
});

export const createSession = async (email, password) => {
    return api.post("/api/Login/login", { email, password });
}

export const enviarRecuperacaoSenha = async (email) => {
    return api.post("/api/Login/RecuperarSenha", { email });
}

export const redefinirSenha = async (password, confirmPassword) => {
    let token = localStorage.getItem("token-redefinir-senha");

    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post("/api/Login/RedefinirSenha", { password, confirmPassword });
}

export const validarToken = () => {
    let token = localStorage.getItem("token");

    if (token == null) {
        return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    api.get('/api/Login/validartoken')
        .then((resposta) => {
            console.log('Valido');
        })
        .catch((erro) => {
            console.error(erro);
            console.log("logout");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("permission");
            api.defaults.headers.Authorization = null;
            window.location.href = '/login';
        });
}

export const permissaoUsuarioAPI = async () => {
    let token = localStorage.getItem("token");
    let id = JSON.parse(localStorage.getItem("user")).id;

    let uri = "/api/Login/permissaousuario?id=" + id;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post(uri);
}

export const validarAmbiente = async () => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/Common/StatusAmbiente");
}

export const buscarIndicador = async (startStr, endStr) => {
    return api.get("/api/Indicadores/Indicadores?dataInicio=" + startStr + "&dataFim=" + endStr);
};

export const pegarPermissoesUsuarios = async (email) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("/api/Permissoes/pegarPermissoesUsuario?email=" + email );
}

export const atualizarPermissoesUsuarios = async (email, selectedPermissions) => {
    let token = localStorage.getItem("token");

    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("/api/Permissoes/atualizarPermissoesUsuario", { email, selectedPermissions});
};







export const validarLinkCompartilhado = async (Link) => {
    return api.get("api/IA/ValidarAcessoLink?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W&link=" + Link);
};

export const salvarTranscricao = async (trancricao) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.post("api/IA/SalvarTranscricao", trancricao);
};


export const addTemplate = async (templates) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("api/editortemplates/add-template", templates, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export const getAllTemplates = async (templates) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get("api/editortemplates/all-templates");
}

export const deleteTemplateById = async (id) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.delete(`api/editortemplates/delete-template/${id}`);
}

export const updateTemplate = async (id, templates) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.put(`api/editortemplates/update-template/${id}`, templates);
}

export const getTemplateById = async (id) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.get(`api/editortemplates/get-template/${id}`);
}

export const sendTemplateProd = async (selectedIds) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    return api.post("api/editortemplates/add-templateprod-by-id", selectedIds), {
        headers: {
            "Content-Type": "application/json",
        },
    };
}