import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { BuscaIntegracao } from '../../../interfaces/IBuscaIntegracoesBetalabs';
import BuscarIntegracoesBetalabs from '../../../components/common/BuscaIntegracoes';
import { useSeguros } from '../../../contexts/segurosProvider';

const BuscarIntegracaoPage: React.FC = () => {
    const [ficha, setFicha] = useState<BuscaIntegracao[]>([]);
    const { buscaIntegracaoBetalabsAsync } = useSeguros();
    return (
        <div>
            <Typography variant="h6">Buscar Integrações</Typography>
            <BuscarIntegracoesBetalabs
                busca={buscaIntegracaoBetalabsAsync}
                ficha={ficha}
                setFicha={setFicha}
            />
        </div>
    );
};
export default BuscarIntegracaoPage;