import React, { useState } from "react";
import { Alert, Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import Loading from "../../components/common/Loading";
import { useSeguros } from "../../contexts/segurosProvider";

const BetalabsPage: React.FC = () => {
    const { enviaEmailBetalabsAsync, cadastraBetalabsAsync, verificaCodigoImobiliarAsync } = useSeguros();

    const [codigoCondominio, setCodigoCondominio] = useState<string>('');
    const [codigoExiste, setCodigoExiste] = useState<boolean>(false);
    const [condominioIntegrado, setCondominioIntegrado] = useState<boolean>(false);
    const [nomeCondominio, setNomeCondominio] = useState<string>('');
    const [numeroDeCondominos, setNumeroDeCondominos] = useState<number>(0);
    const [numeroCadastrados, setNumeroCadastrados] = useState<number>(0);
    const [numeroErrosCadastro, setNumeroErrosCadastro] = useState<number>(0);
    const [numeroEmailsEnviados, setNumeroEmailsEnviados] = useState<number>(0);
    const [numeroEmailsErros, setNumeroEmailsErros] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [msgAlert, setMsgAlert] = useState("");

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCodigoCondominio(event.target.value);
    };

    const verificaCodigo = async () => {
        setIsLoading(true);
        setCodigoExiste(false);
        setCondominioIntegrado(false);
        setNomeCondominio('');
        setNumeroDeCondominos(0);
        try {
            const data = await verificaCodigoImobiliarAsync(codigoCondominio);
            if (data.codigoExiste) {
                setSeverity("success");
                setMsgAlert(data.mensagem);
                setCodigoExiste(true);
                setCondominioIntegrado(data.condominioIntegrado);
                if (data.condominioInfo) {
                    setNomeCondominio(data.condominioInfo.nomeCondom);
                    setNumeroDeCondominos(data.condominioInfo.numeroDeCondominos);
                }
            } else {
                setSeverity("error");
                setMsgAlert(data.mensagem);
                setCodigoExiste(false);
                setCondominioIntegrado(false);
            }
            setOpen(true);
        } catch (error) {
            setSeverity("error");
            setMsgAlert("Código não encontrado.");
            setCodigoExiste(false);
            setCondominioIntegrado(false);
            setOpen(true);
        }

        setIsLoading(false);
    };

    const cadastraCondominos = async () => {
        setIsLoading(true);

        const userString = localStorage.getItem("user");

        try {
            if (userString) {
                const user = JSON.parse(userString);
                const userId = user.id;

                const data = await cadastraBetalabsAsync(codigoCondominio, userId);
                setSeverity("success");
                setMsgAlert("Usuários cadastrados: " + data.numeroCadastrados + ", usuários com erros: " + data.numeroErrosCadastro);
                setNumeroCadastrados(data.numeroCadastrados);
                setNumeroErrosCadastro(data.numeroErrosCadastro);
                setIsLoading(false);
                setOpen(true);
            } else {
                setSeverity("error");
                setMsgAlert("Nenhum usuário logado.");
                setOpen(true);
            }
        } catch {
            setSeverity("error");
            setMsgAlert("Erro interno ao cadastrar");
            setIsLoading(false);
            setOpen(true);
        }
    };

    const sendEmail = async () => {
        setIsLoading(true);
        try {
            const data = await enviaEmailBetalabsAsync(codigoCondominio)
            setSeverity("success")
            setMsgAlert("Emails enviados: " + data.notificacoesSalvas  + ", emails com erros: " + data.notificacoesNaoSalvas);
            setNumeroEmailsEnviados(data.notificacoesSalvas );
            setNumeroEmailsErros(data.notificacoesNaoSalvas);
            setIsLoading(false);
            setOpen(true);
        }
        catch {
            setSeverity("error")
            setMsgAlert("Erro ao enviar os emails");
            setIsLoading(false);
            setOpen(true);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "60%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: "100%" }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    sx={{ width: '300px' }}
                    label="Digite o código do condomínio"
                    variant="outlined"
                    value={codigoCondominio}
                    onChange={handleCodeChange}
                />
                <Button sx={{ marginLeft: '10px' }} variant="contained" onClick={verificaCodigo} disabled={isLoading}>
                    Buscar
                </Button>
            </Box>
            {isLoading && <Loading />}
            {codigoExiste && (
                <Box>
                    <Box sx={{ marginY: '20px' }}>
                        <Typography variant="body1">
                            Nome do Condomínio: <strong>{nomeCondominio}</strong>
                        </Typography>
                        <Typography variant="body1">
                            Número de Condôminos: <strong>{numeroDeCondominos}</strong>
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                        <Box>
                            <Button
                                sx={{ marginRight: '20px', width: '300px' }}
                                onClick={cadastraCondominos}
                                variant="contained"
                                disabled={!codigoExiste || isLoading}
                            >
                                Cadastrar no betalabs
                            </Button>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    marginTop: '20px',
                                    width: '300px',
                                }}
                            >
                                <Typography variant="body1">
                                    Usuários Cadastrados: <strong>{numeroCadastrados}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Usuários com Erros: <strong>{numeroErrosCadastro}</strong>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                sx={{ width: '300px' }}
                                onClick={sendEmail}
                                variant="contained"
                                disabled={!condominioIntegrado || isLoading}
                            >
                                Enviar email aos condôminos
                            </Button>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    marginTop: '20px',
                                    width: '300px',
                                }}>

                                <Typography variant="body1">
                                    Emails enviados aos usuários: <strong>{numeroEmailsEnviados}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Erros: <strong>{numeroEmailsErros}</strong>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default BetalabsPage;