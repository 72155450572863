import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Relatorio from "../../../components/common/Relatorio";
import "../../../styles/styles.css";
import { IClientContrato } from "../interfaces/IRenovacaoContratos";
import { useAlugueis } from "../../../contexts/alugueisProvider";

export default function RelatorioEsteiraRenovacao() {
const {buscaRelatorioEsteiraRenovacaoAsync, exportaRelatorioEsteiraRenovacaoAsync } = useAlugueis();
const [ficha, setFicha] = useState<IClientContrato[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails Esteira de Renovação</Typography>
            <Relatorio
                busca={buscaRelatorioEsteiraRenovacaoAsync}
                exporta={exportaRelatorioEsteiraRenovacaoAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailEsteiraRenovacao"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}
