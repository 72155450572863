import axios from "axios";
import { IPaginatedResult, IPaginationFilter } from "../IService";
import { CDL } from "../../interfaces/ICDL";
import { IEmailSindico } from "../../pages/juridico/RelatorioEmailSindico/Interfaces/IEmailSindico";
import { EconomiaItem, IBoletoNSQueryParams, InquilinoItem } from "../../pages/juridico/BoletoInadimplenciaNSPage";
import { BoletosInadimplenciaNS } from "../../interfaces/IBoletosInadimplenciaNS";
import { normalizeKeys } from "../../utils/helpers";
const baseURL = process.env.REACT_APP_API_BASE_URL;
//const baseURL = "https://localhost:7097";


console.log('baseURL ', baseURL);

export const api = axios.create({
    baseURL: baseURL
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export async function buscarListaCDL(params: IPaginationFilter): Promise<IPaginatedResult<CDL>> {
    return (await api.get("api/CDLWS/Listar", { params })).data;
};

export async function exportarListaCDL(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/CDLWS/Gerar", {
        params,
        responseType: 'blob'
    })).data;
}

export async function buscarRelatorioEmailSindico(params: IPaginationFilter): Promise<IPaginatedResult<IEmailSindico>> {
    return (await api.get("api/WelcomeKit/relatorio/listar-boas-vindas-sindicos", { params })).data;
};

export async function exportarRelatorioEmailSindico(params: IPaginationFilter): Promise<any> {
    return (await api.get("api/WelcomeKit/relatorio/exportar-boas-vindas-sindicos", {
        params,
        responseType: 'blob'
    })).data;
}

export async function filtrarBoletoInadimplencia(body: IBoletoNSQueryParams): Promise<IPaginatedResult<BoletosInadimplenciaNS>> {
    return normalizeKeys((await api.post("/api/BoletoInadimplenteNS/Search", body)).data);
}

export async function exportarBoletoInadimplencia(body: IBoletoNSQueryParams): Promise<any> {
    return (await api.post("/api/BoletoInadimplenteNS/Exportar", body, { responseType: 'blob' })).data;
}

export async function getEconomiasByCondominio(codigo: string): Promise<EconomiaItem[]> {
    return (await api.post("/api/EconomiaImobiliar/EconomiasCondominio/" + codigo)).data;
}

export async function getInquilinos(): Promise<InquilinoItem[]> {
    return (await api.post("/api/EconomiaImobiliar/InquilinosAluguel")).data;
}