import React, { createContext, useContext, ReactNode } from "react";
import { IPaginatedResult, IPaginationFilter } from "../services/IService";
import { CDL } from "../interfaces/ICDL";
import { buscarListaCDL, buscarRelatorioEmailSindico, exportarBoletoInadimplencia, exportarListaCDL, exportarRelatorioEmailSindico, filtrarBoletoInadimplencia, getEconomiasByCondominio, getInquilinos } from "../services/juridico/juridicoApi";
import { IEmailSindico } from "../pages/juridico/RelatorioEmailSindico/Interfaces/IEmailSindico";
import { EconomiaItem, IBoletoNSQueryParams, InquilinoItem } from "../pages/juridico/BoletoInadimplenciaNSPage";
import { BoletosInadimplenciaNS } from "../interfaces/IBoletosInadimplenciaNS";

interface JuridicoProviderProps {
    children: ReactNode;
}

interface JuridicoContextData {
    buscaListaCDLAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<CDL>>;
    exportaListaCDLAsync: (params: IPaginationFilter) => Promise<any>;
    buscaRelatorioEmailSindicoAsync: (params: IPaginationFilter) => Promise<IPaginatedResult<IEmailSindico>>;
    exportaRelatorioEmailSindicoAsync: (params: IPaginationFilter) => Promise<any>;
    filtraBoletoInadimplenciaAsync: (body: IBoletoNSQueryParams) => Promise<IPaginatedResult<BoletosInadimplenciaNS>>;
    exportaBoletoInadimplenciaAsync: (body: IBoletoNSQueryParams) => Promise<any>;
    economiasByCondominioAsync: (codigo: string) => Promise<EconomiaItem[]>;
    getAllInquilinosAsync: () => Promise<InquilinoItem[]>;
}

const juridicoContext = createContext<JuridicoContextData | undefined>(undefined);

export const JuridicoProvider: React.FC<JuridicoProviderProps> = ({ children }) => {
    const buscaListaCDLAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<CDL>> => {
        return await buscarListaCDL(params);
    };

    const exportaListaCDLAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarListaCDL(params);
    };

    const buscaRelatorioEmailSindicoAsync = async (params: IPaginationFilter): Promise<IPaginatedResult<IEmailSindico>> => {
        return await buscarRelatorioEmailSindico(params);
    };

    const exportaRelatorioEmailSindicoAsync = async (params: IPaginationFilter): Promise<any> => {
        return await exportarRelatorioEmailSindico(params);
    };

    const filtraBoletoInadimplenciaAsync = async (body: IBoletoNSQueryParams): Promise<IPaginatedResult<BoletosInadimplenciaNS>> => {
        return await filtrarBoletoInadimplencia(body);
    };

    const exportaBoletoInadimplenciaAsync = async (body: IBoletoNSQueryParams): Promise<any> => {
        return await exportarBoletoInadimplencia(body);
    };

    const economiasByCondominioAsync = async (codigo: string): Promise<EconomiaItem[]> => {
        return await getEconomiasByCondominio(codigo);
    };

    const getAllInquilinosAsync = async (): Promise<InquilinoItem[]> => {
        return await getInquilinos();
    };

    return (
        <juridicoContext.Provider value={{
            buscaListaCDLAsync,
            exportaListaCDLAsync,
            buscaRelatorioEmailSindicoAsync,
            exportaRelatorioEmailSindicoAsync,
            filtraBoletoInadimplenciaAsync,
            exportaBoletoInadimplenciaAsync,
            economiasByCondominioAsync,
            getAllInquilinosAsync
        }}>
            {children}
        </juridicoContext.Provider>
    );
};

export const useJuridico = (): JuridicoContextData => {
    const context = useContext(juridicoContext);
    if (!context) {
        throw new Error("useJuridico must be used within a JuridicoProvider");
    }
    return context;
};
