import React, { createContext, useContext, ReactNode } from "react";
import { filtrarLoteAutomacao, processarArquivoLoteAutomacao } from "../services/loteAutomacao/loteAutomacaoApi";
import { ArquivosAutomacao } from "../pages/LoteAutomacao/ProcessarArquivoPage";
import { IPaginatedResult } from "../services/IService";
import { LoteAutomacao } from "../interfaces/ILoteAutomacao";

interface LoteAutomacaoProviderProps {
    children: ReactNode;
}

interface loteAutomacaoContextData {
    processaArquivoLoteAutomacaoAsync: (tipo_arquivo: string, body: ArquivosAutomacao) => Promise<any>;
    filtraLoteAutomacaoAsync: (body: any) => Promise<IPaginatedResult<LoteAutomacao>>;
}

const loteAutomacaoContext = createContext<loteAutomacaoContextData | undefined>(undefined);

export const LoteAutomacaoProvider: React.FC<LoteAutomacaoProviderProps> = ({ children }) => {
    const processaArquivoLoteAutomacaoAsync = async (tipo_arquivo: string, body: ArquivosAutomacao): Promise<any> => {
        return await processarArquivoLoteAutomacao(tipo_arquivo, body);
    };

    const filtraLoteAutomacaoAsync = async (body: any): Promise<IPaginatedResult<LoteAutomacao>> => {
        return await filtrarLoteAutomacao(body);
    };

    return (
        <loteAutomacaoContext.Provider value={{
            processaArquivoLoteAutomacaoAsync,
            filtraLoteAutomacaoAsync
        }}>
            {children}
        </loteAutomacaoContext.Provider>
    );
};

export const useLoteAutomacao = (): loteAutomacaoContextData => {
    const context = useContext(loteAutomacaoContext);
    if (!context) {
        throw new Error("useLoteAutomacao must be used within a LoteAutomacaoProvider");
    }
    return context;
};
