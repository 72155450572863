import React, { useState, useContext } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loading from '../../../components/common/Loading';
import { AuthContext } from "../../../contexts/auth";
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useCondominio } from '../../../contexts/CondominioProvider';

type Props = {};

const WelcomeKitPage = (props: Props) => {
    const { envioWelcomeKitAsync } = useCondominio();

    const [condominios, setCondominios] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [msgAlert, setMsgAlert] = useState("");

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Requisicao executada com Sucesso!");

        console.log("submit", { condominios });
        try {
            let retorno = await envioWelcomeKitAsync(condominios);
            console.log("retorno", { retorno });  
        } catch (err: any) {
            console.log(err.response.data);
            setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        }
              
        setLoading(false);
        setOpen(true);
        setCondominios("");
    };

    const contents = loading
        ? <Loading /> : "";

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <h1 id="tableLabel"> Envio Welcome Kit</h1>
            <p>Codigos dos Condominios (informe separados por ,) </p>

            <TextField
                size="small"
                required
                id="outlined-required"
                label="Condominios"
                value={condominios} onChange={(e) => setCondominios(e.target.value)}
                sx={{ "width": "500px" }}
            />

            <Button sx={{ "margin-left": "1%" }} variant="contained" endIcon={<SendIcon />} onClick={handleSubmit}>
                    Enviar
                </Button>

            {contents}

        </div>
    );
};

export default WelcomeKitPage;