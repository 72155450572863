import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Relatorio from "../../../components/common/Relatorio";
import "../../../styles/styles.css";
import { IRamal } from "./Interface/IRamal";
import { useUtilidadesTI } from "../../../contexts/utilidadesTIProvider";

export default function Ramais() {
const {buscaRamalAsync} = useUtilidadesTI();
const [ficha, setFicha] = useState<IRamal[]>([]);

    return (
        <>
            <Typography variant="h6">Buscar Dados</Typography>
            <Relatorio
                busca={buscaRamalAsync}
                exporta={buscaRamalAsync}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Ramais"}
                refactoring={true}
            />
            <Divider variant="middle" />
        </>
    );
}
