import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Loading from '../../../../components/common/Loading';
import { formatarData } from '../../../../utils/formatUtils';
import Modal from '../../../../components/common/Modal';
import '../../../../components/common/Relatorio.css'
import { TableDataProps } from '../RelatorioDesocupados/IDesocupados';

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  console.log('ficha data ', ficha)
  return (
    <div className="responsive-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código Imobiliar</TableCell>
            <TableCell>Código Imoview</TableCell>
            <TableCell>Data Desocupado</TableCell>
          </TableRow>
        </TableHead>
        {loading ? <Loading /> : ''}
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <TableBody>
          {ficha.map((item) => (
            <TableRow key={item?.id}>
              <TableCell>{item.codImovel}</TableCell>
              <TableCell>{item.codigo_Imoview}</TableCell>
              <TableCell>{formatarData(item.data_Desocupado)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableData;